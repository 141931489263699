<!--

<div class="view" style="background-image: url('/assets/home-illustration.jpg'); width: 100%; height: 800px; margin-top:-100px;  background-repeat: no-repeat; background-size: cover; ">
    



<section id="heading" class="mat-typography" [ngStyle.lt-md]="{'margin-top': '30px', 'margin-bottom': '30px'}">
 
    <div class="heading-section" fxLayout="column" fxLayoutAlign="center center">
        <div [class.animated]="isSubheading" [class.fadeInRight]="isSubheading">
            <h2 [ngStyle.xs]="{'font-size': '18px'}" [ngStyle.sm]="{'font-size': '22px'}"> </h2>
            </div>
            <div [class.animated]="isSubheading" [class.fadeInRight]="isSubheading">
                <h2 [ngStyle.xs]="{'font-size': '18px'}" [ngStyle.sm]="{'font-size': '22px'}"> </h2>
                </div>
                <div [class.animated]="isSubheading" [class.fadeInRight]="isSubheading">
                    <h2 [ngStyle.xs]="{'font-size': '18px'}" [ngStyle.sm]="{'font-size': '22px'}"> </h2>
                    </div>
                    <div [class.animated]="isSubheading" [class.fadeInRight]="isSubheading">
                        <h2 [ngStyle.xs]="{'font-size': '18px'}" [ngStyle.sm]="{'font-size': '22px'}"> </h2>
                        </div>
                        <div [class.animated]="isSubheading" [class.fadeInRight]="isSubheading">
                            <h2 [ngStyle.xs]="{'font-size': '18px'}" [ngStyle.sm]="{'font-size': '22px'}"> </h2>
                            </div>
        <div [class.animated]="isHeading" [class.fadeInLeft]="isHeading">
        <h1 [ngStyle.xs]="{'font-size': '32px'}" [ngStyle.sm]="{'font-size': '42px'}" style="margin-top: 50px;" >Discover events for all the subjects of interest</h1>
        </div>
        
        <div fxLayout="row" [class.animated]="isHeadingBtn" [class.fadeIn]="isHeadingBtn" [class.heading-btn]="isHeadingBtn" >
            <a class="heading-button-left" mat-flat-button color="primary" style="margin-top: 40px;" >Attend</a>
            
        </div>
    </div>

</section>

</div>

-->

<div class="row heading">
<div class="col-md-6">
<br>
    <h1 class="title">Discover all learning events of your interests</h1>
    <div class="main">
    <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="searchText" (keyup.enter)="search()" placeholder="Search for your favorite webinar topic">
            <div class="input-group-append">
              <button class="btn btn-primary" style="background-color: #792cc7" (click)="search()" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
   
    </div>
  </div>
<!--
  <div style="display: flex;flex-direction: row">
  
<div style="display: flex;flex-direction: column;border: solid;
border-width: thin;
text-align: center;    margin-right: 31px;
    border-color: #792cc7;
    border-radius: 3%;">
        <img src="../../../assets/webinar.svg" style="    height: 50px;
        width: 50px;
        margin-left: 37px;
        margin-top: 17px;">
        <button routerLink="/attend" style="font-family: 'Nunito';" mat-button color="primary">Attend webinar</button>
</div>
 
<div style="display: flex;flex-direction: column;border: solid;
border-width: thin;
text-align: center; border-color: #792cc7;
    border-radius: 3%;">
        <img src="../../../assets/submit.svg" style="    height: 50px;
        width: 50px;
        margin-left: 37px;
        margin-top: 17px;">
        <button routerLink="/host" style="font-family: 'Nunito';" mat-button color="primary">Submit webinar</button>
</div>
</div>
-->
<div style="display: flex;flex-direction: row;">
  
       

            <mat-card routerLink="/attend" class="mat-elevation-z4" style="margin-right: 30px;margin-left: 30px;">
                    <div style="display: flex;flex-direction: column;
                    text-align: center; ;
                        ">
                    <img src="../../../assets/webinar.svg" style="    height: 50px;
                    width: 50px;
                    margin-left: 37px;
                    margin-top: 17px;" alt="no-image">
                    <button routerLink="/attend" style="font-family: 'Nunito';" mat-button color="primary">Attend webinar</button>
            </div>
                </mat-card>
               
        
         
                <mat-card routerLink="/host" class="mat-elevation-z4">
                        <div style="display: flex;flex-direction: column;
                        text-align: center; ;
                            ">
                        <img src="../../../assets/submit.svg" style="    height: 50px;
                        width: 50px;
                        margin-left: 37px;
                        margin-top: 17px;" alt="no-image">
                        <button routerLink="/host" style="font-family: 'Nunito';" mat-button color="primary">Submit webinar</button>
                </div>
                    </mat-card>
        </div>
    

</div>
<div class="col-md-6 mobile-view">
   
    <img  class="responsive" src="../../../assets/3818436.jpg" alt="no-image">


</div>


</div>
