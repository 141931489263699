import { CategoryService } from './../../service/category.service';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { Category } from './../../interfaces/Category.model';
import { AllEventService } from './../../events/all-events/all-event.service';
import { Webinar } from './../../interfaces/webinars.model';
import { Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  webinars: Array<Webinar> = [];
  webinars$: Observable<Webinar>;
  searchText: string;

  category: Category;
  categoryName: string;

  webinar: Webinar;

  constructor(
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private communicationService: CommunicationServiceService,
    private categoryService: CategoryService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.categoryName = params.get('name');
    });

    // console.log(this.categoryName);
    /*
      this.allEventService.getWebinarByCategory(this.categoryName)
      .subscribe(data => {
        // this.employees stores list of employee get from search result
        this.webinars = data.map(e => {
          return {
            id: e.payload.doc.id,
            ...e.payload.doc.data()
          } as Webinar;
        });
      });*/

    this.allEventService.getWebinars().subscribe((data) => {
      this.webinars = data;

      this.webinars = this.webinars.filter(
        (data) => data.category[0].name == this.categoryName
      );
    });

    /*
  
      this.categoryService.getWebinarsFromCategory(this.categoryName).subscribe(
        data=>{
          this.webinars=data;
          console.log(this.webinars);
        }
      )*/
    //console.log(this.webinars);
  }

  ngOnInit(): void {}

  favoriteSelected = false;
  toggleFavorite() {
    this.favoriteSelected = !this.favoriteSelected;
  }

  getCategoriesFromAws() {
    this.categoryService.getCategoriesFromAws().subscribe((data) => {
      // console.log(data);
    });
  }
}
