<div class="dashboard-card">

    <mat-card class="mat-elevation-z4">
            <span style="font-size: 20px;
            margin-bottom: 25px;">Total Webinars Submitted</span>
            <br>
            <br>
            <span style="font-size: 40px;
            ">{{_listOfRegistrations?.length}}</span>
        </mat-card>

        <mat-card class="mat-elevation-z4">
                <span style="font-size: 20px;
                margin-bottom: 25px;">Total registrations</span>
                <br>
                <br>
                <span style="font-size: 40px;
                ">{{totalReg}}</span>
            </mat-card>

        <mat-card class="mat-elevation-z4">
                <span style="font-size: 20px;
            margin-bottom: 25px;">Total Users Signed Up</span>
            <br>
            <br>
            <span style="font-size: 40px;
            ">{{($users | async)?.length}}</span>
            </mat-card>

</div>