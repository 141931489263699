import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import window from 'global';

@Component({
  selector: 'app-host-success',
  templateUrl: './host-success.component.html',
  styleUrls: ['./host-success.component.css']
})
export class HostSuccessComponent {
  uid: string;
  link = 'https://webiknowr.com/webinar/';
  copyFlag = false;
  constructor(
    private dialogRef: MatDialogRef<HostSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private route: Router
  ) {
    this.uid = data;
    this.link = this.link + this.uid;
  }

  close() {
    this.dialogRef.close();
    /*if (typeof window !== "undefined") {
      window.location.reload();
    }
   */
    window.location.reload();
    //this.route.navigate['host'];
  }

  openMyWebinars() {
    this.dialogRef.close();
    this.route.navigate(['/user/dashboard/host']);
  }

  copyLink() {
    this.copyFlag = true;
  }
}
