 <!--<div class="jumbotron">
       
        <div class="container host">
                <h1 style="text-align: center; font-family: 'Nunito';">Wish to host a webinar?</h1>
                <h1 style="text-align: center; font-family: 'Nunito';"><a routerLink="login">Login/Signup</a> to host a webinar</h1>
        </div>
          </div>
   -->

   <section id="home-1" class="bg-blue">
           
                <div class="container h-100">
                    <div class="row h-100 align-items-center">
                        <div class="col-lg-6">
                            <div class="home-content">
                                <h1 class="tagline typer">Submit your webinar and leave the rest to us</h1>
                                <button routerLink="login" mat-raised-button color="primary">Lets get started</button>
                               
                            </div>
                        </div>
                        <div class="col-lg-6">
                             <div class="home-1-image">
                                  <img src="../../../assets/Posts-amico.svg" class="img-fluid floating wow fadeInDown animated" alt="Image not loaded" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: floating;" alt="no-image">
                             </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features" class="space-big">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8 mx-auto center">
                                    <div class="section-heading">
                                        <h1>Why us?</h1>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 mb-space">
                                    <div class="features-box wow fadeInDown" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInDown;">
                                        <img src="../../../assets/free.svg" class="left img-fluid" alt="Image not loaded">
                                        <div class="features-content">
                                            <h6>Free to post</h6>
                                            <p>You can post any number of webinars for free.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mb-space">
                                    <div class="features-box wow fadeInDown" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInDown;">
                                        <img src="../../../assets/easy.svg" class="left img-fluid" alt="Image not loaded">
                                        <div class="features-content">
                                            <h6>Easy to post</h6>
                                            <p>Submitting a webinar takes only 2 mins</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12 mb-space">
                                    <div class="features-box wow fadeInDown" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInDown;">
                                        <img src="../../../assets/customer-service.svg" class="left img-fluid" alt="Image not loaded">
                                        <div class="features-content">
                                            <h6>Premium Support</h6>
                                            <p>multiple support channels for all your queries.</p>
                                        </div>
                                    </div>
                                </div>
                                
                               
                
                            </div>
                        </div>
                    </section>
                    <section id="fact" class="space-big bg-blue">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-8 mx-auto center">
                                            <div class="section-heading mb-space">
                                                <h1>What does our numbers say</h1>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <img src="../../../assets/facts.svg" class="facts-image mob-fix-1" alt="Image not loaded">
                                            <div class="fact-content center">
                                                <h1 class="counter">20+</h1>
                                                <h6 class="upp">Webinars posted daily</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <div class="fact-content center">
                                                <h1 class="counter">10+</h1>
                                                <h6 class="upp">Daily new partnerships</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <div class="fact-content center">
                                                <h1 class="counter">50+</h1>
                                                <h6 class="upp">Daily positive feedbacks</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <div class="fact-content center">
                                                <h1 class="counter">200+</h1>
                                                <h6 class="upp">Daily active users</h6>
                                            </div>
                                        </div>
                        
                                    </div>
                                </div>
                            </section>
        
  