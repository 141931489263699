import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore/public_api';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { ShareComponent } from 'src/app/dialogs/share/share.component';
import { IndustryService } from 'src/app/service/industry.service';
import { Category } from './../../interfaces/Category.model';
import { Industry } from './../../interfaces/Industry.model';
import { Webinar } from './../../interfaces/webinars.model';
import { CategoryService } from './../../service/category.service';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { AllEventService } from './all-event.service';

@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.scss']
})
export class AllEventsComponent implements OnInit, OnDestroy {
  webinars: Array<Webinar> = [];
  originalWebinars: Array<Webinar>;
  categories: Array<Category>;
  industries: Array<Industry>;
  allFlag = false;
  todayFlag = false;
  tomorrowFlag = false;
  weekFlag = false;
  config: any;
  sort: any;
  totalLength: number;
  searchText: string;
  priceFilterChecked = true;
  dataSource: MatTableDataSource<Webinar>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //addedy by preksha for homr to attend page
  @ViewChild('multipleCategories', { static: true }) multipleCategories: any;
  @ViewChild('multipleIndustries', { static: true }) multipleIndustries: any;

  todoCollectionRef: AngularFirestoreCollection<Webinar>;
  webinars$: Observable<Webinar>;
  cat: Category = {
    name: '',
    img: '',
    id: ''
  };
  //formcontrols
  topicFormControl = new FormControl();
  durationFormControl = new FormControl();
  languageFormControl = new FormControl();
  speakersFormControl = new FormControl();

  topicValue: any[];
  durationValue: any[];
  speakersValue: any[];
  languageValue: any[];
  priceFilter: string;

  categoryFormControl = new FormControl();
  industryFormControl = new FormControl();
  priceFormControl = new FormControl();
  categoryFilterValue: any[];
  industryFilterValue: any[];
  priceFilterValue: any[];
  selectedVal = '1';
  list1: any[];

  subscriptions: Subscription[] = [];
  subscription1$: Subscription;
  subscription2$: Subscription;
  subscription3$: Subscription;

  constructor(
    private dialog: MatDialog,
    private allEventService: AllEventService,
    private categoryService: CategoryService,
    private industryService: IndustryService,

    private router: Router,
    private commService: CommunicationServiceService,
    private ngxService: NgxUiLoaderService,
    private _ref: ChangeDetectorRef
  ) {
    this.config = {
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: this.totalLength
    };
    this.searchText = this.commService.searchText;
    //alert(this.multipleCategories);
    //console.log(this.multipleCategories);
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }
  openDialog(selectedWebinar: Webinar) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    // console.log(selectedWebinar);
    dialogConfig.data = selectedWebinar;

    this.dialog.open(ShareComponent, dialogConfig);
  }

  openWebinarDetails(id) {
    this.router.navigate(['/webinar', id]);
  }

  testFilter() {
    //console.log(this.categoryFilterValue);
    this.cat.name = 'Design - UI/UX';
    this.categoryFilterValue.push('Design - UI/UX');
  }

  async ngOnInit(): Promise<any> {
    // console.log(this.searchText);
    // console.log(this.multipleCategories);
    this.ngxService.start();
    // Do something here...

    this.subscription1$ = this.allEventService
      .getWebinars()
      .subscribe((data) => {
        this.ngxService.stop();
        //console.log(data);
        // console.log(JSON.stringify(data));
        const sorted = data.sort((a, b) => {
          const dt1 = Date.parse(
            `${a.schedule.startDate} ${a.schedule.startTime}`
          );
          const dt2 = Date.parse(
            `${b.schedule.startDate} ${b.schedule.startTime}`
          );

          return dt2 - dt1;
        });
        // console.log(sorted)
        const arr = sorted.reverse();
        // console.log(arr)

        // console.log(JSON.stringify(arr));

        this.webinars = arr;
        this.originalWebinars = this.webinars;
        //console.log( this.webinars.length);
        this.totalLength = this.webinars.length;
      });

    this.subscription2$ = this.categoryService
      .getCategories()
      .subscribe((data) => {
        //console.log(data);
        this.categories = data;
      });

    this.subscription3$ = this.industryService
      .getIndustries()
      .subscribe((data) => {
        //console.log(data);
        this.industries = data;
      });

    this.subscriptions.push(this.subscription1$);
    this.subscriptions.push(this.subscription2$);
    this.subscriptions.push(this.subscription3$);

    this.showAllWebinars();

    //addedy by preksha for homr to attend page
    if (this.commService.categoryName) {
      // alert(this.multipleCategories);
      //const catSelect : HTMLElement= document.getElementById("categoryselect");
      this.multipleCategories.writeValue([this.commService.categoryName]);

      // document.getElementById("categoryselect").innerHTML=new Array(this.commService.categoryName);
      this.categoryFormControl.setValue([this.commService.categoryName]);
      /*this.categoryFilterValue=[];
   this.categoryFilterValue.push(this.commService.categoryName);*/
    }

    //addedy by preksha for homr to attend page
    if (this.commService.industryName) {
      this.multipleIndustries.writeValue([this.commService.industryName]);
      this.industryFormControl.setValue([this.commService.industryName]);
      /*this.industryFilterValue = [];
    this.industryFilterValue.push(this.commService.industryName);*/
    }
  }

  //function to remove category from active filters chips
  removeCatergory(category: Category, multipleCategories): void {
    const index = this.categoryFormControl.value.indexOf(category);
    if (index >= 0) {
      const updatedValue = Object.assign([], this.categoryFormControl.value);
      updatedValue.splice(index, 1);
      this.categoryFormControl.patchValue(updatedValue);
      multipleCategories.writeValue(updatedValue);
    }
  }
  //function to remove industry from active filters chips
  removeIndustry(industry: Industry, multipleIndustries): void {
    const index = this.industryFormControl.value.indexOf(industry);
    if (index >= 0) {
      const updatedValue = Object.assign([], this.industryFormControl.value);
      updatedValue.splice(index, 1);
      this.industryFormControl.patchValue(updatedValue);
      multipleIndustries.writeValue(updatedValue);
    }
  }

  //function to remove duration from active filters chips
  removeDuration(duration, multipleDuration): void {
    const index = this.durationFormControl.value.indexOf(duration);
    if (index >= 0) {
      const updatedValue = Object.assign([], this.durationFormControl.value);
      updatedValue.splice(index, 1);
      this.durationFormControl.patchValue(updatedValue);
      multipleDuration.writeValue(updatedValue);
    }
  }

  //function to remove priceFilter from active filters chips
  removePrice(price): void {
    this.priceFilter = '';
  }
  //function to remove priceFilter from active filters chips
  removeSearch(): void {
    this.searchText = '';
  }

  public onValChange(val: string) {
    this.selectedVal = val;
  }

  selectPost(webinar) {}

  durationList = [
    {
      value: '30-60 minutes',
      min: '30',
      max: '60'
    },
    {
      value: '1-2 hours',
      min: '60',
      max: '120'
    },
    {
      value: 'more than 2 hours',
      min: '120',
      max: '1000'
    }
  ];

  showFilters = false;
  toggleFilters() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
    }
  }

  resetFilters() {}

  showAllWebinars() {
    this.allFlag = true;
    this.todayFlag = false;
    this.tomorrowFlag = false;
    this.weekFlag = false;

    this.webinars = this.originalWebinars;
  }
  showTodayWebinars() {
    this.allFlag = false;
    this.todayFlag = true;
    this.tomorrowFlag = false;
    this.weekFlag = false;

    this.webinars = this.originalWebinars;
    const formattedDate = this.getformattedDate();
    //console.log(formattedDate);
    this.webinars = this.webinars.filter((data) => {
      if (data.schedule.startDate === formattedDate) {
        return data;
      }
    });
  }

  getformattedDate() {
    const currentDate = new Date();
    return dayjs(currentDate).format('YYYY-MM-DD');
  }

  showTomorrowWebinars() {
    this.allFlag = false;
    this.todayFlag = false;
    this.tomorrowFlag = true;
    this.weekFlag = false;

    this.webinars = this.originalWebinars;
    const momentDate = new Date();
    momentDate.setDate(momentDate.getDate() + 1); // Replace event.value with your date value
    const formattedDate = dayjs(momentDate).format('YYYY-MM-DD');
    //console.log(formattedDate);
    this.webinars = this.webinars.filter((data) => {
      if (data.schedule.startDate === formattedDate) {
        return data;
      }
    });
  }

  showThisWeekWebinars() {
    this.allFlag = false;
    this.todayFlag = false;
    this.tomorrowFlag = false;
    this.weekFlag = true;

    this.webinars = this.originalWebinars;

    const thisWeekDate = new Date();
    thisWeekDate.setDate(thisWeekDate.getDate() + 6);
    const formattedThisWeekDate = dayjs(thisWeekDate).format('YYYY-MM-DD');

    const todayDate = new Date();

    const formattedTodayDate = dayjs(todayDate).format('YYYY-MM-DD');

    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const formattedTomorrowDate = dayjs(tomorrowDate).format('YYYY-MM-DD');

    this.webinars = this.webinars.filter((data) => {
      if (
        dayjs(data.schedule.startDate).isBefore(formattedThisWeekDate) &&
        dayjs(data.schedule.startDate).isAfter(formattedTodayDate)
      ) {
        return data;
      }
    });
  }

  applySort(sortValue) {
    if ((sortValue = 'price-low-high')) {
    }
  }
  favoriteSelected = false;
  toggleFavorite() {
    this.favoriteSelected = !this.favoriteSelected;
  }

  compare(a: Webinar, b: Webinar) {}

  applySorting(type) {
    if (type == 'Newest') {
      this.webinars.sort((a, b) =>
        a.schedule.startDate.localeCompare(b.schedule.startDate)
      );
    } else if (type == 'Oldest') {
      this.webinars.sort((a, b) =>
        b.schedule.startDate.localeCompare(a.schedule.startDate)
      );
    }

    //console.log(this.webinars);
  }

  hideloader() {
    // Setting display of spinner
    // element to none
    document.getElementById('loading').style.display = 'none';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
