<div>

        <div #maintabs class="col-md-12" >
                                        
                <mat-tab-group *ngIf="hostFlag" class="tab-group" dynamicHeight >
                        <mat-tab label="Upcoming">
                          
                      
                            <span class="tab-content">
                                   <!--
                                            <div 
                                                    *ngFor="let x of webinars">
                                                    <mat-card class="mat-elevation-z4" >
                                                            <div class="container-card-img">
                                                                            <img *ngIf="x.webinar.webinarInfo.posterUrl"  mat-card-image style="width: calc(100% + 32px) !important;" src="{{x.webinar.webinarInfo.posterUrl}}" >
                                                                            <img *ngIf="!x.webinar.webinarInfo.posterUrl"   mat-card-image style="width: calc(100% + 32px) !important;" src="../../../../assets/img-alt.jpg">
                                                                    
                                                                           
                                                                    
                                                             </div>
                                                    
                                
                                                            <mat-card-content>
                                                                    <p  matTooltip="{{x.webinar.webinarInfo.title}}" matTooltipClass='red-tooltip'
                                                                            style="text-align: left; font-weight: 500; font-size: 1.2em ;">
                                                                           
                                                                            {{ (x.webinar.webinarInfo.title.length > 30) ? (x.webinar.webinarInfo.title | slice:0:30) + '...' : x.webinar.webinarInfo.title }}
                                                                    </p>
                                                                    <mat-divider></mat-divider>
                                                                    <br>
                                                                   
                                                                    <div>
                                                                                    <div style="display: flex;justify-content: space-between">
                                                                                    <div class="outer-div">
                                                                                            <mat-icon style="color: #792cc7;font-size: 19px;">calendar_today</mat-icon>&nbsp;
                                                                                    <span
                                                                                            *ngIf="x.webinar.schedule.startDate==x.webinar.schedule.endDate">{{x.webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
                                                                                    <span *ngIf="x.webinar.schedule.startDate!=x.webinar.schedule.endDate">{{x.webinar.schedule.startDate | date : "EEE dd MMM"}}
                                                                                            -
                                                                                            {{x.webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
                                                                            </div>
                                                                            &nbsp;&nbsp;
                                                                            <div class="outer-div">
                                                                                            <mat-icon style="color: #c94190; font-size: 19px;">alarm</mat-icon>
                                                                                    <span>{{x.webinar.schedule.startTime}} - {{x.webinar.schedule.endTime}}</span>
                                                                            </div>
                                                                    </div>
                                                                            
                                                                            
                                                                            <div style="margin-top: 10px; display: flex; flex-direction: row;justify-content: space-between" >
                                                                         <div style="display: flex; flex-direction: row;justify-content: flex-start">
                                                                            <img *ngIf="!x.webinar.organizer.contactInfo.logo" mat-card-avatar  src="../../../../assets/food.jpg" alt="">
                                                                            <img *ngIf="x.webinar.organizer.contactInfo.logo" mat-card-avatar  src="{{x.webinar.organizer.contactInfo.logo}}" alt="">
                                                                           <span  matTooltip="{{x.webinar.organizer.contactInfo.name}}" matTooltipClass='red-tooltip' style="    margin-left: 5px;
                                                                           margin-top: 8px;">{{ (x.webinar.organizer.contactInfo.name.length > 20) ? (x.webinar.organizer.contactInfo.name | slice:0:20) + '...' : x.webinar.organizer.contactInfo.name }}</span> 
                                                                         </div> 
                                                                                    
                                                                                            <button mat-icon-button style="color: white"  ><mat-icon>share</mat-icon></button>
                                                                                            </div>
                                                                                            
                                                                            
                                                                    </div>
                                                            </mat-card-content>
                                                           
                                                    </mat-card>
                                            </div>
                                        -->
                                        <table  *ngIf="upcomingWebinars.length>0" class="table table-hover .table-responsive table-custom">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Schedule</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Registrations</th>
                                                    <th  scope="col">Actions</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr *ngFor="let y of upcomingWebinars;let i=index;">
                                                    <th scope="row">{{i+1}}</th>
                                                    <td>{{y.webinar.webinarInfo.title}}</td>
                                                    <td> <span
                                                        *ngIf="y.webinar.schedule.startDate==y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
                                                <span *ngIf="y.webinar.schedule.startDate!=y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}
                                                        -
                                                        {{y.webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
                                                    <br>
                                                    {{y.webinar.schedule.startTime}} - {{y.webinar.schedule.endTime}}
                                                    </td>
                                                    <td> <span *ngIf="y.webinar.feeInfo.paid=='false'">Free</span>
                                                        <span *ngIf="y.webinar.feeInfo.paid=='true'">{{y.webinar.feeInfo.feeInINR | currency:'INR'}}</span></td>
                                                 <td>{{y.totalHits}}</td>
                                                 
                                                        <td>
                                                          <button (click)="routeToSummary(y)"  matTooltip="View Details" mat-icon-button><mat-icon>visibility</mat-icon></button>
                                                         
                                                          <button  (click)="delete(y)"   matTooltip="Delete" mat-icon-button><mat-icon>delete</mat-icon></button>
                                                         
                                                
                                                </td>
                                                    </tr>
                                                
                                                </tbody>
                                              </table>
                                              <div class="empty" *ngIf="upcomingWebinars.length==0">
                                                    No upcoming webinars
                                                   
                                                </div>

                                              
                            </span>
                         </mat-tab>

                         <mat-tab label="Previous">
                          
                      
                                <span class="tab-content">
                                    
                                        
                                            <table *ngIf="previousWebinars.length>0" class="table table-hover table-custom">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Schedule</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Registrations</th>
                                                        <th  scope="col">Actions</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let y of previousWebinars;let i=index;">
                                                        <th scope="row">{{i+1}}</th>
                                                        <td>{{y.webinar.webinarInfo.title}}</td>
                                                        <td> <span
                                                            *ngIf="y.webinar.schedule.startDate==y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
                                                    <span *ngIf="y.webinar.schedule.startDate!=y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}
                                                            -
                                                            {{y.webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
                                                        <br>
                                                        {{y.webinar.schedule.startTime}} - {{y.webinar.schedule.endTime}}
                                                        </td>
                                                        <td> <span *ngIf="y.webinar.feeInfo.paid=='false'">Free</span>
                                                            <span *ngIf="y.webinar.feeInfo.paid=='true'">{{y.webinar.feeInfo.feeInINR | currency:'INR'}}</span></td>
                                                            <td>{{y.totalHits}}</td>
                                                            <td><button (click)="routeToSummary(y)" matTooltip="View Details" mat-icon-button><mat-icon>visibility</mat-icon></button>
                                                       
                                                        <button (click)="delete(y)" matTooltip="delete" mat-icon-button><mat-icon>delete</mat-icon></button>
                                                    
                                                    </td>
                                                        </tr>
                                                    
                                                    </tbody>
                                                  </table>
                                                  <div class="empty" *ngIf="previousWebinars.length==0">
                                                        No previous webinars
                                                       
                                                    </div>
                                </span>
                             </mat-tab>
            </mat-tab-group>
          
        <div class="host-get-started" *ngIf="webinarsUsersMapping.length==0">You have not hosted any webinar.
                <br><br><button routerLink="/host" mat-raised-button color="primary">Host a webinar</button>
        </div>
</div>

<!--
<div class="col-md-12">

                <div class="wrapper">
                              
                                <nav id="sidebar">
                                    <div class="sidebar-header">
                                        <div *ngIf="!profileImage"><img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQNL_ZnOTpXSvhf1UaK7beHey2BX42U6solRA&usqp=CAU" style="    width: 70px;
                                        height: 70px;
                                        border-radius: 100%;
                                        margin-left: 64px;
                                        "> </div>
                                        <div *ngIf="profileImage"><img  src="{{profileImage}}"  style="    width: 70px;
                                            height: 70px;
                                            border-radius: 100%;
                                            margin-left: 64px;
                                           "> </div>
                        
                        
                                        <h3 style="margin-left: 53px;
                                        margin-top: 5px;
                                        margin-bottom: -10px;">Hi, {{userName}}</h3>
                        
                                       
                                      
                                    </div>
                        
                                    <ul class="list-unstyled components">
                                       
                                        <li >
                                                
                                                <a (click)="selectedMenu('d')"><span class="menu-links"> <i class="fas fa-home"></i> Dashboard</span></a>
                                              
                                            </li>
                          
                                        <li >
                                                <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle "><span  class="menu-links"> <i class="fas fa-calendar"></i> Webinars</span></a>
                                                <ul class="collapse list-unstyled" id="homeSubmenu">
                                                    <li>
                                                        <a (click)="selectedMenu('hw')">Hosted Webinars</a>
                                                    </li>
                                                  
                                                </ul>
                                            </li>
                                       
                                       
                                    </ul>
                        
                                   
                                </nav>
                        
                              
                                <div id="content">
                        
                                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                                        <div class="container-fluid">
                        
                                            <button type="button" (click)="myFunc($event)" id="sidebarCollapse" class="btn btn-info">
                                                <i class="fas fa-align-left"></i>
                                                
                                            </button>
                                          
                                        </div>
                                    </nav>
                        
                                   <div *ngIf="dashboardSelected">
                                       <div class="dashboard-card">
                                    <mat-card class="mat-elevation-z4">
                                        <span style="font-size: 20px;
                                        margin-bottom: 25px;">Total Webinars Submitted</span>
                                        <br>
                                        <span style="font-size: 40px;
                                        ">{{this.totalWebinars}}</span>
                                    </mat-card>
                        
                                    <mat-card class="mat-elevation-z4">
                                            <span style="font-size: 20px;
                                        margin-bottom: 25px;">Total Users Registered</span>
                                        <br>
                                        <span style="font-size: 40px;
                                        ">{{this.totalRegistered}}</span>
                                        </mat-card>
                        
                                    </div>
                                   </div>
                                   <div *ngIf="hostSelected">
                                 
                        
                                    <app-timeline [webinars]="webinarsUsersMapping"></app-timeline>
                                   </div> 
                        
                                   <div *ngIf="attendSelected">
                                        <h2>attend selected</h2>
                                   </div>
                                  
                                </div>
                            </div>
                        
                        



</div>
-->

<br>

      
    
</div>