import { AllEventService } from './../../events/all-events/all-event.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';

import { Webinar } from './../../interfaces/webinars.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShareComponent } from 'src/app/dialogs/share/share.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-featured-events',
  templateUrl: './featured-events.component.html',
  styleUrls: ['./featured-events.component.scss']
})
export class FeaturedEventsComponent implements OnInit, OnDestroy {
  webinars: Array<Webinar>;
  webinars1: Webinar[] = [];
  subscription1$: Subscription;
  constructor(
    private allEventService: AllEventService,
    private dialog: MatDialog,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    //this.ngxService.start();
    // Do something here...
    this.ngxService.startBackgroundLoader('loader-01');
    this.allEventService
      .getFeaturedWebinars()
      .pipe(take(1))
      .pipe(map((x) => x.slice(0, 4)))
      .subscribe((data) => {
        //console.log(data);
        // this.ngxService.stop();
        this.ngxService.stopBackgroundLoader('loader-01');
        const sorted = data.sort((a, b) => {
          const dt1 = Date.parse(
            `${a.schedule.startDate} ${a.schedule.startTime}`
          );
          const dt2 = Date.parse(
            `${b.schedule.startDate} ${b.schedule.startTime}`
          );

          return dt2 - dt1;
        });
        // console.log(sorted)
        const arr = sorted.reverse();
        // console.log(arr)

        this.webinars = arr;
        /*
      this.webinars=this.webinars.map(data=>{
        var duration='';
        let v1: any[]=data.schedule.startTime.substring(0,5).split(':');
        let v2: any[]=data.schedule.endTime.substring(0,5).split(':');
        if((data.schedule.startTime.includes('AM') && data.schedule.endTime.includes('AM')) 
        || (data.schedule.startTime.includes('PM') && data.schedule.endTime.includes('PM'))){

          let hours=v2[0]-v1[0];
          let mins=v2[1]-v1[1];

          if(hours==0 ){
            duration=(v2[1]-v1[1])+' min';
          }if (mins==0){
            duration=(v2[0]-v1[0])+'h ';
          }if(hours>0 && mins>0){
            duration=(v2[0]-v1[0])+'h '+(v2[1]-v1[1])+' min';
          }
          
          console.log(duration);

         
        } 
        else {
          console.log('else');

          
        }
        
         console.log(v1);
         console.log(v2);
        return data;
      })
     */
      });
  }

  openDialog(selectedWebinar: Webinar) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    // console.log(selectedWebinar);
    dialogConfig.data = selectedWebinar;

    this.dialog.open(ShareComponent, dialogConfig);
  }

  favoriteSelected = false;
  toggleFavorite() {
    this.favoriteSelected = !this.favoriteSelected;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    margin: 16,
    autoplay: true,
    autoplaySpeed: 200,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };

  ngOnDestroy(): void {
    //this.subscription1$.unsubscribe();
  }
}
