


<div>

    <form [formGroup]="summaryForm"  >

            <div formGroupName="webinarInfo">
            <mat-form-field  appearance="outline" style="width: 100%">
                    <mat-label>Event Name</mat-label>
                    <input  matInput placeholder="Event Name"  formControlName="title">
                    <mat-icon matSuffix>notes</mat-icon>
            </mat-form-field>

            <mat-form-field  appearance="outline" style="width: 100%">
                    <mat-label>Event Description</mat-label>
                    <textarea rows="8" cols="50" matInput placeholder="Event Description" formControlName="description">

                    </textarea>
                   
                    <mat-icon matSuffix>notes</mat-icon>
            </mat-form-field>

            <mat-form-field  appearance="outline" style="width: 100%">
                    <mat-label>Registration Link</mat-label>
                    <input  matInput placeholder="Event Registration Link" formControlName="formLink">
                   
                    <mat-icon matSuffix>link</mat-icon>
            </mat-form-field>
            </div>
            <div formGroupName="feeInfo">
                    <mat-radio-group class="radio" formControlName="paid">
                        <mat-radio-button value=false>Free</mat-radio-button>
                        <mat-radio-button #fees value=true>Paid</mat-radio-button>

                      
                    </mat-radio-group>
                    <br>
                   
                    <mat-form-field *ngIf="summaryForm.get('feeInfo')['controls'].paid.value=='true'" appearance="outline" style="width: 100%">
                        <mat-label>Webinar Price</mat-label>
                        <input required matInput formControlName="feeInINR" placeholder="Enter webinar Price">
                        <mat-icon matSuffix>money</mat-icon>
                        <mat-hint>Enter in Rupees</mat-hint>
                        <!-- error -->
                        <mat-error *ngIf="summaryForm.get('feeInfo')['controls'].feeInINR.touched && summaryForm.get('feeInfo')['controls'].feeInINR.invalid">
                                <span *ngIf="summaryForm.get('feeInfo')['controls'].feeInINR.errors.required">Please select Price</span>
                                <span *ngIf="summaryForm.get('feeInfo')['controls'].feeInINR.errors.pattern">Please enter in digits</span>
                            </mat-error>

                    </mat-form-field>
                </div>

                <div class="row" style="    margin-top: 10px;">
                <div class="col-md-6">
                                   
                        <div formArrayName="category" *ngFor="let x of summaryForm.get('category')['controls'];let i = index">

                                <div [formGroupName]="i">
                       
                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>Category</mat-label>

                                <mat-select formControlName="name" >
                                    <mat-option [value]="category.name" *ngFor="let category of categories">
                                        {{category.name}}
                                    </mat-option>
                                </mat-select>
                            <!--
                                <mat-error *ngIf="myForm.controls.category.touched && myForm.controls.category.invalid">
                                        <span *ngIf="myForm.controls.category.errors.required">Please select category</span>
                                </mat-error>-->
                            </mat-form-field>
                        </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                                <div formArrayName="industry" *ngFor="let x of summaryForm.get('industry')['controls'];let i = index">

                                        <div [formGroupName]="i">
                            <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>Industry</mat-label>
                                
                                    <mat-select formControlName="name">
                                    <mat-option [value]="industry.name" *ngFor="let industry of industries">
                                        {{industry.name}}
                                    </mat-option>
                                </mat-select>
                                
                                <!--
                                <mat-error *ngIf="myForm.controls.industry.touched && myForm.controls.industry.invalid">
                                        <span *ngIf="myForm.controls.industry.errors.required">Please select category</span>
                                </mat-error>-->
                            </mat-form-field>
                           </div>
                           </div>
                        </div>
                    </div>


            <div class="button-row">
                    <div>
                        <button (click)="close()" mat-button><mat-icon>cancel</mat-icon>Cancel</button>
                    </div>
                    <div>
                            <button (click)="submitForm()"  mat-button><mat-icon>save</mat-icon>Save</button>
                    </div>
            </div>

    </form>
        

    

</div>





