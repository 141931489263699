import { CreateProfileSuccessComponent } from './../../dialogs/create-profile-success/create-profile-success.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CategoryService } from './../../service/category.service';
import { Category } from './../../interfaces/Category.model';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { AuthService } from './../../service/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user.model';
import * as dayjs from 'dayjs';

import { ToastrService } from 'ngx-toastr';

import {
  faLinkedin,
  faFacebook,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

import {
  faBirthdayCake,
  faGraduationCap,
  faIndustry
} from '@fortawesome/free-solid-svg-icons';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.css']
})
export class CreateProfileComponent implements OnInit {
  faLinkedIn = faLinkedin;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faBirthdayCake = faBirthdayCake;
  faGraduationCap = faGraduationCap;
  faIndustry = faIndustry;
  user: User;
  _user: any;
  userForm: FormGroup;
  categories: Category[] = [];
  chipControl = new FormControl(new Set());
  selectedChips: any[] = [];
  speakerSelected = false;

  displayName = '';
  email = '';
  uid = '';
  photoURL = '';
  phoneNumber = '';
  dob = '';
  sex = '';
  roles = 'attendee';
  organizerType = 'individual';
  interest: any[] = this.selectedChips;
  designation = '';
  skills: string[] = [];
  profileUrl = '';
  bio = '';

  separateDialCode = true;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private route: Router,
    public authService: AuthService,
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private toasts: ToastrService,
    private imageCompress: NgxImageCompressService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this._user = await this.authService.isLoggedIn();
    this.afs
      .doc<User>(`users/${this._user.uid}`)
      .valueChanges()
      .subscribe((data) => {
        this.user = data;

        if (data.profileCompleted == undefined || !data.profileCompleted) {
          this.userForm = this.fb.group({
            fullName: [
              this.user.fullName ? this.user.fullName : this.user.displayName,
              Validators.required
            ],
            email: [
              { value: this.user.email, disabled: true },
              Validators.required
            ],
            uid: [this.user.uid],
            pictureUrl: [
              this.user.pictureUrl ? this.user.pictureUrl : this.user.photoURL
            ],
            mobileNumber: [
              this.user.mobileNumber
                ? this.user.mobileNumber
                : this.user.phoneNumber,
              [
                Validators.minLength(10),
                Validators.maxLength(10),
                Validators.pattern('^[0-9]*$'),
                Validators.required
              ]
            ],
            dob: [this.user.dob, Validators.required],
            sex: [this.user.sex, Validators.required],
            roles: [this.roles],
            interest: [this.selectedChips, Validators.required],
            designation: [this.user.designation, Validators.required],
            skills: [this.user.skills],
            profileUrl: [this.user.profileUrl],
            bio: [this.user.bio, Validators.required],
            occupation: [this.user.occupation, Validators.required],
            company: [this.user.company, Validators.required],
            organizerType: [this.organizerType, Validators.required],
            college: [this.user.college, Validators.required],
            website: [this.user.website, Validators.required],
            organizerIndustry: [
              this.user.organizerIndustry,
              Validators.required
            ],
            organizerLocation: [
              this.user.organizerLocation,
              Validators.required
            ],
            speakerFavoriteTopics: [
              this.user.speakerFavoriteTopics,
              Validators.required
            ],
            linkedInProfile: [this.user.linkedInProfile, Validators.required],
            facebookProfile: [this.user.facebookProfile],
            twitterProfile: [this.user.twitterProfile],
            profileCompleted: [false]
          });
        } else {
          this.route.navigate(['home']);
        }
      });

    this.categoryService.getCategories().subscribe((data) => {
      //console.log(data);
      this.categories = data;
    });
  }

  submitForm() {
    if (
      this.userForm.controls.roles.value == 'attendee' ||
      this.userForm.controls.roles.value == 'speaker' ||
      (this.userForm.controls.roles.value == 'organizer' &&
        this.userForm.controls.organizerType.value == 'individual')
    ) {
      if (!this.userForm.controls.fullName.valid) {
        this.toasts.warning('Please fill the name field');
        return;
      } else if (!this.userForm.controls.mobileNumber.valid) {
        this.toasts.warning('Please fill the Mobile Number field');
        return;
      } else if (!this.userForm.controls.dob.valid) {
        this.toasts.warning('Please fill the Date Of Birth field');
        return;
      } else if (!this.userForm.controls.sex.valid) {
        this.toasts.warning('Please fill the sex field');
        return;
      } else if (!this.userForm.controls.occupation.valid) {
        this.toasts.warning('Please fill the Occupation field');
        return;
      } else if (this.userForm.controls.occupation.valid) {
        if (this.userForm.controls.occupation.value == 'student') {
          if (!this.userForm.controls.college.valid) {
            this.toasts.warning('Please fill the College field');
            return;
          }
        } else if (this.userForm.controls.occupation.value == 'professional') {
          if (
            !this.userForm.controls.company.valid ||
            !this.userForm.controls.designation.valid
          ) {
            this.toasts.warning(
              'Please fill the company and designation field'
            );
            return;
          }
        }
      }
      if (!this.userForm.controls.bio.valid) {
        this.toasts.warning('Please fill the about yourself field');
        return;
      }
      if (this.userForm.controls.interest.value.length < 3) {
        this.toasts.warning('Please select atleast 3 interests');
        return;
      }
    }
    if (this.userForm.controls.roles.value == 'speaker') {
      if (!this.userForm.controls.speakerFavoriteTopics.valid) {
        this.toasts.warning('Please fill favorite topics field');
        return;
      }
      if (!this.userForm.controls.linkedInProfile.valid) {
        this.toasts.warning('Please fill linkedIn profile field');
        return;
      }
    }
    if (
      this.userForm.controls.roles.value == 'organizer' &&
      this.userForm.controls.organizerType.value == 'organization'
    ) {
      if (!this.userForm.controls.fullName.valid) {
        this.toasts.warning('Please fill the name field');
        return;
      }
      if (!this.userForm.controls.mobileNumber.valid) {
        this.toasts.warning('Please fill the Mobile Number field');
        return;
      }
      if (!this.userForm.controls.website.valid) {
        this.toasts.warning('Please fill the website field');
        return;
      }
      if (!this.userForm.controls.bio.valid) {
        this.toasts.warning('Please fill the about yourself field');
        return;
      }
      if (!this.userForm.controls.linkedInProfile.valid) {
        this.toasts.warning('Please fill linkedIn profile field');
        return;
      }
      if (!this.userForm.controls.organizerIndustry.valid) {
        this.toasts.warning('Please fill industry field');
        return;
      }
      if (!this.userForm.controls.organizerLocation.valid) {
        this.toasts.warning('Please fill location field');
        return;
      }
      if (this.userForm.controls.interest.value.length < 3) {
        this.toasts.warning('Please select atleast 3 interests');
        return;
      }
    }

    this.userForm.controls.profileCompleted.setValue(true);
    this.afs
      .collection('users')
      .doc(this.user.uid)
      .update(this.userForm.value)
      .then((i) => {
        this.openDialog();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  changeSelected(parameter: string, query: string) {
    const index = this.selectedChips.indexOf(query);
    if (index >= 0) {
      this.selectedChips.splice(index, 1);
    } else {
      this.selectedChips.push(query);
    }
  }

  checkExists(name) {
    return this.selectedChips.includes(name);
  }

  selectedContinueType(type) {
    this.speakerSelected = false;
    if (type == 'speaker') {
      this.speakerSelected = true;
    }
  }

  complete() {
    const u = {
      displayName: this.displayName,
      email: this.email,
      uid: this.uid,
      photoURL: this.photoURL,
      mobileNumber: this.phoneNumber,
      dob: this.dob ? this.dob : '',
      sex: this.sex ? this.sex : '',
      roles: this.roles,
      interest: this.interest ? this.interest : [],
      designation: this.designation ? this.designation : '',
      skills: this.skills ? this.skills : '',
      profileUrl: this.profileUrl ? this.profileUrl : '',
      bio: this.bio ? this.bio : ''
    };
  }

  checkStepControl(value) {
    if (
      value == 'basic-info' &&
      this.userForm.controls.fullName &&
      this.userForm.controls.email &&
      this.userForm.controls.dob &&
      this.userForm.controls.mobileNumber &&
      this.userForm.controls.sex
    ) {
      return true;
    }

    return false;
  }

  dateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    // Prevent Saturday and Sunday from being selected.
    //console.log(this.myForm.get('schedule')['controls']['endDate'].value);

    return d < dt;
  };

  date(e) {
    const momentDate = new Date(e.target.value); // Replace event.value with your date value
    const formattedDate = dayjs(momentDate).format('YYYY-MM-DD');

    //console.log(formattedDate);
    this.userForm.controls.dob.setValue(formattedDate, {
      onlyself: true
    });
  }

  skip() {
    this.route.navigate(['home']);
  }

  setOrganizer() {
    this.userForm.controls.organizerType.patchValue('individual');
  }

  file: any;
  localUrl: any;
  localCompressedURl: any;

  selectFile(event: any) {
    if (event.target.files[0].name.endsWith('.png')) {
      this.toasts.warning('Please upload image with .JPEG or .JPG extension');

      return;
    }

    let fileName: any;
    this.file = event.target.files[0];
    fileName = this.file['name'];

    //console.log(this.myForm.value);

    const imageName = fileName;

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        //console.log(event.target.result);
        this.localUrl = event.target.result;
        this.compressFile(this.localUrl);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image) {
    const orientation = -1;
    //this.sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
    //console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(image, orientation, 100, 60)
      .then((result) => {
        this.localCompressedURl = result;
        //this.sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024)
        //console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        // create file from byte
        this.user.photoURL = this.localCompressedURl;
        this.userForm.controls.pictureUrl.setValue(this.localCompressedURl);

        //console.log(this.myForm.value);

        return this.localCompressedURl;
      });
  }

  async openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '700px';
    dialogConfig.data = this.user;

    dialogConfig.disableClose = true;

    this.dialog.open(CreateProfileSuccessComponent, dialogConfig);
  }
}
