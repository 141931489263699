<div>
        total users : {{($users | async)?.length}}
      </div>
      <br>
    <table class="table table-hover table-custom">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th  scope="col">Email</th>
            <th  scope="col">Mobile</th>
            <th  scope="col">Webinar</th>
            
            
           
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let y of $users | async;let i=index;">
            <th scope="row">{{i+1}}</th>
            <td>{{y.displayName}}</td>
            <td>{{y.email}}</td>
            <td>{{y.phoneNumber}}</td>
            <td><div *ngFor="let x of findWebinars(y.displayName)">
              {{x}}
          </div></td>
      
                
            </tr>
        
        </tbody>
      </table>