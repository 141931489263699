<mat-card class="mat-elevation-z4" >
        <div class="container-card-img">
                        <img *ngIf="webinar.webinarInfo.posterUrl"  [routerLink]="['/webinar', webinar.id]" mat-card-image style="width: calc(100% + 32px) !important;"  [lazyLoad]="webinar.webinarInfo.posterUrl" alt="no-image" ><!--src="{{webinar.webinarInfo.posterUrl}}"-->
                        <img *ngIf="!webinar.webinarInfo.posterUrl"  [routerLink]="['/webinar', webinar.id]" mat-card-image style="width: calc(100% + 32px) !important;" src="../../../../assets/img-alt.jpg" alt="no-image">
                
                        <mat-chip class="action-button-category btn btn-primary top-left mat-elevation-z4" >{{webinar.category[0].name}}</mat-chip>
                        <mat-chip   class="action-button-price btn btn-primary top-right mat-elevation-z4"> 
                                <span *ngIf="webinar.feeInfo.paid=='false'">Free</span>
                                 <span *ngIf="webinar.feeInfo.paid=='true'">{{webinar.feeInfo.feeInINR | currency:'INR'}}</span>
                                
                        </mat-chip>
                
                
         </div>


        <mat-card-content>
                <p  matTooltip="{{webinar.webinarInfo.title}}" matTooltipClass='red-tooltip'
                      class="card-title-desktop" >
                       
                        {{ (webinar.webinarInfo.title.length > 30) ? (webinar.webinarInfo.title | slice:0:30) + '...' : webinar.webinarInfo.title }}
                </p>
                <p  matTooltip="{{webinar.webinarInfo.title}}" matTooltipClass='red-tooltip'
                      class="card-title-mobile" >
                       
                        {{webinar.webinarInfo.title}}
                </p>
                <mat-divider></mat-divider>
                <br>
               
                <div>
                                <div style="display: flex;justify-content: space-between">
                                <div class="outer-div">
                                        <mat-icon style="color: #792cc7;font-size: 19px;">date_range</mat-icon>&nbsp;
                                        
                                <span
                                        *ngIf="webinar.schedule.startDate==webinar.schedule.endDate">{{webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
                                <span *ngIf="webinar.schedule.startDate!=webinar.schedule.endDate">{{webinar.schedule.startDate | date : "EEE dd MMM"}}
                                        -
                                        {{webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
                        </div>
                        &nbsp;&nbsp;
                        <div class="outer-div">
                                        <mat-icon style="color: #c94190; font-size: 19px;">schedule</mat-icon>
                                <span>{{webinar.schedule.startTime}} - {{webinar.schedule.endTime}}</span>
                        </div>
                </div>
                        
                        
                        <div style="margin-top: 10px; display: flex; flex-direction: row;justify-content: space-between" >
                     <div style="display: flex; flex-direction: row;justify-content: flex-start">
                        <img *ngIf="!webinar.organizer.contactInfo.logo" mat-card-avatar  src="../../../../assets/alt-image.jpg" alt="no-image">
                        <img *ngIf="webinar.organizer.contactInfo.logo" mat-card-avatar  src="{{webinar.organizer.contactInfo.logo}}" alt="no-image">
                       <span  matTooltip="{{webinar.organizer.contactInfo.name}}" matTooltipClass='red-tooltip' style="    margin-left: 5px;
                       margin-top: 8px;">{{ (webinar.organizer.contactInfo.name.length > 20) ? (webinar.organizer.contactInfo.name | slice:0:20) + '...' : webinar.organizer.contactInfo.name }}</span> 
                     </div> 
                                
                                
                                        <!--
                                                <mat-chip   class="action-button-price btn btn-primary mat-elevation-z4"> 
                                                               <span *ngIf="webinar.feeInfo.paid=='false'">Free</span>
                                                                <span *ngIf="webinar.feeInfo.paid=='true'">{{webinar.feeInfo.feeInINR | currency:'INR'}}</span>
                                                               
                                                </mat-chip>
                                                <span *ngIf="webinar.feeInfo.paid=='false'">Free</span>
                                                <span *ngIf="webinar.feeInfo.paid=='true'">{{webinar.feeInfo.feeInINR | currency:'INR'}}</span>
                                        -->
                                        <button mat-icon-button style="color: white" (click)="openDialog(webinar)" ><mat-icon>share</mat-icon></button>
                                        </div>
                                        
                        
                </div>
        </mat-card-content>
       
</mat-card>