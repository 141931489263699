import { IndustryService } from './../../service/industry.service';

import { AllEventService } from './../../events/all-events/all-event.service';
import { ActivatedRoute } from '@angular/router';
import { Industry } from './../../interfaces/Industry.model';
import { Category } from './../../interfaces/Category.model';
import { Component, OnInit, Input } from '@angular/core';
import { Webinar } from 'src/app/interfaces/webinars.model';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss']
})
export class IndustryComponent implements OnInit {
  webinars: Array<Webinar> = [];

  searchText: string;

  industry: Industry;
  industryName: string;

  webinar: Webinar;

  constructor(
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private industryService: IndustryService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.industryName = params.get('name');
    });

    /*
    this.allEventService.getWebinarByIndustry(this.industryName)
    .subscribe(data => {
      // this.employees stores list of employee get from search result
      this.webinars = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Webinar;
      });
    });
    */

    this.allEventService.getWebinars().subscribe((data) => {
      this.webinars = data;

      this.webinars = this.webinars.filter(
        (data) => data.industry[0].name == this.industryName
      );
    });

    /*
    this.industryService.getWebinarsFromIndustry(this.industryName).subscribe(
      data=>{
        this.webinars=data;
        console.log(this.webinars);
      }
    )
*/
  }

  favoriteSelected = false;
  toggleFavorite() {
    this.favoriteSelected = !this.favoriteSelected;
  }
}
