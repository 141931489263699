import { Category } from './../../interfaces/Category.model';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';
import { RecordingInfo } from './../../interfaces/RecordingInfo.model';
import { Speaker } from './../../interfaces/Speaker.model';
import { AuthService } from './../../service/auth.service';
import { awsApi } from 'src/environments/environment.prod';

import { webinarsUsersMapping } from './../../interfaces/webinarsUsersMapping.module';
import { User } from './../../interfaces/user.model';
import { Webinar } from './../../interfaces/webinars.model';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Observable, throwError } from 'rxjs';
import { map, take, flatMap, catchError } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import * as dayjs from 'dayjs';
import { Organizer } from 'src/app/interfaces/Organizer.model';

@Injectable({
  providedIn: 'root'
})
export class AllEventService {
  user: User;
  webinarsUsersMapping: WebinarsUsersMapping = webinarsUsersMapping;
  webinarsUsersMappingList: WebinarsUsersMapping[];
  $webinarsUsersMappingList: Observable<WebinarsUsersMapping[]>;
  $webinarsUsersMapping: Observable<WebinarsUsersMapping>;
  webinarsCollection: AngularFirestoreCollection<Webinar>;
  webinarsUserMappingCollection: AngularFirestoreCollection<WebinarsUsersMapping>;
  webinars: Observable<Webinar[]>;
  webinarsDoc: AngularFirestoreDocument<Webinar>;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    public auth: AuthService
  ) {
    const formattedCurrentDate = this.getformattedDate();

    this.webinarsCollection = firestore.collection('webinars', (ref) =>
      ref.where('status', '==', 'Approved').orderBy('schedule', 'asc')
    );
  }

  getformattedDate() {
    const currentDate = new Date();
    return dayjs(currentDate).format('YYYY-MM-DD');
  }
  getWebinarsOfHost(uid) {
    return this.firestore
      .collection('webinars', (ref) =>
        ref.where('hostUserId', '==', uid).orderBy('schedule', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Webinar;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      );
  }

  getWebinarsMappingOfHost(uid): Observable<WebinarsUsersMapping[]> {
    return this.firestore
      .collection<WebinarsUsersMapping>('webinars-users-mapping', (ref) =>
        ref.where('webinar.hostUserId', '==', uid)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as WebinarsUsersMapping;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      );
  }

  getSpeakers() {
    return this.firestore.collection<Speaker>('speaker').valueChanges();
  }

  getOrganizers() {
    return this.firestore.collection<Organizer>('organizer').valueChanges();
  }

  getWebinars(): Observable<Webinar[]> {
    //console.log(this.webinars);

    //return  this.http.post<Webinar[]>(awsApi.getAllWebinars,null);
    const currentDate = new Date();
    const formattedCurrentDate = this.getformattedDate();
    //,ref=>ref.where('schedule.startDate', '>=', formattedCurrentDate)
    return this.firestore
      .collection('webinars')
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Webinar;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      )
      .pipe(
        map((txs) =>
          txs.filter((txn) => {
            const d = Date.parse(
              `${txn.schedule.startDate} ${txn.schedule.startTime}`
            );
            return dayjs(d).isAfter(currentDate);
          })
        )
      );
  }

  getRecordedWebinars(): Observable<Webinar[]> {
    //console.log(this.webinars);

    //return  this.http.post<Webinar[]>(awsApi.getAllWebinars,null);

    const formattedCurrentDate = this.getformattedDate();

    return this.firestore
      .collection(
        'webinars',
        (ref) =>
          ref.where('schedule.startDate', '>=', formattedCurrentDate) &&
          ref.where('recordingInfo.recorded', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Webinar;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      );
  }

  async createWebinar(webinar: Webinar) {
    //console.log(webinar);
    if (webinar.recordingInfo.recordingLink) {
      webinar.recordingInfo.recorded = true;
    }
    const creationDate = new Date();
    webinar.creationDate = creationDate;
    //console.log(localStorage.getItem('user'));

    const u = await this.auth.isLoggedIn();
    webinar.hostUserId = u.uid;
    webinar.status = 'Approved';
    webinar.featured = true;
    const dur = this.getDuration(webinar);
    webinar.schedule.durationInMinutes = dur;

    let webid;

    // console.log(JSON.stringify(webinar));
    await this.firestore
      .collection('webinars')
      .add(webinar)
      .then(function (docRef) {
        //console.log("Document written with ID: ", docRef.id);
        webid = docRef.id;
      })
      .catch(function (error) {
        console.log('Error adding document: ', error);
      });
    // console.log( webid);
    if (webid) {
      // console.log( "calling mapping");
      this.addUserMapping(webinar, webid);
    }

    this.addSpeaker(webinar);
    this.addOrganizer(webinar);
    this.addTags(webinar);

    //this.sendEmail(webinar,"submission");

    return webid;
  }

  async sendEmail(webinar?: Webinar, type?: any) {
    const user = await this.auth.isLoggedIn();

    let schedule = '';
    let jsonObject = {};
    if (webinar) {
      if (webinar.schedule.startDate == webinar.schedule.endDate) {
        schedule =
          webinar.schedule.startDate +
          ' | ' +
          webinar.schedule.startTime +
          ' - ' +
          webinar.schedule.endTime;
      } else {
        schedule =
          webinar.schedule.startDate +
          ' - ' +
          webinar.schedule.endDate +
          ' | ' +
          webinar.schedule.startTime +
          ' - ' +
          webinar.schedule.endTime;
      }
      jsonObject = {
        webinarName: webinar.webinarInfo.title,
        schedule: schedule,
        organizerName: webinar.organizer.contactInfo.name,
        toEmail: user.email,
        userName: user.displayName,
        posterUrl: webinar.webinarInfo.posterUrl,
        type: type
      };
    } else {
      jsonObject = {
        toEmail: user.email,
        userName: user.displayName,
        type: type
      };
    }

    try {
      //console.log();
      this.http
        .post(
          'https://webiknowr.ap-south-1.elasticbeanstalk.com/notification/sendMail',
          jsonObject,
          this.httpOptions
        )
        .toPromise()
        .then((data) => {})
        .catch((data) => {});
    } catch (error) {
      console.error(error);
    }
  }

  async addTags(webinar: Webinar) {
    webinar.tags.forEach(async (tag) => {
      await this.firestore
        .collection('tags', (ref) => ref.where('name', '==', tag.name))
        .valueChanges()
        .pipe(take(1))
        .subscribe((data) => {
          if (data.length == 0) {
            const creationDate = new Date();
            tag.creationDate = creationDate;

            this.firestore.collection('tags').add(tag);
          }
        });
    });
  }

  async addSpeaker(webinar: Webinar) {
    let count = 0;
    webinar.speakers.forEach(async (speaker) => {
      await this.firestore
        .collection('speaker', (ref) =>
          ref.where('name', '==', speaker.contactInfo.name)
        )
        .valueChanges()
        .pipe(take(1))
        .subscribe((data) => {
          if (data.length == 0) {
            count = 1;
            const creationDate = new Date();
            speaker.contactInfo.creationDate = creationDate;
            this.firestore.collection('speaker').add(speaker.contactInfo);
          }
        });
    });
  }

  async addOrganizer(webinar: Webinar) {
    let count = 0;

    await this.firestore
      .collection('organizer', (ref) =>
        ref.where('name', '==', webinar.organizer.contactInfo.name)
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe((data) => {
        if (data.length == 0) {
          count = 1;
          const creationDate = new Date();
          webinar.organizer.contactInfo.creationDate = creationDate;
          this.firestore
            .collection('organizer')
            .add(webinar.organizer.contactInfo);
        }
      });
  }

  addUserMapping(webinar, id) {
    webinarsUsersMapping.webinar = webinar;
    webinarsUsersMapping.webinarId = id;
    webinarsUsersMapping.userId = [];

    webinarsUsersMapping.totalHits = 0;
    const creationDate = new Date();
    webinarsUsersMapping.creationDate = creationDate;
    this.firestore
      .collection('webinars-users-mapping')
      .add(webinarsUsersMapping);
  }

  getDuration(data) {
    let duration = 0;
    const v1: any[] = data.schedule.startTime.substring(0, 5).split(':');
    const v2: any[] = data.schedule.endTime.substring(0, 5).split(':');
    if (
      (data.schedule.startTime.includes('AM') &&
        data.schedule.endTime.includes('AM')) ||
      (data.schedule.startTime.includes('PM') &&
        data.schedule.endTime.includes('PM'))
    ) {
      const hours = v2[0] - v1[0];
      const mins = v2[1] - v1[1];

      if (hours == 0) {
        duration = v2[1] - v1[1];
      }
      if (mins == 0) {
        duration = (v2[0] - v1[0]) * 60;
      }
      if (hours > 0 && (mins > 0 || mins < 0)) {
        if (mins > 0) duration = (v2[0] - v1[0]) * 60 + (v2[1] - v1[1]);
        else duration = (v2[0] - v1[0]) * 60 - (v1[1] - v2[1]);
      }
    } else {
      const hours = 12 - v1[0];
      const mins = 0 - v1[1];

      if (hours == 0) {
        duration = 0;
      }
      if (mins == 0) {
        duration = (12 - v1[0]) * 60;
      }
      if (hours > 0 && mins <= 0) {
        duration = (12 - v1[0]) * 60 + (0 - v1[1]);
      }

      duration = duration + v2[0] * 60 + (v2[1] - 0);
    }

    return duration;
  }

  getWebinarById(id: string): Observable<Webinar> {
    //return this.http.get<Webinar>(awsApi.getWebinar+"/"+id);

    /*
    let objList= new Array();
    this.firestore.collection<WebinarsUsersMapping>('webinars-users-mapping').valueChanges().subscribe(data=>{
     // console.log(JSON.stringify(data));
      for(let j=20;j<40;j++){

        let obj={
          "categoryName":"",
          "industryName":"",
          "creationDate": "2020-10-31T15:57:48.089Z",
          "description": "string",
          "feesInINR": "string",
          "formLink": "string",
          "id": "string",
          "isApproved": "Y",
          "isDeleted": "N",
          "isFeatured": "Y",
          "isPaid": "string",
          "organizer": {
            "about": "string",
            "createdBy": "string",
            "creationDate": "2020-10-31T15:57:48.089Z",
            "email": "string",
            "logo":"",
            "mobile": "string",
            "name": "string",
            "profileUrl": "string",
            "updatedBy": "string",
            "updationDate": "2020-10-31T15:57:48.089Z"
          },
          "posterUrl": "string",
          "schedule": {
            "createdBy": "string",
            "creationDate": "2020-10-31T15:57:48.090Z",
            "durationInMinutes": "string",
            "endDate": "string",
            "endTime": "string",
          
            "startDate": "string",
            "startTime": "string",
            "updatedBy": "string",
            "updationDate": "2020-10-31T15:57:48.090Z"
          },
          "speaker": [],
          "statusValue": "string",
          "tag": [],
          "title": "string",
          "updationDate": "2020-10-31T15:57:48.090Z",
          "userId": "string",
          "recCount":0
        };

        obj.categoryName=data[j].webinar.category[0].name;
        obj.industryName=data[j].webinar.industry[0].name;
        obj.feesInINR=data[j].webinar.feeInfo.feeInINR;
        obj.formLink=data[j].webinar.webinarInfo.formLink;
        obj.isDeleted="N";
        obj.isApproved="true";
        obj.recCount=data[j].totalHits;
        obj.isPaid=data[j].webinar.feeInfo.paid;
        obj.organizer.name=data[j].webinar.organizer.contactInfo.name;
        obj.organizer.email=data[j].webinar.organizer.contactInfo.email;
        obj.organizer.profileUrl=data[j].webinar.organizer.contactInfo.profileUrl;
        obj.organizer.about=data[j].webinar.organizer.contactInfo.about;
        obj.organizer.createdBy="admin";
        obj.organizer.logo=data[j].webinar.organizer.contactInfo.logo;

        obj.schedule.durationInMinutes=data[j].webinar.schedule.durationInMinutes.toString();
        obj.schedule.startDate=data[j].webinar.schedule.startDate;
        obj.schedule.endDate=data[j].webinar.schedule.endDate;
        obj.schedule.startTime=data[j].webinar.schedule.startTime;
        obj.schedule.endTime=data[j].webinar.schedule.endTime;
        
        
//console.log(data[0].webinar.speakers.length);
        let list=new Array();
        for(let i=0;i<data[j].webinar.speakers.length;i++){
          
    let y= {
      "about": "string",
      "createdBy": "string",
      "creationDate": "2020-10-31T15:57:48.090Z",
      "designation": "string",
      "email": "string",
      "logo":"",
      "mobile": "string",
      "name": "string",
      "profileUrl": "string",
      "updatedBy": "string",
      "updationDate": "2020-10-31T15:57:48.090Z"
    };

          y.name=data[j].webinar.speakers[i].contactInfo.name;
          y.designation=data[j].webinar.speakers[i].contactInfo.designation;
          y.email=data[j].webinar.speakers[i].contactInfo.email;
          y.about=data[j].webinar.speakers[i].contactInfo.about;
          y.mobile=data[j].webinar.speakers[i].contactInfo.mobileNo;
          y.profileUrl=data[j].webinar.speakers[i].contactInfo.profileUrl;
           y.logo=data[j].webinar.speakers[i].contactInfo.logo;
           //console.log(JSON.stringify(y));
           list.push(y);
        }
        obj.speaker=list;
        obj.title=data[j].webinar.webinarInfo.title;
        obj.description=data[j].webinar.webinarInfo.description;
        obj.id=data[j].webinarId;
        obj.posterUrl=data[j].webinar.webinarInfo.posterUrl;
        obj.userId=data[j].webinar.hostUserId;

       // obj.creationDate=data[j].webinar.creationDate.toUTCString();
       let list1=new Array();
       if(data[j].webinar.tags){
        for(let g=0;g<data[j].webinar.tags.length;g++){
          let f=  {
           "createdBy": "string",
           "creationDate": "2020-10-31T15:57:48.090Z",
           
           "name": "string",
           "updatedBy": "string",
           "updationDate": "2020-10-31T15:57:48.090Z"
         };
 
         f.name=data[j].webinar.tags[g].name;
         list1.push(f);
        }
       }
      
       obj.tag=list1;
       // console.log(obj.speaker)
        objList.push(obj);
        
        
      }

     // console.log(JSON.stringify(data));
      console.log(JSON.stringify(objList));
    })

    */
    //console.log(JSON.stringify(objList));
    return this.webinarsCollection
      .doc<Webinar>(id)
      .valueChanges()
      .pipe(take(1));
  }

  getWebinarUserMappingByWebinarId(
    id: string
  ): Observable<WebinarsUsersMapping[]> {
    //return this.http.get<Webinar>(awsApi.getWebinar+"/"+id);
    return this.firestore
      .collection<WebinarsUsersMapping>('webinars-users-mapping', (ref) =>
        ref.where('webinarId', '==', id)
      )
      .valueChanges()
      .pipe(take(1));
  }

  saveRegistration(_webinar: Webinar, _user: User) {
    const doc = this.firestore.collection('webinars-users-mapping', (ref) =>
      ref.where('webinarId', '==', _webinar.id)
    );
    doc
      .snapshotChanges()
      .pipe(take(1))
      .subscribe((res: any) => {
        //console.log(res);
        const id = res[0].payload.doc.id;
        const mapping = res[0].payload.doc.data() as WebinarsUsersMapping;
        let count = 0;
        mapping.userId.forEach((i) => {
          if (i.email == _user.email) {
            count = 1;
          }
        });

        if (count == 0) {
          _user.creationDate = new Date();
          mapping.userId.push(_user);
          // console.log(mapping);
          mapping.totalHits = mapping.totalHits + 1;
          const creationDate = new Date();
          this.firestore
            .collection('webinars-users-mapping')
            .doc(id)
            .update({ userId: mapping.userId });
          this.firestore
            .collection('webinars-users-mapping')
            .doc(id)
            .update({ totalHits: mapping.totalHits });

          // this.sendEmail(_webinar,"registration");
        } else {
          //console.log("user already registered");
        }
      });
  }

  getAllWebinarsUsersMapping() {
    return this.firestore
      .collection('webinars-users-mapping', (ref) =>
        ref.orderBy('webinar.schedule.startDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as WebinarsUsersMapping;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      );
  }

  saveViews(_webinar: Webinar, _user: User) {
    const doc = this.firestore.collection('webinars-users-mapping', (ref) =>
      ref.where('webinarId', '==', _webinar.id)
    );
    doc
      .snapshotChanges()
      .pipe(take(1))
      .subscribe((res: any) => {
        //console.log(res);
        const id = res[0].payload.doc.id;
        const mapping = res[0].payload.doc.data() as WebinarsUsersMapping;

        //console.log(_webinar);
        if (mapping.webinar.recordingInfo.views) {
          _webinar.recordingInfo.views =
            mapping.webinar.recordingInfo.views + 1;
        } else {
          _webinar.recordingInfo.views = 1;
        }

        this.firestore
          .collection('webinars-users-mapping')
          .doc(id)
          .update({ webinar: _webinar });
      });
  }

  getFeaturedWebinars(): Observable<Webinar[]> {
    const currentDate = new Date();
    const formattedCurrentDate = this.getformattedDate();

    return this.firestore
      .collection('webinars', (ref) =>
        ref.where('schedule.startDate', '>=', formattedCurrentDate)
      )
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Webinar;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      )
      .pipe(
        map((txs) =>
          txs.filter((txn) => {
            const d = Date.parse(
              `${txn.schedule.startDate} ${txn.schedule.startTime}`
            );
            return txn.featured == true && dayjs(d).isAfter(currentDate);
          })
        )
      );
    /*
    return this.webinars.pipe(
      map(txs => txs.filter(txn => txn.featured==true && moment(txn.schedule.startDate).isAfter(formattedCurrentDate)))
  );
  */
  }

  getUpcomingWebinars(): Observable<Webinar[]> {
    const thisWeekDate = new Date();
    thisWeekDate.setDate(thisWeekDate.getDate() + 10);
    const formattedThisWeekDate = dayjs(thisWeekDate).format('YYYY-MM-DD');
    const formattedCurrentDate = this.getformattedDate();

    return this.firestore
      .collection('webinars', (ref) =>
        ref.where('schedule.startDate', '>=', formattedCurrentDate)
      )
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Webinar;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      )
      .pipe(
        map((txs) =>
          txs.filter((txn) => {
            const d = Date.parse(
              `${txn.schedule.startDate} ${txn.schedule.startTime}`
            );
            return (
              dayjs(txn.schedule.startDate).isBefore(formattedThisWeekDate) &&
              dayjs(txn.schedule.startDate).isAfter(formattedCurrentDate)
            );
          })
        )
      );
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  deleteWebinar(webinar: WebinarsUsersMapping) {
    this.firestore.collection('deleted-webinars').add(webinar);
    this.firestore
      .collection('webinars-users-mapping')
      .doc(`${webinar.id}`)
      .delete();
    return this.webinarsCollection.doc(`${webinar.webinarId}`).delete();
  }

  getRelatedWebinars() {
    // this.firestore.collection('webinars',ref=>ref.where('category','==',''))
  }

  addRecordingLink(webinar: Webinar, webinar_id, recordingLink) {
    //console.log(webinar,webinar_id,recordingLink);
    const obj = {
      recordingLink: recordingLink,
      recorded: true,
      views: 0,
      duration: webinar.schedule.durationInMinutes
    };
    const rec: RecordingInfo = obj;
    rec.recorded = true;
    rec.recordingLink = recordingLink;

    webinar.recordingInfo = rec;
    this.webinarsCollection.doc(webinar_id).update({ recordingInfo: obj });

    const doc = this.firestore.collection('webinars-users-mapping', (ref) =>
      ref.where('webinarId', '==', webinar_id)
    );
    doc
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as WebinarsUsersMapping;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((_doc: any) => {
        const id = _doc[0].id; //first result of query [0]
        this.firestore
          .doc(`webinars-users-mapping/${id}`)
          .update({ webinar: webinar });
      });

    //this.firestore.collection("webinars-users-mapping").doc(webinar.id).update({webinar: webinar});
  }

  updateWebinar(webinar: Webinar, id: string) {
    this.firestore.collection('webinars').doc(id).update(webinar);
    const doc = this.firestore.collection('webinars-users-mapping', (ref) =>
      ref.where('webinarId', '==', id)
    );
    doc
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as WebinarsUsersMapping;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((_doc: any) => {
        const id = _doc[0].id; //first result of query [0]
        this.firestore
          .doc(`webinars-users-mapping/${id}`)
          .update({ webinar: webinar });
      });
  }

  updateOrganizer(webinar: Webinar) {
    //console.log(webinar.organizer.contactInfo.name);

    const doc = this.firestore.collection('organizer', (ref) =>
      ref.where('name', '==', webinar.organizer.contactInfo.name)
    );
    doc
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Organizer;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((_doc: any) => {
        const id = _doc[0].id; //first result of query [0]
        this.firestore
          .doc(`organizer/${id}`)
          .update(webinar.organizer.contactInfo);
      });
  }

  updateSpeaker(webinar: Webinar) {
    //console.log(webinar.speakers);

    for (let i = 0; i < webinar.speakers.length; i++) {
      const doc = this.firestore.collection('speaker', (ref) =>
        ref.where('name', '==', webinar.speakers[i].contactInfo.name)
      );
      doc
        .snapshotChanges()
        .pipe(take(1))
        .pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Speaker;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        )
        .subscribe((_doc: any) => {
          const id = _doc[0].id; //first result of query [0]
          this.firestore
            .doc(`speaker/${id}`)
            .update(webinar.speakers[i].contactInfo);
        });
    }
  }
}
