import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from './../../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: User = {};
  isDisabled = true;
  _user: any;
  constructor(
    private auth: AuthService,
    private ngxService: NgxUiLoaderService,
    private afs: AngularFirestore
  ) {}

  async ngOnInit(): Promise<void> {
    this.ngxService.start();
    this._user = await this.auth.isLoggedIn();
    this.afs
      .collection('users', (ref) => ref.where('uid', '==', this._user.uid))
      .valueChanges()
      .pipe(take(1))
      .subscribe((data) => {
        this.user = data[0];
        this.ngxService.stop();
      });
  }

  update() {
    this.isDisabled = false;
  }

  save() {
    const data = {
      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      phoneNumber: this.user.phoneNumber,
      dob: this.user.dob,
      sex: this.user.sex,
      country: this.user.country,
      state: this.user.state,
      city: this.user.city
    };

    this.afs.collection('users').doc(this.user.uid).update(this.user);

    this.isDisabled = true;
  }

  cancel() {
    this.isDisabled = true;
  }
}
