
<div class="container-fluid">
        <div class="row">
        
                <div class="col-lg-4"></div>
    
                <div *ngIf="authService.user$ | async; then authenticated else guest">
                                     
                </div>
    
                <ng-template #guest>
    
                        <div *ngIf="registerFlag" class="col-lg-4">
                                <ngx-auth-firebaseui-register
                                (onLoginRequested)="toggleLogin('register')"
                                (onSuccess)="registerSuccess()"
                                >
                    
                                </ngx-auth-firebaseui-register>
                        </div>
                        <div *ngIf="loginFlag"  class="col-lg-4">
                                
                                <ngx-auth-firebaseui-login
                                (onCreateAccountRequested)="toggleLogin('login')"
                                [providers]="['google']"
                                (onSuccess)="loginSuccess()"
                               
                                >
                    
                                </ngx-auth-firebaseui-login>
                        </div>
                </ng-template>
    
                <ng-template #authenticated>
                    <div *ngIf="authService.user$ | async as user">
                        <p>
                            Hi, {{user.displayName}}
                            <br> 
                            You can now continue to Register for the event.
                        </p>
                    </div>
                </ng-template>
                
                
                <div class="col-lg-4"></div>
        </div>
    </div>