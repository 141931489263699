


<div style=" margin-top: 10px">
    

<mat-form-field appearance="outline" style="width: 30%;margin-left: 5px">
        <mat-label>Name</mat-label>
        <input   matInput placeholder="Name" [(ngModel)]="name">
</mat-form-field>
<mat-form-field appearance="outline" style="width: 50%; margin-left: 5px">
        <mat-label>Image</mat-label>
        <input   matInput placeholder="image" [(ngModel)]="image">
</mat-form-field>
<button style="margin-left: 10px;background-color: green" class="btn btn-primary"  (click)="addCategory()">Add</button>
</div>

<table   class="table table-hover .table-responsive table-custom">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Image</th>
        <th  scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let category of categories;let i=index;">
        <th scope="row">{{i+1}}</th>
        <td> 

           <input class="form-control" [(ngModel)]="category.name">
        </td>
        <td>
                <input class="form-control" [(ngModel)]="category.img" >
            
        </td>
            <td>
              <button  (click)="updateCategory(category)"  style="background-color: yellow" mat-raised-button>Update</button>
              <button  (click)="deleteCategory(category.id)"  style="background-color: red;margin-left: 5px" mat-raised-button>Delete</button>
             
            
             
    
    </td>
        </tr>
    
    </tbody>
  </table>