import { Webinar } from './../interfaces/webinars.model';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { Industry } from './../interfaces/Industry.model';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { awsApi } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  industries: Observable<Industry[]>;
  constructor(private firestore: AngularFirestore, private http: HttpClient) {
    this.industries = firestore
      .collection('industries')
      .snapshotChanges()
      .pipe(take(1))
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Industry;

            return data;
          });
        })
      );

    // this.industries= this.http.get<Industry[]>(awsApi.getAllIndustries);
  }

  getIndustries(): Observable<Industry[]> {
    //console.log(this.industries);
    return this.industries;
  }

  getWebinarsFromIndustry(industryName): Observable<Webinar[]> {
    //console.log(industryName);

    const s = "{[{'name':'" + industryName + "'}]}";
    const ss = {
      industry: [
        {
          name: industryName
        }
      ]
    };
    //console.log(JSON.stringify(ss));
    return this.http.post<Webinar[]>(awsApi.getAllWebinars, ss);
  }
}
