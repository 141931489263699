<div style="margin-top: 10px">
<table class="table table-hover .table-responsive table-custom">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Title</th>
            <th scope="col">Schedule</th>
            <th scope="col">Oragnizer</th>
         <th scope="col">Attendees</th>
            <th  scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let y of webinarUsersMappingList;let i=index;">
            <th scope="row">{{i+1}}</th>
            <td>{{y.webinar.webinarInfo.title}}</td>
            <td> <span
                *ngIf="y.webinar.schedule.startDate==y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
        <span *ngIf="y.webinar.schedule.startDate!=y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}
                -
                {{y.webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
            <br>
            {{y.webinar.schedule.startTime}} - {{y.webinar.schedule.endTime}}
            </td>
            <td> {{y.webinar.organizer.contactInfo.name}}</td>
       
         
                <td>
                        <div *ngFor="let u of y.userId">
                                {{u.email}}

                              </div></td>
       
         
                        <td>

                        <button mat-button  [matMenuTriggerFor]="actions" mat-icon-button><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #actions="matMenu" yPosition="below">
                                <button  mat-menu-item (click)="routeToSummary(y)"  ><mat-icon>visibility</mat-icon>open</button>
                                                         
                                <button  mat-menu-item (click)="delete(y)" ><mat-icon>delete</mat-icon>delete</button>
                        </mat-menu>


                       
                 
                
                 
        
        </td>
            </tr>
        
        </tbody>
      </table>
    </div>