import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-profile-success',
  templateUrl: './create-profile-success.component.html',
  styleUrls: ['./create-profile-success.component.css']
})
export class CreateProfileSuccessComponent implements OnInit {
  user: any;
  constructor(
    private dialogRef: MatDialogRef<CreateProfileSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private route: Router
  ) {
    this.user = data;
  }

  ngOnInit(): void {}

  openRoute(target) {
    this.route.navigate([target]);
    this.dialogRef.close();
  }
}
