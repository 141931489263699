import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { Industry } from './../../interfaces/Industry.model';
import { Category } from './../../interfaces/Category.model';
import { CategoryService } from './../../service/category.service';
import { IndustryService } from 'src/app/service/industry.service';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  categories: Array<Category>;
  industries: Array<Industry>;
  constructor(
    private categoryService: CategoryService,
    private industryService: IndustryService,
    private communicationServiceService: CommunicationServiceService,
    private route: Router,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.ngxService.startBackgroundLoader('loader-3');
    this.categoryService
      .getCategories()
      .pipe(take(1))
      .subscribe((data) => {
        //console.log(data);
        this.ngxService.stopBackgroundLoader('loader-3');
        this.categories = data;
      });

    this.industryService
      .getIndustries()
      .pipe(take(1))
      .subscribe((data) => {
        // console.log(data);
        this.industries = data;
      });
    this.communicationServiceService.searchText = null;
    this.communicationServiceService.categoryName = null;
    this.communicationServiceService.industryName = null;
  }

  selectCategory(category: Category) {
    //console.log(category);
    this.route.navigate(['/attend']);
    this.communicationServiceService.categoryName = category.name;
  }

  selectIndustry(industry: Industry) {
    //console.log(industry);
    this.route.navigate(['/attend']);
    this.communicationServiceService.industryName = industry.name;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    margin: 16,
    autoplay: true,
    autoplaySpeed: 400,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 6
      }
    }
  };
}
