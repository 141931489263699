import { CommunicationServiceService } from './service/communication-service.service';
import { FeedbackComponent } from './dialogs/feedback/feedback.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from './interfaces/user.model';
import { Observable } from 'rxjs';
import { AuthService } from './service/auth.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LinkMenuItem } from 'ngx-auth-firebaseui';
import { Router, NavigationEnd } from '@angular/router';

import * as firebase from 'firebase/app';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatSidenav } from '@angular/material/sidenav';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: Observable<firebase.User>;
  searchText: string;
  _user: any = {};
  location = '';

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  constructor(
    public auth: AuthService,
    public route: Router,
    public afAuth: AngularFireAuth,
    private dialog: MatDialog,
    private commService: CommunicationServiceService,
    private toast: ToastrService
  ) {
    this.user = afAuth.authState;

    const navEndEvents = route.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      this.location = event.url;
      /*
  gtag('config', 'UA-173012421-1',{
    'page_path':event.urlAfterRedirects,
    'user_name':this._user
  });
  */
    });
  }
  async ngOnInit(): Promise<any> {
    this._user = await this.auth.isLoggedIn();
    this.links = [
      {
        icon: 'home',
        text: 'My Dashboard',
        callback: () => {
          this.sidenav.toggle();
          this.route.navigate(['user/dashboard/host']);
        }
      }
    ];

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }

  title = 'webiknowr';

  links: LinkMenuItem[];

  signOut() {
    localStorage.removeItem('user');
    console.log('log out');
    this.toast.success('successfully logged out!');

    this.route.navigate(['home']);
  }

  logOut() {
    this.auth.signOut();
    this.toast.success('successfully logged out!');
    this.route.navigate(['home']);
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.dialog.open(FeedbackComponent, dialogConfig);
  }

  search() {
    this.route.navigate(['/attend']);
    this.commService.searchText = this.searchText;
  }
}
