import { ProfileComponent } from './user/dashboard/profile/profile.component';
import { AttendeeComponent } from './user/dashboard/attendee/attendee.component';

import { DashboardHostOrganizerComponent } from './user/dashboard/host/organizer/organizer.component';
import { DashboardHomeComponent } from './user/dashboard/home/home.component';
import { TimelineComponent } from './user/dashboard/host/timeline/timeline.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { CategoryComponent } from './webinar/category/category.component';

import { WebinarDetailsComponent } from './webinar/webinar-details/webinar-details.component';

import { AllEventsComponent } from './events/all-events/all-events.component';

import { HomeComponent } from './home/home/home.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';

import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
import { IndustryComponent } from './webinar/industry/industry.component';
import { AdminComponent } from './admin/admin.component';
import { SummaryComponent } from './user/dashboard/host/summary/summary.component';
import { DashboardHostSpeakersComponent } from './user/dashboard/host/speakers/speakers.component';
import { DashboardHostScheduleComponent } from './user/dashboard/host/dashboard-host-schedule/dashboard-host-schedule.component';
import { DashboardHostRegistrantsComponent } from './user/dashboard/host/dashboard-host-registrants/dashboard-host-registrants.component';
import { UserprofileComponent } from './userprofile/userprofile.component';

const redirectUnauthorizedToHostGuest = () =>
  redirectUnauthorizedTo(['host/guest']);
const redirectLoggedInToAttend = () => redirectLoggedInTo(['attend']);
const redirectLoggedInToHost = () => redirectLoggedInTo(['host']);

const redirectUnauthorizedUserProfile = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInUserProfile = () =>
  redirectLoggedInTo(['user/dashboard/home']);

const redirectAuthorizedAdmin = () =>
  redirectLoggedInTo(['admin/dashboard/home']);

const routes: Routes = [
  {
    path: 'attend',
    component: AllEventsComponent
  },
  {
    path: 'home/attend',
    component: AllEventsComponent
  },
  {
    path: 'home',

    component: HomeComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'webinar/:id',
    component: WebinarDetailsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'category/:name',
    component: CategoryComponent
  },
  {
    path: 'industry/:name',
    component: IndustryComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedUserProfile }
  },

  {
    path: 'profile/:id',
    component: UserprofileComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
    /*
    let user=JSON.parse(localStorage.getItem('user'));
    hostUserId=user.uid;
    */
  }
}
