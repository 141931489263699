import { NgxImageCompressService } from 'ngx-image-compress';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Webinar } from 'src/app/interfaces/webinars.model';

import { CommunicationServiceService } from './../../../../service/communication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../../events/all-events/all-event.service';
import { AuthService } from './../../../../service/auth.service';
import { Component, OnInit } from '@angular/core';

import * as dayjs from 'dayjs';
import { SummaryEditComponent } from './summary-edit/summary-edit.component';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent {
  id = '';
  webinar: Webinar;
  copyFlag = false;
  expiredFlag = false;
  recordingLink = '';
  recInputFlag = false;
  errorMessage: string;

  file: any;
  localUrl: any;
  localCompressedURl: any;
  posterCompressedURL: any;
  organizerCompressedURL: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private dialog: MatDialog,
    private imageCompress: NgxImageCompressService,
    private toast: ToastrService
  ) {
    this.id = localStorage.getItem('webinar-id');
    //this.commService.webinarUserMapping=undefined;

    this.allEventService
      .getWebinarById(this.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.webinar = data;
        this.recordingLink = this.webinar.recordingInfo.recordingLink;

        const dt1 = Date.parse(
          `${this.webinar.schedule.endDate} ${this.webinar.schedule.endTime}`
        );
        const currentDate = new Date();
        const scheduleDate = new Date(dt1);

        if (dayjs(currentDate).isAfter(scheduleDate)) {
          this.expiredFlag = true;
        }
        //console.log(this.webinar);
      });
  }

  copyLink() {
    this.copyFlag = true;
  }

  addRecordingLink() {
    if (!this.recordingLink.toLowerCase().includes('youtube.com/embed')) {
      this.errorMessage =
        'not a valid youtube embedded link. Please enter the valid link.';
      return;
    }
    //console.log(this.recordingLink);
    try {
      this.allEventService.addRecordingLink(
        this.webinar,
        this.id,
        this.recordingLink
      );
      this.errorMessage = null;
      this.expiredFlag = true;
      this.recInputFlag = true;
    } catch (error) {
      console.log(error);
    }
  }

  async openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '700px';
    dialogConfig.data = this.webinar;

    dialogConfig.disableClose = true;

    this.dialog.open(SummaryEditComponent, dialogConfig);
  }

  selectFile(event: any, type) {
    this.file = event.target.files[0];
    const fileName = this.file['name'];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFile(this.localUrl, fileName, type);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image, fileName, type) {
    const orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    //console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(image, orientation, 100, 60)
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        //console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        // create file from byte
        //console.log(this.localCompressedURl);
        //console.log(this.myForm.value);
        this.webinar.webinarInfo.posterUrl = this.localCompressedURl;
        try {
          this.allEventService.updateWebinar(this.webinar, this.id);
          this.toast.success('Poster updated Successfully!');
        } catch (error) {
          this.toast.error('Poster could not be saved. Please try again.');
          console.log(error);
        }

        return this.localCompressedURl;
      });
  }
}
