import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WebinarDetailsComponent } from './../webinar/webinar-details/webinar-details.component';
import { CategoryComponent } from './category/category.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { WebinarCardComponent } from './webinar-card/webinar-card.component';
import { PipeModule } from './../common/pipe/pipe.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularmaterialModule } from './../common/angularmaterial/angularmaterial.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const routes: Routes = [];

@NgModule({
  declarations: [
    WebinarCardComponent,
    CategoryComponent,
    WebinarDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularmaterialModule,
    RouterModule.forRoot(routes),
    PipeModule,
    LazyLoadImageModule,
    CarouselModule,
    FlexLayoutModule
  ],
  providers: [],
  exports: [WebinarCardComponent, WebinarDetailsComponent, CategoryComponent]
})
export class WebinarsModule {}
