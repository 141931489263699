
<mat-sidenav-container   class="example-container">
  
    <mat-sidenav style="width: 200px" #snav mode="side"  opened  [fixedInViewport]="true" [fixedTopGap]="75" >
    
        
            <mat-nav-list>
 <!--              <a mat-list-item *ngIf="!location.includes('/summary')"  (click)="goToDashboardHome()" #dashboard ><mat-icon>dashboard</mat-icon>Dashboard</a>--> 
 <button style="margin-left: 22px;
 margin-bottom: 28px;
 margin-top: 9px;" mat-raised-button  routerLink="/home"  ><mat-icon>keyboard_backspace</mat-icon>Back to Home</button>
 
                    <a mat-list-item routerLinkActive="active"  routerLink="home" >Overview</a>
                    <a mat-list-item routerLinkActive="active"  routerLink="category" >category</a>
                    <a mat-list-item routerLinkActive="active"  routerLink="webinars" >Webinar</a>
                    <a mat-list-item routerLinkActive="active"  routerLink="users" >Users</a>
                    <a mat-list-item routerLinkActive="active"  routerLink="speakers" >Speakers</a>
                    <a mat-list-item routerLinkActive="active"  routerLink="organizers" >Organizers</a>
                    
                   
             <!--      <a mat-list-item   *ngIf="location.includes('/summary')"><mat-icon>notifications</mat-icon>Notifications</a>--> 
            
            </mat-nav-list>
    
    </mat-sidenav>
    <mat-sidenav-content > 
      <!--
     <app-about-me></app-about-me>
     <app-popular-speaker></app-popular-speaker>
     <app-upcoming-events></app-upcoming-events>
      <app-footer></app-footer>
     -->
     <button class="menu-icon" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
 
     
    
     <router-outlet  ></router-outlet> 
  
    </mat-sidenav-content>
  </mat-sidenav-container>
