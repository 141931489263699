import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommunicationServiceService } from './../../../../service/communication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../../events/all-events/all-event.service';
import { AuthService } from './../../../../service/auth.service';
import { Component, OnInit } from '@angular/core';

import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';
import { Webinar } from 'src/app/interfaces/webinars.model';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.css']
})
export class DashboardHostOrganizerComponent implements OnInit {
  webinarMapping: WebinarsUsersMapping;
  id: string;
  webinar: Webinar;
  name: string;
  designation: string;
  email: string;
  profileUrl: string;
  mobile: string;
  logo: string;
  about: string;
  isDisabled = true;

  public myForm: FormGroup;
  $webinar: Observable<any>;

  file: any;
  localUrl: any;
  localCompressedURl: any;
  posterCompressedURL: any;
  organizerCompressedURL: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private fb: FormBuilder,
    private toast: ToastrService,
    private imageCompress: NgxImageCompressService
  ) {}

  async ngOnInit(): Promise<void> {
    this.id = localStorage.getItem('webinar-id');
    //this.commService.webinarUserMapping=undefined;

    this.$webinar = await this.allEventService
      .getWebinarById(this.id)
      .pipe(take(1));
    this.$webinar.subscribe((data) => {
      this.webinar = data;

      this.name = this.webinar.organizer.contactInfo.name;

      this.email = this.webinar.organizer.contactInfo.email;
      this.mobile = this.webinar.organizer.contactInfo.mobileNo;
      this.about = this.webinar.organizer.contactInfo.about;
      this.logo = this.webinar.organizer.contactInfo.logo;
      this.profileUrl = this.webinar.organizer.contactInfo.profileUrl;

      /*
    this.myForm=this.fb.group({

      contactInfo:this.fb.group({
        name: [this.webinar.organizer.contactInfo.name, [Validators.required]],
        profileUrl: [this.webinar.organizer.contactInfo.profileUrl],
        email: [this.webinar.organizer.contactInfo.email, [Validators.required, Validators.email] ],
        mobileNo: [this.webinar.organizer.contactInfo.mobileNo,[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
        about: [this.webinar.organizer.contactInfo.about],
        logo:[this.webinar.organizer.contactInfo.logo]
      })
    
    })
 */
    });
  }

  update() {
    this.isDisabled = false;
  }

  save() {
    this.webinar.organizer.contactInfo.name = this.name;
    this.webinar.organizer.contactInfo.about = this.about;
    this.webinar.organizer.contactInfo.email = this.email;
    this.webinar.organizer.contactInfo.mobileNo = this.mobile;
    this.webinar.organizer.contactInfo.profileUrl = this.profileUrl;
    this.webinar.organizer.contactInfo.logo = this.logo;

    try {
      this.allEventService.updateWebinar(this.webinar, this.id);
      this.toast.success('Organizer details updated successfully!');
    } catch (error) {
      this.toast.error('There is some error. Please try again.');
    }

    try {
      this.allEventService.updateOrganizer(this.webinar);
    } catch (error) {}
    this.isDisabled = true;
  }

  cancel() {
    this.isDisabled = true;
  }

  selectFile(event: any, type) {
    this.file = event.target.files[0];
    const fileName = this.file['name'];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFile(this.localUrl, fileName, type);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image, fileName, type) {
    const orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    //console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(image, orientation, 100, 60)
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        //console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        // create file from byte
        //console.log(this.localCompressedURl);
        //console.log(this.myForm.value);
        this.logo = this.localCompressedURl;

        return this.localCompressedURl;
      });
  }
}
