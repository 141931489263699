import { AllEventService } from './../../events/all-events/all-event.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { Webinar } from './../../interfaces/webinars.model';

import { Component, OnInit } from '@angular/core';
import { ShareComponent } from 'src/app/dialogs/share/share.component';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare let $: any;
declare let require: any;
@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit {
  webinars: Array<Webinar>;
  webinars1: Webinar[] = [];
  subscription1$: Subscription;
  constructor(
    private allEventService: AllEventService,
    private dialog: MatDialog,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.ngxService.startBackgroundLoader('loader-02');
    this.subscription1$ = this.allEventService
      .getUpcomingWebinars()
      .pipe(map((x) => x.slice(0, 4)))
      .subscribe((data) => {
        ///console.log(data);
        this.ngxService.stopBackgroundLoader('loader-02');
        const sorted = data.sort((a, b) => {
          const dt1 = Date.parse(
            `${a.schedule.startDate} ${a.schedule.startTime}`
          );
          const dt2 = Date.parse(
            `${b.schedule.startDate} ${b.schedule.startTime}`
          );

          return dt2 - dt1;
        });
        // console.log(sorted)
        const arr = sorted.reverse();
        // console.log(arr)

        this.webinars = arr;
      });
  }

  events = [
    {
      title: 'Webinar on Artificial Integlligence',
      img: 'assets/ai.jpg',
      time: '25 July 10:00 AM'
    },
    {
      title: 'Webinar on challenges in a startup',
      img: 'assets/ai.jpg',
      time: '26 July 10:00 AM'
    },
    {
      title: 'Webinar on social networking',
      img: 'assets/ai.jpg',
      time: '28 July 10:00 AM'
    },
    {
      title: 'Webinar on Astrophysics',
      img: 'assets/ai.jpg',
      time: '01 August 10:00 AM'
    },
    {
      title: 'Webinar on food science',
      img: 'assets/ai.jpg',
      time: '05 August 10:00 AM'
    },
    {
      title: 'Webinar on food science',
      img: 'assets/ai.jpg',
      time: '05 August 10:00 AM'
    }
  ];

  openDialog(selectedWebinar: Webinar) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;

    dialogConfig.width = '500px';
    //console.log(selectedWebinar);
    dialogConfig.data = selectedWebinar;

    this.dialog.open(ShareComponent, dialogConfig);
  }

  favoriteSelected = false;
  toggleFavorite() {
    this.favoriteSelected = !this.favoriteSelected;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    margin: 16,
    autoplay: true,
    autoplaySpeed: 200,
    autoplayHoverPause: true,

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };
  ngOnDestroy(): void {
    this.subscription1$.unsubscribe();
  }
}
