import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../../events/all-events/all-event.service';
import { CommunicationServiceService } from './../../../../service/communication-service.service';
import { AuthService } from './../../../../service/auth.service';
import { Webinar } from './../../../../interfaces/webinars.model';
import { Component, OnInit, Input } from '@angular/core';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';
import * as dayjs from 'dayjs';

import { take } from 'rxjs/operators';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  upcomingWebinars: WebinarsUsersMapping[];
  previousWebinars: WebinarsUsersMapping[];
  originalWebinars: WebinarsUsersMapping[];
  upcomingFlag = false;
  previousFlag = false;
  totalWebinars = 0;
  totalRegistered = 0;
  webinars: Array<Webinar> = [];
  webinarsUsersMapping: Array<WebinarsUsersMapping> = [];
  originalWebinarList: Webinar[];
  hostFlag = false;
  _user: any;
  displayName = 'user';
  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private ngxService: NgxUiLoaderService
  ) {}

  async ngOnInit(): Promise<void> {
    //console.log(this.webinars);

    const user = await this.authService.isLoggedIn();

    this._user = user;
    this.displayName = user.displayName;

    //console.log(user.uid);
    this.ngxService.start();
    const x = await this.allEventService.getWebinarsMappingOfHost(user.uid);

    if (x) {
      x.subscribe((data) => {
        this.webinarsUsersMapping = data;
        if (data.length > 0) {
          this.hostFlag = true;
        }
        this.originalWebinars = this.webinarsUsersMapping;
        this.totalWebinars = this.webinarsUsersMapping.length;
        this.upcomingWebinars = this.getUpcomingWebinars();
        this.previousWebinars = this.getPreviousWebinars();
        this.ngxService.stop();
      });
    }

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }

  getPreviousWebinars(): WebinarsUsersMapping[] {
    const currentDate = new Date();
    const formattedCurrentDate = dayjs(currentDate).format('YYYY-MM-DD');
    this.originalWebinars = this.webinarsUsersMapping;
    return this.originalWebinars.filter((txn) => {
      const dt1 = Date.parse(
        `${txn.webinar.schedule.endDate} ${txn.webinar.schedule.endTime}`
      );
      const newDate = new Date(dt1);
      return dayjs(newDate).isBefore(currentDate);
    });
  }

  getUpcomingWebinars() {
    const thisWeekDate = new Date();
    thisWeekDate.setDate(thisWeekDate.getDate() + 10);
    const formattedThisWeekDate = dayjs(thisWeekDate).format('YYYY-MM-DD');

    const currentDate = new Date();
    const formattedCurrentDate = dayjs(currentDate).format('YYYY-MM-DD');
    this.originalWebinars = this.webinarsUsersMapping;

    return this.originalWebinars.filter((txn) => {
      const dt1 = Date.parse(
        `${txn.webinar.schedule.endDate} ${txn.webinar.schedule.endTime}`
      );
      const newDate = new Date(dt1);
      return dayjs(newDate).isAfter(currentDate);
    });
  }

  routeToSummary(webinar) {
    this.commService.webinarUserMapping = webinar;
    localStorage.setItem('webinar-id', webinar.webinarId);
    this.router.navigate(['user/dashboard/host/summary']);
  }

  delete(webinar) {
    if (confirm('Do you really want to delete this webinar?')) {
      // console.log('delete');
      this.allEventService.deleteWebinar(webinar);
    }
  }
}
