import { AuthService } from './../../service/auth.service';
import { Webinar } from 'src/app/interfaces/webinars.model';
import { AuthProvider } from 'ngx-auth-firebaseui';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit {
  webinar: Webinar;
  errorMessage: string;
  successMessage: string;

  loginFlag = true;
  registerFlag = false;
  providers = AuthProvider;
  constructor(
    private dialogRef: MatDialogRef<LoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private route: Router,
    public authService: AuthService
  ) {
    this.webinar = data;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  toggleLogin(value) {
    if (value == 'login') {
      this.loginFlag = false;
      this.registerFlag = true;
    } else {
      this.loginFlag = true;
      this.registerFlag = false;
    }
  }

  loginSuccess() {
    this.route.navigate(['webinar', this.webinar.id]);
  }

  registerSuccess() {
    this.route.navigate(['webinar', this.webinar.id]);
  }
}
