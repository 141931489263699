

<div class="container-fluid">
    <!--
    <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
                <mat-card>

                    <mat-card-content>

                            <div *ngIf="authService.user$ | async; then authenticated else guest">
                                 
                            </div>
                            
                            
                            <ng-template #guest>
                                
                                
                                <h2 style="text-align: center">Sign Up</h2>

                                <form [formGroup]="loginForm" (ngSubmit)="tryRegister()" novalidate>
                                        <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Your Name</mat-label>
                                                <input required matInput placeholder="Full Name" formControlName="name">
                                                <mat-icon matSuffix>person</mat-icon>
                                               
                                                <mat-error *ngIf="loginForm.controls.name.touched && loginForm.controls.name.invalid">
                                                    <span *ngIf="loginForm.controls.name.errors.required">Name is
                                                        mandatory.</span>
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" style="width: 100%">
                                                    <mat-label>Email Address</mat-label>
                                                    <input required matInput placeholder="Email Address" formControlName="email">
                                                    <mat-icon matSuffix>email</mat-icon>
                                                   
                                                   
                                                    <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
                                                        <span *ngIf="loginForm.controls.email.errors.email">Email not valid.</span>
                                                        <span *ngIf="loginForm.controls.email.errors.required">Email is mandatory.</span>
                                                    </mat-error>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" style="width: 100%">
                                                    <mat-label>Password</mat-label>
                                                    <input type="password" required matInput placeholder="password" formControlName="password">
                                                    <mat-icon matSuffix>visibility</mat-icon>
                                                   
                                                   
                                                    <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
                                                        <span *ngIf="loginForm.controls.password.errors.required">Name is
                                                            mandatory.</span>
                                                    </mat-error>
                                            </mat-form-field>
                                        <p style="text-align: center"><button type="submit" mat-raised-button color="primary" style="width: 200px">Submit</button></p>
                                      </form>
                                
                            <mat-divider></mat-divider>
                            <br>
                            <div>
                                <button style="margin-left: 100px" mat-raised-button>
                                    <img src="https://secure.meetupstatic.com/s/img/09976452261947836380738/logo_google.svg" alt="Continue with Google" />
                                    <span class="button-label">Continue with Google</span>
                                </button>
                            </div>
                               
                               
                            </ng-template>
                            
                            
                            
                            <ng-template #authenticated>
                                <div *ngIf="authService.user$ | async as user">
                                    <h3>Howdy, {{ user.displayName }}</h3>
                                    <img  [src]="user.photoURL">
                                    <p>UID: {{ user.uid }}</p>
                                    <button (click)="authService.signOut()">Logout</button>
                                </div>
                            </ng-template>
                    </mat-card-content>
                </mat-card>
        </div>
        <div class="col-lg-4"></div>
    </div>
-->
    <div class="row">
        
            <div class="col-lg-4"></div>

            <div *ngIf="authService.user$ | async; then authenticated else guest">
                                 
            </div>

            <ng-template #guest>

                    <div *ngIf="registerFlag" class="col-lg-4">
                            <ngx-auth-firebaseui-register
                            (onLoginRequested)="toggleLogin('register')"
                            (onSuccess)="registerSuccess()"
                            >
                
                            </ngx-auth-firebaseui-register>
                    </div>
                    <div *ngIf="loginFlag"  class="col-lg-4">
                            
                            <ngx-auth-firebaseui-login
            
                        
                            (onCreateAccountRequested)="toggleLogin('login')"
                            [providers]="['google','facebook']"
                            (onSuccess)="loginSuccess()"
                           
                            >
                
                            </ngx-auth-firebaseui-login>
                    </div>
            </ng-template>

            <ng-template #authenticated>
                <div *ngIf="authService.user$ | async as user">
                    <p>
                        Hi, {{user.displayName}}
                        <br> 
                        You are already logged in!!!
                    </p>
                </div>
            </ng-template>
            
            
            <div class="col-lg-4"></div>
    </div>
</div>


