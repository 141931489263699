import { Webinar } from './../interfaces/webinars.model';
import { awsApi } from './../../environments/environment.prod';
import { map, take } from 'rxjs/operators';
import { Category } from './../interfaces/Category.model';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categories: Observable<Category[]>;
  category: string;
  webinars: Webinar[];
  constructor(private firestore: AngularFirestore, private http: HttpClient) {
    this.categories = firestore
      .collection('categories')
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as Category;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );

    //this.categories= this.http.get<Category[]>(awsApi.getAllCategories);
    // console.log(this.http.get<Webinar[]>(awsApi.getAllWebinars));
  }

  updateCategory(id, category) {
    this.firestore.collection('categories').doc(id).update(category);
  }

  deleteCategory(id) {
    this.firestore.collection('categories').doc(id).delete();
  }

  addCategory(category) {
    this.firestore.collection('categories').add(category);
  }

  getCategories(): Observable<Category[]> {
    //console.log(this.categories);
    return this.categories;
  }

  getCategoriesFromAws(): Observable<Category[]> {
    //console.log(this.categories);
    //console.log(this.http.get<Category[]>(awsApi.getAllCategories));
    this.categories = this.http.get<Category[]>(awsApi.getAllCategories);
    return this.categories;
  }

  getWebinarsFromCategory(categoryName): Observable<Webinar[]> {
    // console.log(categoryName);

    const s = "{[{'name':'" + categoryName + "'}]}";
    const ss = {
      category: [
        {
          name: categoryName
        }
      ]
    };
    // console.log(JSON.stringify(ss));
    return this.http.post<Webinar[]>(awsApi.getAllWebinars, ss);
  }
}
