<!-- 
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img src="{{webinar.img}}" class="d-block w-100 " style="width: 500px; height:500px" alt="...">
                          </div>
                          
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>
        </div>
        <div class="col-lg-3"></div>
</div>
</div>
<br>
<div class="container-fluid">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
            <mat-card class="mat-elevation-z4" >
                        
    
                    <mat-card-content>
                        <div style="font-weight: bold; font-size: 20px">{{webinar.title}}</div>
                        
                        
                        
                    
                        
                        <br>
                        
                        <mat-divider></mat-divider>
                        <br>
                        <div>{{webinar['schedule'].startDate | date: 'fullDate'}} <span *ngIf="webinar.endDate && webinar.startDate!=webinar.endDate"> - {{webinar.endDate | date: 'fullDate'}}</span></div>
                        <div >{{webinar.startTime}} - {{webinar.endTime}}</div>
                        <div *ngIf="webinar.price=='Free'">{{webinar.price}}</div>
                        <div *ngIf="webinar.price=='Paid'">{{webinar.fees | currency:'INR'}}</div>
                    <div style="text-align: right">
                       <div class="outer-div" >
                          
                            <button style="font-size: 18px"  mat-button color="primary" >Register</button> 
                            <mat-icon>thumb_up_alt</mat-icon>
                        &nbsp;&nbsp;
                        <mat-icon>share</mat-icon></div> </div>
                    </mat-card-content>
                    
                  </mat-card>
    </div>
    <div class="col-lg-2"></div>
    
</div>
<br>
<br>
<div class="container-fluid">
        
        <div class="col-lg-4">
            <div>
                <mat-card class="mat-elevation-z4" >
                            
    
        
                        <mat-card-content>
                            <h2>Speakers</h2>
                            <mat-divider></mat-divider>
                            <br>
                            
            
                            <img src="https://in.bmscdn.com/webin/test-m6/placeholders/user-default.png">
                            <span> {{webinar.speakers}}</span>
                           
                        </mat-card-content>
                        
                      </mat-card>
             </div>
             <br>
             <div>
                    <mat-card class="mat-elevation-z4" >
                                
        
            
                            <mat-card-content>
                                <h2>Organizer</h2>
                                <mat-divider></mat-divider>
                                <br>
                                
                
                                <img src="https://in.bmscdn.com/webin/test-m6/placeholders/user-default.png">
                                <span> {{webinar.organizer}}</span>
                               
                            </mat-card-content>
                            
                          </mat-card>
                 </div>
                 <br>
        </div>
        
        <div class="col-lg-8">
                <div>
                    <mat-card class="mat-elevation-z4" >
                                
        
            
                            <mat-card-content>
                                <h2>About webinar</h2>
                                <mat-divider></mat-divider>
                                <br>
                                
                
                               <p>{{webinar.description}}</p>
                            </mat-card-content>
                            
                          </mat-card>
                </div>
                
            </div>
    
    </div>
 -->



 
 
 <div *ngIf="loaded" class="app-root">
    <div id="top"></div>
    <div class="container">
        <div class="row">

          <div fxShow="false" fxShow.lt-sm="true" class="col-md-12">

              <mat-card style="font-family: 'Nunito' !important;margin-bottom: -18px;" class="example-card">
                      
                  <mat-card-content style="color: rgb(201, 199, 209);">
                      <div style="display: flex;justify-content: space-between;">

                      <span style="font-size: 14px">{{webinar.category[0].name}}</span>
                      <span style="color: hsl(145,100%,64%)"  *ngIf="!webinar.feeInfo.feeInINR && webinar.schedule.startDate">FREE</span>
                          <span style="color: hsl(145,100%,64%)" *ngIf="webinar.feeInfo.feeInINR && webinar.schedule.startDate">{{webinar.feeInfo.feeInINR | currency:'INR'}}</span>
                    </div>
                    <div style="margin-top: -5px;" >
                        <span *ngFor="let tag of webinar.tags" style="    font-size: 10px;
                        font-family: nunito;">{{tag.name}}&nbsp;&nbsp;</span>
                    </div>
                    <br>
                    <span  style="font-size: 25px;"> 
                      {{webinar.webinarInfo.title}}
                    </span>
                    <div *ngIf="webinar.schedule.startDate" style="display: flex;justify-content: space-between;margin-top: 15px;">
                        <div class="outer-div">
                                <mat-icon style="color: #792cc7;font-size: 19px;">calendar_today</mat-icon>
                        <span
                                *ngIf="webinar.schedule.startDate==webinar.schedule.endDate">{{webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
                        <span *ngIf="webinar.schedule.startDate!=webinar.schedule.endDate">{{webinar.schedule.startDate | date : "EEE dd MMM"}}
                                -
                                {{webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
                </div>
                &nbsp;&nbsp;
                <div class="outer-div">
                                <mat-icon style="color: #c94190; font-size: 20px;">alarm</mat-icon>
                        <span>{{webinar.schedule.startTime}} IST - {{webinar.schedule.endTime}} IST</span>
                </div>
                
                
        </div>
       
        <div style="margin-top: 15px; display: flex; flex-direction: row;justify-content: space-between;margin-bottom: -10px;" >
            <div style="display: flex; flex-direction: row;justify-content: flex-start">
               <img *ngIf="!webinar.organizer.contactInfo.logo" mat-card-avatar  src="../../assets/ait-image.jpg" alt="no-image">
               <img *ngIf="webinar.organizer.contactInfo.logo" mat-card-avatar  src="{{webinar.organizer.contactInfo.logo}}" alt="no-image">
              <span style="    margin-left: 5px;
              margin-top: 8px;">{{webinar.organizer.contactInfo.name}}</span> 
            </div> 
                       
                       
                               <!--
                                       <mat-chip   class="action-button-price btn btn-primary mat-elevation-z4"> 
                                                      <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                       <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                      
                                       </mat-chip>
                                       <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                       <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                               -->
                               <button mat-icon-button style="color: white" (click)="shareDialog(webinar)" ><mat-icon>share</mat-icon></button>
                               </div>
                               <div *ngIf="webinar.certificate=='true'"style="
                               margin-top: 16px;
                           " >
                                 <img src="../../../../assets/certificate.svg" style="width: 30px;
                                 height: 30px;
                                
                                 margin-right: 10px;"> <span style="font-family: 'nunito'">E-Certificate provided</span> 
                               </div>
<!--
               <p style="text-align: center; "><button style="width: 200px;margin-top: 15px" (click)="register()" *ngIf="!expiredFlag && webinar.schedule.startDate" mat-raised-button color="primary"><span style="font-size: 15px">Register</span></button></p>
               <div style="font-family: 'Nunito';
               font-size: 8px;
               text-align: center;">** your name and email id will be shared with the organizer</div>-->
                  </mat-card-content>
                 
                </mat-card>
          </div>
            <div class="col-md-8" style="margin-top: 32px;" >
               <mat-card style="padding-bottom: 0;">
                  <img *ngIf="webinar.webinarInfo.posterUrl && !showVideoFlag" mat-card-image class="img-poster" src={{webinar.webinarInfo.posterUrl}} alt="no-image" >
                  <img *ngIf="!webinar.webinarInfo.posterUrl && !showVideoFlag"  mat-card-image class="img-poster" src="../../assets/img-alt.jpg" alt="no-image" >
                <div *ngIf="showVideoFlag">

                  <iframe width="600" height="350" [src]="webinar.recordingInfo.recordingLink | safe:'resourceUrl'"  
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                  gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

               </mat-card>
                        
              
                <div class="box" style="margin-top: 15px;min-height:400px">
                    <div class="mat-content" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
                    <mat-tab-group class="tab-group" dynamicHeight [(selectedIndex)]="selectedTab">
                        <mat-tab label="About the event">
                          
                      
                            <span class="tab-content">
                              <pre>{{webinar.webinarInfo.description}}</pre>
                            </span>
                        </mat-tab>
                        <mat-tab label="Speakers and Host">
                         
                            <div class="row">
                                <div class="col-md-6" *ngFor="let speaker of webinar['speakers']" >
                                    <div class="speaker-card">
                                      
                                        <div class="card-body text-center">
                                            <img *ngIf="!speaker.contactInfo.logo" class="avatar img-circle" src="https://in.bmscdn.com/webin/test-m6/placeholders/user-default.png" alt="Bologna" width="80" height="80">
                                            <img *ngIf="speaker.contactInfo.logo" class="avatar img-circle" src="{{speaker.contactInfo.logo}}" alt="Bologna" width="80" height="80">
                                         
                                          <p style="text-align: center; color: white;margin-top: 5px">
                                            {{speaker.contactInfo.name}}
                                          </p>
                                          <p style="text-align: center;color: white">
                              
                                              {{speaker.contactInfo.designation}}
                                            </p>
                                            <p style="text-align: center;color: white">
                              
                                              {{speaker.contactInfo.about}}
                                            </p>
                                          
                                          
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </mat-tab>
                        <mat-tab label="Organizer">
                            <br>
                             
                              <div class="row">
                                  <div class="col-md-12"  >
                                      <div class="speaker-card">
                                        
                                          <div class="card-body text-center">
                                            <img *ngIf="!webinar.organizer.contactInfo.logo" class="avatar img-circle" src="https://in.bmscdn.com/webin/test-m6/placeholders/user-default.png" alt="Bologna" width="80" height="80">
                                            <img *ngIf="webinar.organizer.contactInfo.logo" class="avatar img-circle" src="{{webinar.organizer.contactInfo.logo}}" alt="Bologna" width="80" height="80">
                                            <p style="text-align: center; color: white;margin-top: 5px">
                                              {{webinar.organizer.contactInfo.name}}
                                            </p>
                                            <p style="text-align: center;color: white">
                                
                                                {{webinar.organizer.contactInfo.about}}
                                              </p>
                                            
                                            
                                          </div>
                                      </div>
                                  </div>
                                  
                              </div>
                          </mat-tab>
                        
                        
                    </mat-tab-group>
                      </div>
                </div>
            </div>
            <div class="col-md-4" style="margin-top: 32px;" >
                <div fxShow="true" fxShow.lt-sm="false" id="right" >
                    
                    <mat-card class="example-card mat-elevation-z4">
                      
                      <mat-card-content style="color: rgb(201, 199, 209);">
                          <span style="    font-size: 14px;
                          font-family: nunito;">{{webinar.category[0].name}}</span>
                          <br>
                          <div >
                              <span *ngFor="let tag of webinar.tags" style="    font-size: 10px;
                              font-family: nunito;">{{tag.name}}&nbsp;&nbsp;</span>
                          </div>
                        
                        
                        <br>
                        <span style="    font-size: 23px;
                        font-family: nunito;">
                          {{webinar.webinarInfo.title}}
                        </span>
                        <br>
                        <br>
                        <div style=" display: flex; flex-direction: row;justify-content: space-between;margin-bottom: -20px" >
                            <div style="display: flex; flex-direction: row;justify-content: flex-start">
                               <img *ngIf="!webinar.organizer.contactInfo.logo" mat-card-avatar  src="../../assets/alt-image.jpg" alt="">
                               <img *ngIf="webinar.organizer.contactInfo.logo" mat-card-avatar  src="{{webinar.organizer.contactInfo.logo}}" alt="">
                              <span style="    margin-left: 5px;
                              margin-top: 8px; font-family: 'nunito'">{{webinar.organizer.contactInfo.name}}</span> 
                            </div> 
                              
                            <button mat-icon-button style="color: white" (click)="shareDialog(webinar)" ><mat-icon>share</mat-icon></button>
                            </div>
                       
  
                      </mat-card-content>
                      <div *ngIf="webinar.schedule.startDate" class="calendar">
                        <p color="hsl(250,10%,80%)" font-size="2" font-family="BarlowCondensed-Regular" letter-spacing="1" class="month">{{webinar['schedule'].startDate | date: 'MMM'}}</p>
                        <h6 color="hsl(250,10%,80%)" font-size="3,4,4" font-family="BarlowCondensed-Regular" letter-spacing="1" class="date"><span *ngIf="webinar['schedule'].startDate==webinar['schedule'].endDate">{{webinar['schedule'].startDate | date: 'dd'}}</span>
                          <span *ngIf="webinar['schedule'].startDate!=webinar['schedule'].endDate">{{webinar['schedule'].startDate | date: 'dd'}} - {{webinar['schedule'].endDate | date: 'dd'}}</span>
                        </h6>
                        <p color="hsl(250,10%,76%)" font-size="0.5" font-family="CircularXXWeb-Book" class="year">{{webinar['schedule'].startDate | date: 'yyyy'}}</p>
                      </div>
                    </mat-card>
                    <br> 
                    <mat-card class="card mat-elevation-z4">
                      
                      <mat-card-content  style="color: rgb(201, 199, 209); overflow: visible;height: auto;">
                       
                        <div  *ngIf="webinar.schedule.startDate" class="time_parent"> 
                        <div  style="color: rgb(146, 143, 163)">Start Time<br><span  style="font-size: 20px; color: rgb(201, 199, 209)">{{webinar['schedule'].startTime}} IST</span></div>
                        <div  style="color: rgb(146, 143, 163)">End Time<br><span  style="font-size: 20px;color: rgb(201, 199, 209)">{{webinar['schedule'].endTime}} IST</span></div>
                      </div>
                    
                      <div  *ngIf="webinar.schedule.startDate" class="time_parent">  
                        <div class="entry">
                          <p color="hsl(250,10%,60%)"   class="entry">ENTRY</p>
                          <h6 color="hsl(145,100%,64%)"   letter-spacing="1" class="free" *ngIf="!webinar.feeInfo.feeInINR">FREE</h6>
                          <h6 color="hsl(145,100%,64%)"  letter-spacing="1" class="free" *ngIf="webinar.feeInfo.feeInINR">{{webinar.feeInfo.feeInINR | currency:'INR'}}</h6>
                        </div>
                        <div class="timer">
                          <p color="hsl(250,10%,60%)"  font-family="CircularXXWeb-Medium" class="entry">EVENT STARTS IN</p>
                          <h6 color="hsl(250,10%,80%)" #timer id="timer" font-family="BarlowCondensed-Regular" letter-spacing="1" class="sec">2D : 12H : 56M: 2S</h6>
                        
                        </div>
                        </div>
                        <div *ngIf="webinar.certificate=='true'" style="
                        margin-top: 5px;
                    " >
                          <img src="../../../../assets/certificate.svg" style="width: 30px;
                          height: 30px;
                          margin-left: 10px;
                          margin-right: 10px;"> <span style="font-family: 'nunito'">E-Certificate provided</span> 
                        </div>
                        <p style="text-align: center; "><button style="width: 200px;margin-top: 15px" (click)="register()" *ngIf="!expiredFlag && webinar.schedule.startDate" mat-raised-button color="primary"><span style="font-size: 15px">Register</span></button></p>
                        <div style="font-family: 'Nunito';
                        font-size: 8px;
                        text-align: center;">** your registration details will be shared with the organizer</div>
                      <!--  <p style="text-align: center; "><button style="width: 200px;margin-top: 15px" (click)="addSpeaker()" mat-raised-button color="primary"><span style="font-size: 15px">Register</span></button></p>-->
                      <p style="text-align: center; "><button style="width: 200px;margin-top: 15px" (click)="watchRecording()" *ngIf="recordingFlag" mat-raised-button color="primary"><span style="font-size: 15px">Watch Recording</span></button></p>
                        
                     
                        
                      </mat-card-content>
                      
                    </mat-card>
                  
                    
                </div>
                
            </div>
        </div>
    </div>
  
  </div>
  
  
  <div id="desktop-view" *ngIf="webinars.length>0" class="container" style="margin-bottom:60px">
    <mat-divider></mat-divider>
  <br>
    <div class="row">
      <div class="col-md-12">
             
                      <div style="display: flex;flex-direction: row;justify-content: space-between">
                        <h1 style="text-align: left; font-family: 'Nunito';font-weight: bold; ">
                          
                            
                            Related Webinars
                        
                        </h1>
                        <button mat-button style="font-family: 'Nunito';font-weight: bold; " routerLink="/attend">
                                 
                                     
                                    View All
                          
                                   </button>
                            </div>
              <div  fxLayout="row wrap" fxLayoutGap="16px grid">
                      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%"
                              *ngFor="let x of webinars">
                              <mat-card class="related-cards mat-elevation-z4" >
                                      <div class="container-card-img">
                                                      <img *ngIf="x.webinarInfo.posterUrl"  (click)="openRelatedWebinar(x.id)" mat-card-image class="related-card-img" src="{{x.webinarInfo.posterUrl}}" alt="no-image">
                                                      <img *ngIf="!x.webinarInfo.posterUrl"  (click)="openRelatedWebinar(x.id)" mat-card-image class="related-card-img" src="../../../../assets/img-alt.jpg" alt="no-image">
                                              
                                                      <mat-chip class="action-button-category btn btn-primary top-left mat-elevation-z4" >{{x.category[0].name}}</mat-chip>
                                                      <mat-chip   class="action-button-price btn btn-primary top-right mat-elevation-z4"> 
                                                              <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                               <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                              
                                                      </mat-chip>
                                              
                                              
                                       </div>
                              
          
                                      <mat-card-content>
                                              <p  matTooltip="{{x.webinarInfo.title}}" matTooltipClass='red-tooltip'
                                                      style="text-align: left; font-weight: 500; font-size: 1em ;">
                                                     
                                                      {{ (x.webinarInfo.title.length > 30) ? (x.webinarInfo.title | slice:0:30) + '...' : x.webinarInfo.title }}
                                              </p>
                                              <mat-divider></mat-divider>
                                              <br>
                                             
                                              <div>
                                                              <div style="display: flex;justify-content: space-between">
                                                              <div class="outer-div">
                                                                      <mat-icon style="color: #792cc7;font-size: 19px;">date_range</mat-icon>&nbsp;
                                                              <span
                                                                      *ngIf="x.schedule.startDate==x.schedule.endDate">{{x.schedule.startDate | date : "EEE dd MMM"}}</span>
                                                              <span *ngIf="x.schedule.startDate!=x.schedule.endDate">{{x.schedule.startDate | date : "EEE dd MMM"}}
                                                                      -
                                                                      {{x.schedule.endDate | date :"EEE dd MMM"}}</span>
                                                      </div>
                                                      &nbsp;&nbsp;
                                                      <div class="outer-div">
                                                                      <mat-icon style="color: #c94190; font-size: 19px;">schedule</mat-icon>
                                                              <span>{{x.schedule.startTime}} - {{x.schedule.endTime}}</span>
                                                      </div>
                                              </div>
                                                      
                                                      
                                                      <div style="margin-top: 10px; display: flex; flex-direction: row;justify-content: space-between" >
                                                   <div style="display: flex; flex-direction: row;justify-content: flex-start">
                                                      <img *ngIf="!x.organizer.contactInfo.logo" mat-card-avatar  src="../../../../assets/alt-image.jpg" alt="">
                                                      <img *ngIf="x.organizer.contactInfo.logo" mat-card-avatar  src="{{x.organizer.contactInfo.logo}}" alt="">
                                                     <span  matTooltip="{{x.organizer.contactInfo.name}}" matTooltipClass='red-tooltip' style="    margin-left: 5px;
                                                     margin-top: 6px;
    font-size: 9px;">{{ (x.organizer.contactInfo.name.length > 20) ? (x.organizer.contactInfo.name | slice:0:20) + '...' : x.organizer.contactInfo.name }}</span> 
                                                   </div> 
                                                              
                                                              
                                                                      <!--
                                                                              <mat-chip   class="action-button-price btn btn-primary mat-elevation-z4"> 
                                                                                             <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                                                              <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                                                             
                                                                              </mat-chip>
                                                                              <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                                              <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                                      -->
                                                                      <button mat-icon-button style="color: white" (click)="shareDialog(x)" ><mat-icon style="    height: 34px;
                                                                        width: 20px;
                                                                        font-size: 22px;">share</mat-icon></button>
                                                                      </div>
                                                                      
                                                      
                                              </div>
                                      </mat-card-content>
                                     
                              </mat-card>
                      </div>
              </div>
      </div>
</div>
  </div>

  <div id="mobile-view" *ngIf="webinars.length>0" class="container" style="margin-bottom:60px">
    <mat-divider></mat-divider>
  <br>
    <div class="row">
      <div class="col-md-12">
             
                      <div style="display: flex;flex-direction: row;justify-content: space-between">
                        <h1 style="text-align: left; font-family: 'Nunito';font-weight: bold; ">
                          
                            
                            Related Webinars
                        
                        </h1>
                        <button mat-button style="font-family: 'Nunito';font-weight: bold; " routerLink="/attend">
                                 
                                     
                                    View All
                          
                                   </button>
                            </div>
                            <owl-carousel-o [options]="customOptions" #owlCar>
                              <ng-container  *ngFor="let x of webinars">
                                  <ng-template  carouselSlide >
                              <mat-card class="related-cards mat-elevation-z4" >
                                      <div class="container-card-img">
                                                      <img *ngIf="x.webinarInfo.posterUrl"   (click)="openRelatedWebinar(x.id)" mat-card-image class="related-card-img" alt="no-image" src="{{x.webinarInfo.posterUrl}}" >
                                                      <img *ngIf="!x.webinarInfo.posterUrl"   (click)="openRelatedWebinar(x.id)" mat-card-image class="related-card-img" alt="no-image" src="../../../../assets/img-alt.jpg">
                                              
                                                      <mat-chip class="action-button-category btn btn-primary top-left mat-elevation-z4" >{{x.category[0].name}}</mat-chip>
                                                      <mat-chip   class="action-button-price btn btn-primary top-right mat-elevation-z4"> 
                                                              <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                               <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                              
                                                      </mat-chip>
                                              
                                              
                                       </div>
                              
          
                                      <mat-card-content>
                                              <p id="mobile-view" matTooltip="{{x.webinarInfo.title}}" matTooltipClass='red-tooltip'
                                                      style="text-align: left; font-weight: 500; font-size: 1em ;">
                                                     
                                                      {{x.webinarInfo.title }}
                                              </p>
                                              <p id="desktop-view" matTooltip="{{x.webinarInfo.title}}" matTooltipClass='red-tooltip'
                                                      style="text-align: left; font-weight: 500; font-size: 1em ;">
                                                     
                                                      {{ (x.webinarInfo.title.length > 30) ? (x.webinarInfo.title | slice:0:30) + '...' : x.webinarInfo.title }}
                                              </p>
                                              <mat-divider></mat-divider>
                                              <br>
                                             
                                              <div>
                                                              <div style="display: flex;justify-content: space-between">
                                                              <div class="outer-div">
                                                                      <mat-icon style="color: #792cc7;font-size: 19px;">calendar_today</mat-icon>&nbsp;
                                                              <span
                                                                      *ngIf="x.schedule.startDate==x.schedule.endDate">{{x.schedule.startDate | date : "EEE dd MMM"}}</span>
                                                              <span *ngIf="x.schedule.startDate!=x.schedule.endDate">{{x.schedule.startDate | date : "EEE dd MMM"}}
                                                                      -
                                                                      {{x.schedule.endDate | date :"EEE dd MMM"}}</span>
                                                      </div>
                                                      &nbsp;&nbsp;
                                                      <div class="outer-div">
                                                                      <mat-icon style="color: #c94190; font-size: 19px;">alarm</mat-icon>
                                                              <span>{{x.schedule.startTime}} - {{x.schedule.endTime}}</span>
                                                      </div>
                                              </div>
                                                      
                                                      
                                                      <div style="margin-top: 10px; display: flex; flex-direction: row;justify-content: space-between" >
                                                   <div style="display: flex; flex-direction: row;justify-content: flex-start">
                                                      <img *ngIf="!x.organizer.contactInfo.logo" mat-card-avatar  src="../../../../assets/alt-image.jpg" alt="no-image">
                                                      <img *ngIf="x.organizer.contactInfo.logo" mat-card-avatar  src="{{x.organizer.contactInfo.logo}}" alt="no-image">
                                                     <span  matTooltip="{{x.organizer.contactInfo.name}}" matTooltipClass='red-tooltip' style="    margin-left: 5px;
                                                     margin-top: 6px;
    font-size: 9px;">{{ (x.organizer.contactInfo.name.length > 20) ? (x.organizer.contactInfo.name | slice:0:20) + '...' : x.organizer.contactInfo.name }}</span> 
                                                   </div> 
                                                              
                                                              
                                                                      <!--
                                                                              <mat-chip   class="action-button-price btn btn-primary mat-elevation-z4"> 
                                                                                             <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                                                              <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                                                             
                                                                              </mat-chip>
                                                                              <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                                              <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                                                      -->
                                                                      <button mat-icon-button style="color: white" (click)="shareDialog(x)" ><mat-icon style="    height: 34px;
                                                                        width: 20px;
                                                                        font-size: 22px;">share</mat-icon></button>
                                                                      </div>
                                                                      
                                                      
                                              </div>
                                      </mat-card-content>
                                     
                              </mat-card>
                            </ng-template>
                          </ng-container>
                      </owl-carousel-o>
                      <p fxShow="true" fxShow.lt-sm="false" style="text-align: center;margin-top: 10px;font-size: 30px;">
                              <button  class=" corousel-nav-button owl-prev" (click)="owlCar.prev()"><mat-icon >navigate_before</mat-icon></button>
                              <button class=" corousel-nav-button owl-next" (click)="owlCar.next()"><mat-icon > navigate_next</mat-icon></button>
                            </p>
                      
      </div>
</div>
  </div>

  <div fxShow="false" fxShow.lt-sm="true" class="kHYWpG">
    <div >
      <div class="iUkjfd">
        <button (click)="register()" *ngIf="!expiredFlag && webinar.schedule.startDate" mat-raised-button class="izCBeS">
          <p class="ciLCCQ">
            <span>Click here to register</span>
          </p></button>
          <button  (click)="watchRecording()" *ngIf="recordingFlag" mat-raised-button class="izCBeS">
            <p class="ciLCCQ">
              <span>Watch Recording</span>
            </p></button>
            <button   *ngIf="!recordingFlag && expiredFlag && webinar.schedule.startDate" mat-raised-button class="izCBeS">
              <p class="ciLCCQ">
                <span>Event Expired</span>
              </p></button>
        </div>
      </div>
    </div>
 
