
<div class="parent-container">
                <form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="submitForm()">
    <div class="row">
      
        
        <div class="col-md-5">
            <div style="font-size: 30px; text-align: center; margin-top: 28px;  line-height: 1.2;">Hello, {{(user?.fullName)?(user?.fullName):(user?.displayName)}}</div>
            <div style="font-size: 18px; text-align: center; margin-top: 28px;  line-height: 1.2;">Welcome to this exciting journey of learning. We recommend you to complete your profile which will help us to provide you personalized content</div>
            
             
            <div style="text-align: center;margin-top: 20px">
                    Continue As
                    <div style="margin-top: 10px">
                                <mat-radio-group color="primary" formControlName="roles" class="radio" >
                                                <mat-radio-button color="primary"  style="font-family: 'Nunito'"  value="attendee" [checked]="true">Attendee</mat-radio-button>
                                                <mat-radio-button  color="primary" style="font-family: 'Nunito'" value="speaker">Speaker</mat-radio-button>
                                                <mat-radio-button color="primary" style="font-family: 'Nunito'" (click)="setOrganizer()" value="organizer">Organizer</mat-radio-button>
                                            
                                        </mat-radio-group>
                    </div>
                    <div style="margin-top: 10px">
                        - OR -
                        <p><button  style="font-family: 'Nunito'" mat-button type="button" (click)="skip()">Skip for now</button></p>
                    </div>
                  
                </div>
           
        </div>
  
    
     
        <div class="col-md-7">
         
                
         
                <mat-horizontal-stepper  labelPosition="bottom" [linear]="true">
                       


                        <mat-step [stepControl]="checkStepControl('basic-info')">
                                <ng-template matStepLabel>Basic Info</ng-template>
                         <div *ngIf="userForm.controls.roles.value=='organizer'" style="margin-bottom: 20px">
                                        <mat-radio-group color="primary" required formControlName="organizerType"  class="radio" >
                                                        <mat-radio-button color="primary" value='individual'>Individual</mat-radio-button>
                                                        <mat-radio-button color="primary" value='organization'>Organization</mat-radio-button>
                                                       
                                        </mat-radio-group>
                         </div>
                              
                         <div style="text-align: center;margin-bottom: 10px">
                                 <img *ngIf="user.photoURL" src="{{user.photoURL}}" alt="no photo" style="width: 80px;height: 80px;border-radius: 100%">
                                 <img *ngIf="!user.photoURL" src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png" alt="no photo" style="width: 80px;height: 80px;border-radius: 100%">
                         
                                 <div style="margin-top: 10px">
                                        <button type="button" (click)="fileInput.click()" mat-button color="primary">
                                                <span style="font-family: 'nunito'" *ngIf="user.photoURL">Change Picture</span>
                                                <span style="font-family: 'nunito'" *ngIf="!user.photoURL">Upload Picture</span>
                                        </button> 
                                        <input style="display: none" type="file" accept=".jpg,.jpeg" id="image-input" (change)="selectFile($event)" #fileInput>
                                 </div>
                         
                       </div>

                                <div  class="name_email"> 
                                <mat-form-field appearance="outline" style="width: 100%;    margin-right: 12px;">
                                        <mat-label>Name</mat-label>
                                        <input required  matInput placeholder="Name" formControlName="fullName">
                                        <mat-icon matSuffix>person</mat-icon>
                                </mat-form-field>
                                
                                <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Email</mat-label>
                                        <input   matInput placeholder="Email" formControlName="email">
                                        <mat-icon matSuffix>email</mat-icon>
                                </mat-form-field> 
                                </div>
                               
                                        <!--
                        <ngx-intl-tel-input
                        [cssClass]="'form-control'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true" 
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India"
                        [maxLength]="15"
                        [tooltipField]="TooltipLabel.Name"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        name="phone"
                                formControlName="mobileNumber"
                        ></ngx-intl-tel-input>--> 

                        <div class="name_email"> 
                                <mat-form-field appearance="outline" style="width: 100%;    margin-right: 12px;">
                                        <mat-label>Mobile</mat-label>
                                        <input required matInput   placeholder="+91 xxxxxxxxxx" formControlName="mobileNumber">
                                        <mat-icon matSuffix>call</mat-icon>
                                        <mat-error
                                        *ngIf="userForm.controls.mobileNumber.touched && userForm.controls.mobileNumber.invalid">
                                        <span *ngIf="userForm.controls.mobileNumber.errors.required">Mobile Number is mandatory</span>
                                        <span *ngIf="userForm.controls.mobileNumber.errors.pattern">Only digits Allowed</span>
                                                
                                        <span *ngIf="!userForm.controls.mobileNumber.errors.pattern && (userForm.controls.mobileNumber.errors.minlength || userForm.controls.mobileNumber.errors.maxlength)">Mobile Number should be of 10 digits</span>    
                                </mat-error>
                                </mat-form-field>

                                <mat-form-field (click)="picker1.open()" *ngIf="(userForm.controls.roles.value=='organizer' && userForm.controls.organizerType.value=='individual') || (userForm.controls.roles.value=='attendee' ||userForm.controls.roles.value=='speaker' ) " appearance="outline" style="width: 100%">
                                    <mat-label>Date of birth</mat-label>
                                    <input required   matInput placeholder="mm/dd/yyyy"  [matDatepickerFilter]="dateFilter"
                                    [matDatepicker]="picker1"  formControlName="dob"   (dateChange)="date($event)">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                   
                                   
                                    <mat-error
                                    *ngIf="userForm.controls.dob.touched && userForm.controls.dob.invalid">
                                    <span *ngIf="userForm.controls.dob.errors.required">Date Of Birth is mandatory</span>
                                </mat-error>
                               </mat-form-field>

                               <mat-form-field *ngIf="userForm.controls.organizerType.value=='organization' && userForm.controls.roles.value!='attendee' && userForm.controls.roles.value!='speaker' " appearance="outline" style="width: 100%">
                                        <mat-label>Website</mat-label>
                                        <input   matInput placeholder="https://www.example.com" formControlName="website">
                                        <mat-icon matSuffix>web</mat-icon>
                                </mat-form-field> 

                            </div>

                            <div *ngIf="(userForm.controls.roles.value=='organizer' && userForm.controls.organizerType.value=='individual') || (userForm.controls.roles.value=='attendee' ||userForm.controls.roles.value=='speaker' )" class="sex_occupation_college">

                                        <mat-form-field appearance="outline" style="width: 100%;margin-right: 5px;">
                                                        <mat-label>Select Sex</mat-label>
                                                        <mat-select formControlName="sex" 
                                                                >
                                                              
                                                                <mat-option   value="male">
                                                                        Male
                                                                </mat-option>
                                                                <mat-option   value="female">
                                                                        Female
                                                                </mat-option>
                                                                <mat-option   value="others">
                                                                        Other
                                                                </mat-option>
                                                        </mat-select>
                                                        <mat-error
                                                        *ngIf="userForm.controls.sex.touched && userForm.controls.sex.invalid">
                                                        <span *ngIf="userForm.controls.sex.errors.required">Providing sex is mandatory</span>
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="(userForm.controls.roles.value=='organizer' && userForm.controls.organizerType.value!='organization') || (userForm.controls.roles.value=='attendee' ||userForm.controls.roles.value=='speaker' )"appearance="outline" style="width: 100%;margin-right: 5px;">
                                                                <mat-label>Select Occupation</mat-label>
                                                                <mat-select formControlName="occupation" 
                                                                        >
                                                                      
                                                                        <mat-option   value="student">
                                                                                Student
                                                                        </mat-option>
                                                                        <mat-option   value="professional">
                                                                                Working Professional
                                                                        </mat-option>
                                                                        <mat-option   value="self-employed">
                                                                                Self-Employed
                                                                        </mat-option>
                                                                </mat-select>
                                                                <mat-error
                                                                *ngIf="userForm.controls.occupation.touched && userForm.controls.occupation.invalid">
                                                                <span *ngIf="userForm.controls.occupation.errors.required">Providing occupation is mandatory</span>
                                                            </mat-error>
                                                        </mat-form-field>
                                                       
                                                      
                            </div>

                        <div *ngIf="userForm.controls.occupation.value=='student' && ((userForm.controls.roles.value=='organizer' && userForm.controls.organizerType.value!='organization') || (userForm.controls.roles.value=='attendee' ||userForm.controls.roles.value=='speaker' ))" >
                                        <mat-form-field  appearance="outline" style="width: 100%" >
                                                        <mat-label>College</mat-label>
                                                        <div class="input_icon"> 
                                                        <input required matInput   placeholder="College" formControlName="college">
                                                        
                                                         <fa-icon [icon]="faGraduationCap"></fa-icon>
                                                        </div>
                                                        <mat-error
                                                        *ngIf="userForm.controls.college.touched && userForm.controls.college.invalid">
                                                        <span *ngIf="userForm.controls.college.errors.required">Providing college name is mandatory</span>
                                                    </mat-error> 
                                                </mat-form-field>
                        </div>

                        <div *ngIf="userForm.controls.occupation.value=='professional' && ((userForm.controls.roles.value=='organizer' && userForm.controls.organizerType.value!='organization') || (userForm.controls.roles.value=='attendee' ||userForm.controls.roles.value=='speaker' ))" class="name_email">
                                        <mat-form-field  appearance="outline" style="width: 100%; margin-right: 12px" >
                                                        <mat-label>Company</mat-label>
                                                        <input required matInput   placeholder="Company" formControlName="company">
                                                        <mat-icon matSuffix>apartment</mat-icon>
                                                        <mat-error
                                                        *ngIf="userForm.controls.company.touched && userForm.controls.company.invalid">
                                                        <span *ngIf="userForm.controls.company.errors.required">Providing company name is mandatory</span>
                                                    </mat-error>
                                                </mat-form-field>
                                         <mat-form-field *ngIf="userForm.controls.occupation.value=='professional' && ((userForm.controls.roles.value=='organizer' && userForm.controls.organizerType.value!='organization') || (userForm.controls.roles.value=='attendee' ||userForm.controls.roles.value=='speaker' ))" appearance="outline" style="width: 100%">
                                                                <mat-label>Designation</mat-label>
                                                                <input required  matInput placeholder="Designation" formControlName="designation">
                                                                <mat-icon matSuffix>work</mat-icon>
                                                                <mat-error
                                                                *ngIf="userForm.controls.designation.touched && userForm.controls.designation.invalid">
                                                                <span *ngIf="userForm.controls.designation.errors.required">Providing designation name is mandatory</span>
                                                            </mat-error>
                                        </mat-form-field>
                                                    
                        </div>

                            <!--

                               <mat-radio-group required formControlName="sex"  class="radio" style="margin-left: 20px">
                                    <mat-radio-button value='male'>Male</mat-radio-button>
                                    <mat-radio-button  value='female'>Female</mat-radio-button>
                                    <mat-radio-button  value='other'>Other</mat-radio-button>
                               </mat-radio-group>
                               -->
                               <br>
                               <p style="text-align: center">
                                <button mat-raised-button color="primary" matStepperNext type="button">Next<mat-icon style="    font-size: 22px;">arrow_forward</mat-icon></button>
                            </p> 
                               
                        </mat-step>

                        <mat-step >
                                <ng-template matStepLabel>More Info</ng-template>
                        
                               <!--
                                <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Skills</mat-label>
                                        <input   matInput placeholder="skills" formControlName="skills">
                                        <mat-icon matSuffix>insert_chart_outlined</mat-icon>
                                </mat-form-field>-->
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label>About Yourself</mat-label>
                                    <textarea required  matInput placeholder="tell us something about yourself" formControlName="bio"></textarea>
                                  
                               </mat-form-field>
                               <div class="name_email"> 
                               <mat-form-field appearance="outline" style="width: 100%; margin-right: 5px">
                                    <mat-label>LinkedIn profile</mat-label>
                                    <div class="input_icon"> 
                                    <input required  matInput placeholder="https://www.linkedin.com/in/" formControlName="linkedInProfile">
                                    <fa-icon [icon]="faLinkedIn"></fa-icon>
                                    </div>
                                 </mat-form-field> 
                                 <mat-form-field appearance="outline" style="width: 100%; margin-right: 5px">
                                        <mat-label>Facebook profile</mat-label>
                                        <div class="input_icon"> 
                                        <input   matInput placeholder="https://www.facebook.com/" formControlName="facebookProfile">
                                        <fa-icon [icon]="faFacebook"></fa-icon>
                                        </div>
                                </mat-form-field>
                                <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Twitter profile</mat-label>
                                        <div class="input_icon"> 
                                        <input   matInput placeholder="https://www.twitter.com/" formControlName="twitterProfile">
                                        <fa-icon [icon]="faTwitter"></fa-icon>
                                        </div>
                                </mat-form-field>
                                </div>

                                 <mat-form-field *ngIf="userForm.controls.organizerType.value=='organization' && userForm.controls.roles.value=='organizer'" appearance="outline" style="width: 100%">
                                        <mat-label>Related Industry</mat-label>
                                        <div class="input_icon">
                                        <input required  matInput placeholder="industry your organization is related to" formControlName="organizerIndustry">
                                        <fa-icon [icon]="faIndustry"></fa-icon>
                                         </div>
                                </mat-form-field>
                                <mat-form-field *ngIf="userForm.controls.organizerType.value=='organization' && userForm.controls.roles.value=='organizer'" appearance="outline" style="width: 100%">
                                        <mat-label>Location</mat-label>
                                        <input required  matInput placeholder="Organization location/address" formControlName="organizerLocation">
                                        <mat-icon matSuffix>location_on</mat-icon>
                                </mat-form-field>
                             
                                <mat-form-field *ngIf="userForm.controls.roles.value=='speaker'" appearance="outline" style="width: 100%">
                                        <mat-label>Favorite Topics To Speak</mat-label>
                                        <input required  matInput placeholder="Let us know some favorite topics where you like to speak" formControlName="speakerFavoriteTopics">
                                        <mat-icon matSuffix>favorite</mat-icon>
                                </mat-form-field>
                               <br>
                               <p style="text-align: center">
                                <button mat-raised-button color="primary" matStepperPrevious type="button"><mat-icon style="    font-size: 22px;">arrow_back</mat-icon>Back</button>
                                <button style="margin-left: 40px" mat-raised-button color="primary" matStepperNext type="button">Next<mat-icon style="    font-size: 22px;">arrow_forward</mat-icon></button>
                            </p> 
                               
                        </mat-step>


                        <mat-step>
                                <ng-template matStepLabel>Interests</ng-template>
                                <div style="text-align: center;font-family: 'nunito'">
                                    Tell us some of your interests
                                </div>
                                <br>

                                <mat-chip-list  [multiple]="true" #chipList selectable aria-label="Selected Filters">
                                      
                                        <mat-chip *ngFor="let category of categories" 
                                          (click)="changeSelected('s',category.name)"
                                           [selected]="checkExists(category.name)">
                                    {{category.name}}
                                   
                                  </mat-chip>
                                 
                                  </mat-chip-list>
                                  <br>
                                  <mat-checkbox color="primary" #terms>
                                               <span style="font-family: 'nunito'">Accept <a href="https://www.termsandconditionsgenerator.com/live.php?token=8wwzug12BWQPXgVmh702zNzef00KgiyZ" target="_blank">Terms and privacy policy</a> </span> 
                                </mat-checkbox>
                                  <div style="text-align: center">
                                        <button mat-raised-button color="primary" matStepperPrevious type="button"><mat-icon style="    font-size: 22px;">arrow_back</mat-icon>Back</button>
                                        <button style="margin-left: 40px" mat-raised-button color="primary" [disabled]="!terms.checked" type="submit">Save and Complete</button>
                                  </div> 
                        </mat-step>
                   
                </mat-horizontal-stepper>
           
   
   
</div>
    </div>
</form>

</div>
<!--
<form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="submitForm()">
        
        <mat-form-field appearance="outline" style="width: 100%">
                                <mat-label>Name</mat-label>
                                <input   matInput placeholder="Name" formControlName="displayName">
                                <mat-icon matSuffix>person</mat-icon>
         </mat-form-field>

</form>-->