import { FlexLayoutModule } from '@angular/flex-layout';
import { WebinarsModule } from './../webinar/webinar.module';
import { PipeModule } from './../common/pipe/pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  POSITION,
  SPINNER,
  PB_DIRECTION
} from 'ngx-ui-loader';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { FeaturedEventsComponent } from './featured-events/featured-events.component';
import { AllEventService } from './all-events/all-event.service';
import { AllEventsComponent } from './all-events/all-events.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularmaterialModule } from './../common/angularmaterial/angularmaterial.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'purple',
  fgsColor: 'purple',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.ballSpin, // background spinner type
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  text: 'loading',
  textColor: '#f5f3f3'
};
const routes: Routes = [];

@NgModule({
  declarations: [
    AllEventsComponent,
    FeaturedEventsComponent,
    UpcomingEventsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularmaterialModule,
    RouterModule.forChild(routes),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxPaginationModule,
    PipeModule,
    WebinarsModule,
    FlexLayoutModule
  ],
  providers: [AllEventService],
  exports: [
    AllEventsComponent,
    FeaturedEventsComponent,
    UpcomingEventsComponent
  ]
})
export class EventsModule {}
