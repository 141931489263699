import { CommunicationServiceService } from './../../../../service/communication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../../events/all-events/all-event.service';
import { AuthService } from './../../../../service/auth.service';
import { Component, OnInit } from '@angular/core';

import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';
import { Webinar } from 'src/app/interfaces/webinars.model';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-host-schedule',
  templateUrl: './dashboard-host-schedule.component.html',
  styleUrls: ['./dashboard-host-schedule.component.css']
})
export class DashboardHostScheduleComponent implements OnInit {
  startTimeArray: any = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];
  endTimeArray: any = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];
  id: string;
  webinar: Webinar;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  isDisabled = true;

  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.id = localStorage.getItem('webinar-id');
    //this.commService.webinarUserMapping=undefined;

    this.allEventService
      .getWebinarById(this.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.webinar = data;
        this.startDate = this.webinar.schedule.startDate;
        this.endDate = this.webinar.schedule.endDate;
        this.startTime = this.webinar.schedule.startTime;
        this.endTime = this.webinar.schedule.endTime;

        //console.log(this.webinar);
      });
  }

  update() {
    this.isDisabled = false;
  }

  cancel() {
    this.isDisabled = true;
  }

  save() {
    this.webinar.schedule.startDate = this.startDate;
    this.webinar.schedule.endDate = this.endDate;
    this.webinar.schedule.startTime = this.startTime;
    this.webinar.schedule.endTime = this.endTime;

    const dur = this.allEventService.getDuration(this.webinar);
    this.webinar.schedule.durationInMinutes = dur;

    this.allEventService.updateWebinar(this.webinar, this.id);
    this.toast.success('Schedule updated successfully');
    this.isDisabled = true;
  }

  changeEndTimeArray(time) {
    const list1 = this.startTimeArray;
    // console.log(time);
    const i = this.startTimeArray.indexOf(time);
    // console.log(i);
    // console.log('before', this.endTimeArray);
    this.endTimeArray.splice(0, i + 1);
    // console.log('after', this.endTimeArray);
  }

  resetEndTimeArray() {
    const timeArray = [
      '12:00 AM',
      '12:30 AM',
      '01:00 AM',
      '01:30 AM',
      '02:00 AM',
      '02:30 AM',
      '03:00 AM',
      '03:30 AM',
      '04:00 AM',
      '04:30 AM',
      '05:00 AM',
      '05:30 AM',
      '06:00 AM',
      '06:30 AM',
      '07:00 AM',
      '07:30 AM',
      '08:00 AM',
      '08:30 AM',
      '09:00 AM',
      '09:30 AM',
      '10:00 AM',
      '10:30 AM',
      '11:00 AM',
      '11:30 AM',
      '12:00 PM',
      '12:30 PM',
      '01:00 PM',
      '01:30 PM',
      '02:00 PM',
      '02:30 PM',
      '03:00 PM',
      '03:30 PM',
      '04:00 PM',
      '04:30 PM',
      '05:00 PM',
      '05:30 PM',
      '06:00 PM',
      '06:30 PM',
      '07:00 PM',
      '07:30 PM',
      '08:00 PM',
      '08:30 PM',
      '09:00 PM',
      '09:30 PM',
      '10:00 PM',
      '10:30 PM',
      '11:00 PM',
      '11:30 PM'
    ];
    //console.log('before reset', this.endTimeArray);
    // console.log('before reset', timeArray);
    this.endTimeArray = timeArray;
    //console.log('after reset', this.endTimeArray);
  }

  dateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    // Prevent Saturday and Sunday from being selected.
    //console.log(this.myForm.get('schedule')['controls']['endDate'].value);

    return d > dt;
  };
  endDateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    // Prevent Saturday and Sunday from being selected.
    const dtt = this.startDate;
    const momentDate = new Date(dtt);
    momentDate.setDate(momentDate.getDate() - 1);
    if (dtt != null && dtt != undefined && dtt != '') {
      return d > momentDate;
    }
    return d > dt;
  };

  date(e, dateType) {
    const momentDate = new Date(e.target.value); // Replace event.value with your date value
    const formattedDate = dayjs(momentDate).format('YYYY-MM-DD');

    //console.log(formattedDate);
    this.startDate = formattedDate;

    if (dateType == 'startDate') {
      this.endDate = formattedDate;
    }
  }
}
