<div class="container">
        <div style="padding: 15px;
        margin-top: 20px;">
    
           <span style="text-align: left; font-size:20px; font-weight: bold;font-family: 'Nunito'">Schedule Details</span> 
           <button *ngIf="isDisabled" (click)="update()" style="margin-left: 30px" mat-button color="primary"><mat-icon>edit</mat-icon>Edit</button>
           <button *ngIf="!isDisabled" (click)="save()" style="margin-left: 30px" mat-button color="primary"><mat-icon>save</mat-icon>Save</button>
           <button *ngIf="!isDisabled" (click)="cancel()" style="margin-left: 30px" mat-button color="primary"><mat-icon>cancel</mat-icon>Cancel</button>
          
        </div>
        <div class="col-md-6" style="
        margin-top: 14px;" >
    
                <mat-card class="mat-elevation-z8">
    
                  
                        <diV fxLayout="column" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">
                                <mat-form-field  appearance="outline">

                                    <mat-label>Start date</mat-label>
                                    <input  [disabled]="isDisabled" [(ngModel)]="startDate" matInput 
                                        [matDatepicker]="picker1"  [matDatepickerFilter]="dateFilter" (dateChange)="date($event,'startDate')" placeholder="Choose a date"
                                        >
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                   
                                   
                                </mat-form-field>
                                <mat-form-field   appearance="outline">

                                    <mat-label>End date</mat-label>
                                    <input  [disabled]="isDisabled" [(ngModel)]="endDate" matInput 
                                        [matDatepicker]="picker2" [matDatepickerFilter]="endDateFilter" (dateChange)="date($event,'endDate')" placeholder="Choose a date"
                                       >
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                  
                                   
                                </mat-form-field>
                            </diV>
                            <diV fxLayout="column" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Start Time</mat-label>

                                    <mat-select  [disabled]="isDisabled" [(ngModel)]="startTime" >
                                        <mat-option [value]="time" *ngFor="let time of startTimeArray"
                                        (click)="changeEndTimeArray(time)" >{{time}}
                                        </mat-option>
                                    </mat-select>
                                  


                                </mat-form-field>
                                <mat-form-field  appearance="outline">
                                    <mat-label>End Time</mat-label>

                                    <mat-select  [disabled]="isDisabled" [(ngModel)]="endTime" >
                                        <mat-option [value]="time" *ngFor="let time of endTimeArray"
                                        (click)="resetEndTimeArray()"  >{{time}}
                                        </mat-option>
                                    </mat-select>
                                   


                                </mat-form-field>
                            </diV>
                </mat-card>
        </div>
    
    </div>