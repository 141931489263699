import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input } from '@angular/core';
import { Webinar } from 'src/app/interfaces/webinars.model';
import { ShareComponent } from 'src/app/dialogs/share/share.component';

@Component({
  selector: 'app-webinar-card',
  templateUrl: './webinar-card.component.html',
  styleUrls: ['./webinar-card.component.scss']
})
export class WebinarCardComponent implements OnInit {
  @Input() webinar: Webinar;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(selectedWebinar: Webinar) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    // console.log(selectedWebinar);
    dialogConfig.data = selectedWebinar;

    this.dialog.open(ShareComponent, dialogConfig);
  }
}
