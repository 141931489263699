<div>
    <!--
        <div class="row">
               <p style="text-align: right"> <button class="mat-icon-button" color="primary"(click)="close()"><mat-icon>close</mat-icon></button></p>
        </div>-->

    <div class="row">
        <div class="col-md-12">

                <span style="    font-size: 11px;
                font-family: nunito;
                margin-bottom: 20px;
                margin-left: -5px;">Copy this link to share</span>
<!--

  
                <span style="    font-family: nunito;
                margin-left: -5px;
                margin-top: 20px;"> www.webiknowr.com/webinar/{{webinar.id}}</span>
                <input style="    font-size: 11px;
                font-family: nunito;
                margin-bottom: 20px;
                margin-left: -5px;" type="text"  disabled [(ngModel)]="link">-->

    <br>
                <div style="display: flex;flex-direction: row wrap;justify-content: space-between">
<!--
                    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Copy this link to share</mat-label>
        <input matInput [(ngModel)]="link" disabled
                autocomplete="off">

                
</mat-form-field>-->

<input  [(ngModel)]="link" disabled
autocomplete="off" style="width: 100%; font-family: 'Nunito'">
<button style="margin-left: 5px" (click)="copyLink()" [cdkCopyToClipboard]="link" class="btn"><mat-icon>content_copy</mat-icon>Copy</button>
</div> 

<div *ngIf="copyFlag">
        <br>
    <span style="color: green;font-family: 'Nunito'">Link copied to clipboard</span>
</div>
            
                <br>
            <mat-divider>
            
            </mat-divider>
            <br>
            <a href="{{generateCustomLink('whatsapp')}}"
                    data-action="share/whatsapp/share"
                    target="_blank"> 
                    <img src="assets/whatsapp.svg"  style="width: 30px;height: 30px;" alt="no-image">
                </a> 
              

            <a href="{{generateCustomLink('fb')}}"  target="_blank"><img src="assets/facebook.svg"  style="width: 30px;height: 30px; margin-left: 20px; " alt="no-image"></a>
            <a href="{{generateCustomLink('telegram')}}" target="_blank"><img src="assets/telegram.svg"  style="width: 30px;height: 30px; margin-left: 20px; " alt="no-image"></a>
            
            
            
              
               
            
        </div>
    </div>

</div>
