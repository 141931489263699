import { BackendService } from './../../service/backend.service';
import { awsApi } from './../../../environments/environment.prod';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AllEventService } from './../../events/all-events/all-event.service';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { AuthService } from './../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { AuthProvider, Theme } from 'ngx-auth-firebaseui';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { User } from 'src/app/interfaces/user.model';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  u: any;
  errorMessage: string;
  successMessage: string;
  editProfileFlag = false;
  loginFlag = true;
  registerFlag = false;
  user$: Observable<User>;
  userData: any; // Save logged in user data
  loginForm: FormGroup;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }
  providers = AuthProvider;
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private route: Router,
    public authService: AuthService,
    private fb: FormBuilder,
    private commService: CommunicationServiceService,
    private toastr: ToastrService,
    private allEventsService: AllEventService,
    private http: HttpClient,
    private backendService: BackendService,
    private _location: Location
  ) {}

  printUser(event) {}

  printError(event) {
    console.error(event);
  }

  afterLogin(event) {
    this.route.navigate(['home']);
  }

  tryRegister() {
    this.authService.doRegister(this.loginForm.value).then(
      (res) => {
        // console.log(res);
        this.errorMessage = '';
        this.successMessage = 'Your account has been created';
      },
      (err) => {
        console.log(err);
        this.errorMessage = err.message;
        this.successMessage = '';
      }
    );
  }

  toggleLogin(value) {
    if (value == 'login') {
      this.loginFlag = false;
      this.registerFlag = true;
    } else {
      this.loginFlag = true;
      this.registerFlag = false;
    }
  }

  async loginSuccess() {
    this.afAuth.authState.pipe(
      switchMap((user) => {
        // Logged in
        // console.log(user);
        if (user) {
          this.userData = user;
          localStorage.setItem('user', JSON.stringify(this.userData));
          this.afs
            .doc<User>(`users/${user.uid}`)
            .valueChanges()
            .subscribe((data) => {
              // console.log(data);
            });
        } else {
          // Logged out
          // console.log('hii');
          localStorage.setItem('user', null);
          JSON.parse(localStorage.getItem('user'));
          return of(null);
        }
      })
    );

    this.u = await this.authService.isLoggedIn();
    this.afs
      .doc<User>(`users/${this.u.uid}`)
      .valueChanges()
      .subscribe((data) => {
        // console.log(data);

        if (data.profileCompleted == undefined || !data.profileCompleted) {
          // console.log('hi');
          this.route.navigate(['login/create-profile']);
        } else {
          this.route.navigate(['home']);
          //this._location.back();
        }

        // this.backendService.saveUser(data);
      });

    this.toastr.success('Successfully logged in!');

    //this.route.navigate(['home']);
  }

  registerSuccess() {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        // Logged in
        if (user) {
          this.userData = user;
          localStorage.setItem('user', JSON.stringify(this.userData));
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out

          localStorage.setItem('user', null);
          JSON.parse(localStorage.getItem('user'));
          return of(null);
        }
      })
    );
    //this.allEventsService.sendEmail(null,"login");
    this.toastr.success('Successfully registered');
    this.route.navigate(['home']);
  }
}
