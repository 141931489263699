import { ExportService } from './../../../../service/export.service';
import { User } from './../../../../interfaces/user.model';
import { CommunicationServiceService } from './../../../../service/communication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../../events/all-events/all-event.service';
import { AuthService } from './../../../../service/auth.service';
import { Component, OnInit } from '@angular/core';

import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';
import { Webinar } from 'src/app/interfaces/webinars.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-host-registrants',
  templateUrl: './dashboard-host-registrants.component.html',
  styleUrls: ['./dashboard-host-registrants.component.css']
})
export class DashboardHostRegistrantsComponent implements OnInit {
  id: string;
  webinar: Webinar;
  webinarUserMapping: WebinarsUsersMapping;
  userId: User[] = [];

  usersExportList: any = [];
  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.id = localStorage.getItem('webinar-id');
    //this.commService.webinarUserMapping=undefined;

    this.allEventService
      .getWebinarUserMappingByWebinarId(this.id)
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.length > 0) this.webinarUserMapping = data[0];
        this.userId = this.webinarUserMapping.userId;
      });
  }

  covertToDate(timestamp) {
    const date = new Date(timestamp.seconds * 1000);

    return date;
  }

  export() {
    for (let i = 0; i < this.userId.length; i++) {
      this.usersExportList.push({
        Name: this.userId[i].displayName,
        Email: this.userId[i].email,
        Registration_date: this.covertToDate(this.userId[i].creationDate)
      });
    }

    //this.exportService.exportExcel(this.usersExportList, 'registrants');
  }
}
