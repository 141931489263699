import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AngularmaterialModule } from './../common/angularmaterial/angularmaterial.module';
import { FirebaseModule } from './../common/firebase/firebase.module';
import { PipeModule } from './../common/pipe/pipe.module';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { LoginComponent } from './login/login.component';

const redirectLoggedInToHost = () => redirectLoggedInTo(['host']);

const redirectUnauthorizedUserProfile = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInUserProfile = () =>
  redirectLoggedInTo(['user/dashboard/home']);

const routes: Routes = [
  {
    path: 'host/guest/login',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUserProfile }
  },
  {
    path: 'login/create-profile',
    component: CreateProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedUserProfile }
  },
  {
    path: 'host/login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHost }
  }
];

@NgModule({
  declarations: [LoginComponent, CreateProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularmaterialModule,
    RouterModule.forRoot(routes),
    PipeModule,
    LazyLoadImageModule,
    CarouselModule,
    FlexLayoutModule,
    FirebaseModule,
    FontAwesomeModule
  ],
  providers: [],
  exports: [LoginComponent, CreateProfileComponent]
})
export class LoginModule {}
