      
<div >
                <ngx-ui-loader bgsPosition="POSITION.centerCenter" [loaderId]="'loader-02'"></ngx-ui-loader>
<div class="row">

<div class="col-md-12">
                <div style="display: flex;flex-direction: row;justify-content: space-between">
                                <h1 style="text-align: left; font-family: 'Nunito';font-weight: bold; ">
                                   <!-- <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                                    <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                                    
                                    Upcoming Webinars
                                  <!--  <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                                    <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                                </h1>
                                <button mat-button style="font-family: 'Nunito';font-weight: bold; " routerLink="attend">
                                            <!-- <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                                             <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                                             
                                            View All
                                           <!--  <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                                             <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                                           </button>
                                    </div>
<!--
<owl-carousel-o [options]="customOptions" #owlCar>
        <ng-container  *ngFor="let x of webinars ">
            <ng-template  carouselSlide >
               
                   
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <p *ngIf="webinars?.length>0" fxShow="true" fxShow.lt-sm="false" style="text-align: center;margin-top: 10px;font-size: 30px;">
            <button  class=" corousel-nav-button owl-prev" (click)="owlCar.prev()"><mat-icon >navigate_before</mat-icon></button>
            <button class=" corousel-nav-button owl-next" (click)="owlCar.next()"><mat-icon > navigate_next</mat-icon></button>
          </p>
        -->
        <div  fxLayout="row wrap" fxLayoutGap="16px grid">
                        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let x of webinars">
                                        <app-webinar-card
                                        [webinar]="x">

                                        </app-webinar-card>
                        </div>
                        </div>
</div>
</div>

</div>