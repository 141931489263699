<div class="container">

<div class="row" style="margin-top: 20px">

    <div class="col-md-9">

        <mat-card>
            <div class="c1">
            <div >
                <img class="profile-img" src="../../assets/me.jpg">
            </div>
            <div class="c2" >
                <div class="c3">
                    <div>
                    <div style="margin-top: 10px">Ashish Agrawal</div>
                    <div style="font-size: 14px">Senior Software Engineer at Healthasyst</div>
                    <div style="font-size: 14px">Bangalore, India</div>
                     </div>
                     <div style="margin-left: 262px;"><button color="primary" mat-raised-button>Follow</button></div>
                </div>
           

            <div class="c4">
                <div>Followers
                    <br>100
                </div>
                <div>SpeakIn
                    <br>100
                </div>
                <div>Reach
                    <br>100
                </div>
                <div>Rating
                    <br>100
                </div>
                <div>Insights
                    <br>100
                </div>
                <div>Answers
                    <br>100
                </div>
                <div>Attended
                    <br>100
                </div>
            </div>
        </div>
        </div>
        </mat-card>
    </div> 
    <div class="col-md-3">
        <mat-card>
            <div class="c5">
            <button color="primary" mat-button>Share Profile</button>
            <button color="primary" mat-button>Request Event</button>
            <button color="primary" mat-button>Message</button>
            </div>  
        </mat-card>
    </div>
</div>

<div class="row" style="margin-top: 20px;margin-bottom: 20px">

    <div class="col-md-9">

        <mat-card>
            <div class="c3" style="font-family: 'nunito'">
                <div><a (click)="scrollToElement(about)">About</a></div>
                <div><a (click)="scrollToElement(events)">Events</a></div>
                <div><a (click)="scrollToElement(answers)">Answers</a></div>
                <div><a (click)="scrollToElement(insights)">Insights</a></div>
                <div><a (click)="scrollToElement(testimonials)">Testimonials</a></div>
                <div><a (click)="scrollToElement(community)">Community</a></div>
                <div><a (click)="scrollToElement(featured)">Featured</a></div>
            </div>
        </mat-card>
        
        
            <div style="margin-top: 10px" #about>
                    <app-user-profile-about></app-user-profile-about>
            </div>
            <div style="margin-top: 10px" #events>
                 <app-user-profile-events></app-user-profile-events>
            </div>
            <div #answers>
                answers
            </div>
            <div #insights>
                    insights
            </div>
            <div #testimonials>
                    Testimonials
                </div>
            <div #community>
                    Community
            </div>
            <div #featured>
                    Featured
            </div>
        
    </div>
    <div class="col-md-3">
            <mat-card></mat-card>
    </div>
</div>

</div>