import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  location = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private toasts: ToastrService
  ) {
    router.events.subscribe((event: NavigationEnd) => {
      this.location = event.url;
      if (this.location == '/admin/dashboard') {
        this.router.navigate(['/admin/dashboard/home']);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    const user = await this.authService.isLoggedIn();
    if (
      user.email != 'ashish.agrawal65@gmail.com' &&
      user.email != 'webiknowr@gmail.com'
    ) {
      this.toasts.warning('Access Denied to Admin section');
      this.router.navigate(['/home']);
    }
  }
}
