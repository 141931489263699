<div class="container">
        <div style="padding: 15px;
        margin-top: 20px;">
    
           <span style="text-align: left; font-size:20px; font-weight: bold;font-family: 'Nunito'">Speaker Details</span> 
           <button *ngIf="isDisabled" (click)="update()" style="margin-left: 30px" mat-button color="primary"><mat-icon>edit</mat-icon>Edit</button>
           <button *ngIf="!isDisabled" (click)="save()" style="margin-left: 30px" mat-button color="primary"><mat-icon>save</mat-icon>Save</button>
           <button *ngIf="!isDisabled" (click)="cancel()" style="margin-left: 30px" mat-button color="primary"><mat-icon>cancel</mat-icon>Cancel</button>
          
        </div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" >
             
        <div class="col-md-6" style="
        margin-top: 14px;" *ngFor="let x of speakers;let i=index" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%">
    
                <mat-card class="mat-elevation-z8" >
    
                                <p *ngIf="!isDisabled" style="text-align: center;margin-bottom: -18px;"><button (click)="poster.click()"  color="primary" mat-button><mat-icon>photo</mat-icon>
                                        <span *ngIf="webinar.organizer.contactInfo.logo">change photo</span>
                                        <span *ngIf="!webinar.organizer.contactInfo.logo">upload photo</span>
                                    </button>
                                </p>
                    
                              
                                                                
                    <input style="opacity: 0" #poster type="file" accept=".jpg,.png,.jpeg" id="poster-input" (change)="selectFile($event,'poster',i)" >
                  <p style="text-align: center">
         <img *ngIf="!x.contactInfo.logo" src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png" alt="no logo" width="100px" height="100px"> 
         <img *ngIf="x.contactInfo.logo" src="{{x.contactInfo.logo}}" alt="no logo" width="100px" height="100px"></p> 
    
              
    
                <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Name</mat-label>
                        <input [disabled]="isDisabled" matInput placeholder="Name" [(ngModel)]="x.contactInfo.name">
                        <mat-icon matSuffix>person</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Designation</mat-label>
                        <input [disabled]="isDisabled" matInput placeholder="Designation" [(ngModel)]="x.contactInfo.designation">
                        <mat-icon matSuffix>work</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>About the speaker</mat-label>
                        <textarea [disabled]="isDisabled" matInput placeholder="Designation" [(ngModel)]="x.contactInfo.about">
                        </textarea>
                        <mat-icon matSuffix>description</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Email</mat-label>
                        <input [disabled]="isDisabled" matInput placeholder="Email" [(ngModel)]="x.contactInfo.email">
                        <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Mobile</mat-label>
                        <input [disabled]="isDisabled" matInput placeholder="Mobile" [(ngModel)]="x.contactInfo.mobileNo">
                        <mat-icon matSuffix>phone</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>LinkdIn Profile</mat-label>
                        <input [disabled]="isDisabled" matInput placeholder="Lnkdin" [(ngModel)]="x.contactInfo.profileUrl">
                        <mat-icon matSuffix>link</mat-icon>
                </mat-form-field>
        
                </mat-card>
        </div>
    </div>
    </div>