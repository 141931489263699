import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from './../service/auth.service';
import { Webinar } from './../interfaces/webinarsold.model';
import { WebinarsUsersMapping } from './../interfaces/webinarsUsersMapping.module';
import { AngularFirestore } from '@angular/fire/firestore';
import { AdminService } from './admin.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../interfaces/user.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  listOfRegistrations: Observable<WebinarsUsersMapping[]>;
  _listOfRegistrations: WebinarsUsersMapping[];
  displayedColumns: string[] = ['Name', 'Status', 'Users', 'Count'];
  displayedColumns1: string[] = ['id', 'Name', 'email'];
  totalReg = 0;
  rFlag = true;
  uFlag = false;
  $users: Observable<User[]>;
  users: User[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userDataSource: any;
  constructor(
    private firestore: AngularFirestore,
    private adminService: AdminService,
    public authService: AuthService
  ) {
    this.firestore
      .collection('webinars-users-mapping', (ref) =>
        ref.orderBy('webinar.schedule.startDate', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((a) => {
            const data = a.payload.doc.data() as WebinarsUsersMapping;
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      )
      .subscribe((data) => {
        this._listOfRegistrations = data;
        data.forEach((i) => {
          this.totalReg = this.totalReg + i.totalHits;
        });
      });
    /*.subscribe((res: WebinarsUsersMapping[]) =>{
      console.log(res);
      
    
      var web;
      var obj= looseObject;
    
    res.map(webinar=>{
      let docref=this.firestore.collection('webinars').doc<Webinar>(webinar.webinarId).valueChanges().subscribe(data=>{
        web=data;
       
       // console.log(obj);
       obj.webinar=web;
        //this.listOfRegistrations.push()
      });
    
      //this.listOfRegistrations.push(obj);
      let userList=[];
      webinar.userId.map(u=>{
    
        let docref=this.firestore.collection('users', ref => ref.where('uid', '==', u)).valueChanges().subscribe(data=>{
      //console.log(data);
      userList.push(data);
        });
    
      })
      
      obj.user=userList;
      console.log(obj);
    
    this.listOfRegistrations.push(obj);
    console.log(this.listOfRegistrations);
    console.log(JSON.stringify(this.listOfRegistrations));
    })
    
    
    
    })*/
  }

  async ngOnInit(): Promise<void> {
    this.$users = await this.adminService.getAllUsers();
    this.$users.subscribe((data) => {
      console.log(JSON.stringify(data));
    });
    /*
     */
  }

  showRegistrationDetails() {
    this.rFlag = true;
    this.uFlag = false;
  }
  showUserDetails() {
    this.rFlag = false;
    this.uFlag = true;
  }
}

interface LooseObject {
  webinar: Webinar;
  user: User[];
}

const looseObject = {
  webinar: {
    id: null,
    webinarInfo: {
      title: 'title1',
      description: 'desc1',
      formLink: 'formlink1',
      posterUrl: 'posterurl1',
      meetingLink: 'meetinglisnk1'
    },
    category: {
      name: 'string'
    },
    industry: {
      name: 'string'
    },
    organizer: [
      {
        id: 'organizer1',
        contactInfo: {
          name: 'organizer1',
          profileUrl: 'profileUrl',
          email: 'email',
          mobileNo: '939393'
        }
      }
    ],
    feeInfo: {
      feeInINR: 120,
      paid: 'true'
    },
    recordingInfo: {
      recordingLink: 'sdsd',
      recorderd: true
    },
    schedule: {
      startDate: '10/29/2020',
      endDate: '10/29/2020',
      startTime: '09:00AM',
      endTime: '11:00AM',
      durationInMinutes: '120'
    },
    hostUserId: 'hostusedId',
    tags: [
      {
        name: 'tag1'
      },
      {
        name: 'tag2'
      }
    ],
    speakers: [
      {
        id: 'Speaker1',
        contactInfo: {
          name: 'Speaker1',
          profileUrl: 'profileUrl',
          email: 'email',
          mobileNo: '939393'
        }
      }
    ],
    status: 'Awaiting Approval',
    featured: true
  },
  user: [
    {
      uid: 'string',
      email: '',
      photoURL: '',
      displayName: ''
    }
  ]
};
