import { CommunicationServiceService } from './../../service/communication-service.service';
import { AuthService } from './../../service/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-host-guest',
  templateUrl: './host-guest.component.html',
  styleUrls: ['./host-guest.component.css']
})
export class HostGuestComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private commService: CommunicationServiceService
  ) {}

  ngOnInit(): void {
    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }
}
