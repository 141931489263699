

 

 

    <div style="height: calc(100vh - 65px);"> 
        <mat-toolbar  style="box-shadow: 0 0 3px rgba(0,0,0,.2);height: 75px; background-color: #fff; padding: 0 5px; text-transform: uppercase!important;">
          <div *ngIf="!location.includes('/user/dashboard')" fxShow="true" fxHide.gt-sm="true">
            <button mat-icon-button (click)="sidenav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
      
         
            <!--<span style="font-size: 30px;font-family: 'Lato'">Webiknowr</span>-->
            <img *ngIf="location.includes('/user/dashboard')" routerLink="/home" src="assets/navbar-logo.png" class="navbar-image" alt="no-logo" >
            <img *ngIf="!location.includes('/user/dashboard')" routerLink="/home" src="assets/navbar-logo.png"  style="height: auto; width: 300px;    outline: none;" alt="no-logo">
           <!--
            <mat-form-field class="toolbar-search"  appearance="outline">
              
                <input matInput  
                        autocomplete="off" placeholder="Search anything...">
      
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>-->
          <span class="example-spacer"></span>
    
          <div *ngIf="location.includes('/user/dashboard')" fxShow="true" fxHide.lt-md="true" >
           <span style="font-family: nunito;
           margin-right: 45px;">Hi, {{(user | async)?.displayName}}! Welcome to your dashboard</span>
          </div>
    
          <div *ngIf="location.includes('/user/dashboard')" fxShow="true" fxHide.gt-sm="true" >
            <div style="font-family: nunito; text-align: center;font-size: 10px;margin-right: 42px;margin-top: 9px;
            ">Welcome {{(user | async)?.displayName}}!
         
          </div>
            
           </div>
          
          <div *ngIf="!location.includes('/user/dashboard')" fxShow="true" fxHide.lt-md="true" class="toolbarlinks">
            <!--
              <button mat-button  [matMenuTriggerFor]="listen">Listen</button>
              <mat-menu #listen="matMenu" yPosition="below">
                <button mat-menu-item routerLink="listen/podcast">Podcasts</button>
                <button mat-menu-item routerLink="listen/stories">Stories</button>
              </mat-menu>
            -->
           <div class="toolbar-parent">
             <!--
              <div class="input-group" style="margin-right: 20px;">
                      <input type="text" class="form-control" [(ngModel)]="searchText" (keyup.enter)="search()" placeholder="what's on your mind?">
                      <div class="input-group-append">
                        <button class="btn btn-primary" style="background-color: #792cc7" (click)="search()" type="button">
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
             
              </div>
            -->
          
            
              <button mat-button class="attend" routerLink="attend"  >Attend</button>
              <!--
              <mat-menu #listen="matMenu" yPosition="below">
                <button mat-menu-item routerLink="watch/webinars">Attend</button>
                <button mat-menu-item routerLink="watch/webinars">Host</button>
                <button mat-menu-item routerLink="watch/webinars">Upcoming Webinars</button>
                <button mat-menu-item routerLink="watch/webinars">All Webinars</button>
              </mat-menu>-->
             <!--<button mat-button class="host" routerLink="watch"  >Watch</button>--> 
              <button mat-button class="host" routerLink="host"  >Submit</button>
             
            
              <button mat-button class="host" routerLink="contact"  >Contact</button>
    
              
              <span *ngIf="!(afAuth.authState| async)"><button  mat-button routerLink="login" class="login">Login/Register</button></span> 
              
              <ngx-auth-firebaseui-avatar [links]="links"
              [canViewAccount]=false
              (onSignOut)="signOut()"
              ></ngx-auth-firebaseui-avatar>
    
            </div>
              
          </div>
      
        </mat-toolbar>
        <mat-sidenav-container fxFlexFill  class="example-container">
      
          <mat-sidenav style="width: 200px" color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
            <div fxLayout="column">
              
               
             <div  style="text-align: center; margin-top: 10px ;margin-bottom:18px" >
                  <ngx-auth-firebaseui-avatar [links]="links"
                  [canViewAccount]=false
                  canDeleteAccount=false
                  (onSignOut)="signOut()"
                  ></ngx-auth-firebaseui-avatar>
                  
              </div>
              
              <div *ngIf="(afAuth.authState| async)" style="margin-bottom: -10px;">
                  <div style="    text-align: center;
                  font-family: nunito;
                  font-size: 11px;
                  
                  margin-top: -9px;">{{(afAuth.authState| async)?.email}}</div>
                  <div style="text-align: center;
                  margin-top: 5px;
                  margin-bottom: 12px;
                  font-size: 11px;
                  font-family: Nunito;">
                    <button class="btn" style="font-family: 'Nunito';color: #673ab7;
                    font-weight: 600;font-size: 11px;" routerLink="/user/dashboard/host" (click)="sidenav.toggle()" color="primary">Dashboard</button>
                     
                    <p style="text-align: center">
                     <button mat-button  style="font-family: 'Nunito';color: red;
                     font-weight: 600;font-size: 11px;margin-right:10px"  (click)="logOut()" color="primary"><mat-icon >login</mat-icon><span>Sign Out</span></button>
                 </p>
                </div>
                </div>
               
                <!--
              <mat-divider></mat-divider>
                <button mat-button routerLink="home" (click)="sidenav.toggle()" style="font-size: 15px; font-family: 'Nunito';font-weight: 700;"><mat-icon>home</mat-icon>&nbsp;&nbsp;<span>Home</span></button>
                <mat-divider></mat-divider>
              <button mat-button routerLink="attend" (click)="sidenav.toggle()" style="font-size: 15px; font-family: 'Nunito';font-weight: 700;"><mat-icon>event</mat-icon>&nbsp;&nbsp;Attend</button>
              <mat-divider></mat-divider>
              <button mat-button routerLink="host" (click)="sidenav.toggle()" style="font-size: 15px; font-family: 'Nunito';font-weight: 700;"><mat-icon>add</mat-icon>&nbsp;&nbsp;Host</button>
              <mat-divider></mat-divider>
           -->  
    
              <mat-nav-list>
                  <div *ngIf="!(afAuth.authState| async)" style="margin-top: -25px;">
               
    
                      <a mat-list-item   routerLink="login"  (click)="sidenav.toggle()" ><mat-icon>login</mat-icon>&nbsp;&nbsp;<span style="font-size: 15px; font-family: 'Nunito';font-weight: 700;">Login/Register</span></a>
                      <mat-divider></mat-divider>
                    </div> 
                 
                  <a mat-list-item   routerLink="home"  (click)="sidenav.toggle()" ><mat-icon>home</mat-icon>&nbsp;&nbsp;<span style="font-size: 15px; font-family: 'Nunito';font-weight: 700;">Home</span></a>
                  <mat-divider></mat-divider>
                  <a mat-list-item   routerLink="attend"  (click)="sidenav.toggle()" ><mat-icon>live_tv</mat-icon>&nbsp;&nbsp;<span style="font-size: 15px; font-family: 'Nunito';font-weight: 700;">Attend</span></a>
                  <mat-divider></mat-divider>
                 <!-- <a mat-list-item   routerLink="watch"  (click)="sidenav.toggle()" ><mat-icon>play_arrow</mat-icon>&nbsp;&nbsp;<span style="font-size: 15px; font-family: 'Nunito';font-weight: 700;">Watch</span></a>
                   <mat-divider></mat-divider> --> 
                   <a mat-list-item   routerLink="host"  (click)="sidenav.toggle()" ><mat-icon>add</mat-icon>&nbsp;&nbsp;<span style="font-size: 15px; font-family: 'Nunito';font-weight: 700;">Submit</span></a>
                   <mat-divider></mat-divider>         
                   <a mat-list-item   routerLink="contact"  (click)="sidenav.toggle()" ><mat-icon>contact_support</mat-icon>&nbsp;&nbsp;<span style="font-size: 15px; font-family: 'Nunito';font-weight: 700;">Contact</span></a>
                   <mat-divider></mat-divider>          
                             
            </mat-nav-list>
            </div>
          </mat-sidenav>
          <mat-sidenav-content fxFlexFill>
            <!--
           <app-about-me></app-about-me>
           <app-popular-speaker></app-popular-speaker>
           <app-upcoming-events></app-upcoming-events>
            <app-footer></app-footer>
           -->
           <router-outlet></router-outlet> 
          <app-footer></app-footer>
        <!--  <button (click)="openDialog()"class="feedback-button__container">Feedback</button>-->
        
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div> 
      <ngx-ui-loader></ngx-ui-loader>
      
  
