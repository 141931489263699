import { CommunicationServiceService } from './../../service/communication-service.service';
import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css']
})
export class HeadingComponent implements OnInit {
  isHeading = true;
  isSubheading = true;
  isHeadingBtn = true;
  searchText: string;

  constructor(
    private route: Router,
    private commService: CommunicationServiceService
  ) {}

  ngOnInit(): void {}

  search() {
    //console.log(this.searchText);
    this.route.navigate(['/attend']);
    this.commService.searchText = this.searchText;
  }
}
