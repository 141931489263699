

<div style="text-align: center;margin-bottom: 15px;margin-top: 10px">

    <img src="assets/check.svg" style="height: 70px;width:70px" alt="no-image">
</div>
<div style="font-family: 'nunito';margin-bottom: 10px; text-align: center">
    Contratulations {{user?.fullName}}, you have successfully created your profile.

</div>
<div style="text-align:center;">
    <button (click)="openRoute('/home')" style="margin-right: 5px; font-family: 'nunito'" mat-button color="primary">
        Go to Home
    </button>
    <button (click)="openRoute('/user/dashboard/host')" style="margin-right: 5px;font-family: 'nunito'"  mat-button color="primary">
        Go to Dashboard
    </button>
    <button (click)="openRoute('/attend')"  style="margin-right: 5px;font-family: 'nunito'"  mat-button color="primary">
        Attend Webinar
    </button>
    <button (click)="openRoute('/host')"  style="font-family: 'nunito'" mat-button color="primary">
        Submit Webinar
    </button>
</div>