<!--
<div [formGroup]="formGroup">
  <div>
    <mat-form-field>
      <input matInput placeholder="Enter your email" [formControl]="emailField" required>
      <mat-error *ngIf="emailField.invalid">{{ getErrorMessage(emailField) }}</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="half-width">
      <textarea matInput placeholder="Enter your feedback" [formControl]="feedbackField" required></textarea>
      <mat-error *ngIf="feedbackField.invalid">{{ getErrorMessage(feedbackField) }}</mat-error>
    </mat-form-field>
  </div>
  <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid">Submit Feedback</button>
</div>
-->
<h1 style="text-align: center">Thank you! for visiting our website</h1> 
<h3 style="text-align: center">Feel free to share your relevant feedback or concerns 
    by joining our official groups**</h3>
    <div style="text-align: center">
            <a href="https://chat.whatsapp.com/BI0bkvesdQm4XjMAoH0hyC" target="_blank"><img src="assets/whatsapp.svg"  style="width: 30px;height: 30px;" alt="no-image"></a>
            <a href="https://t.me/joinchat/Qbs2LRlf8fuuNrpJZtNHeg" target="_blank"><img src="assets/telegram.svg"  style="width: 30px;height: 30px; margin-left: 20px; " alt="no-image"></a>
    </div>
    <br>
    <h6>** These groups are specifically for feedback collection purposes, be rest assured they won't be used for any promotional activities</h6>

