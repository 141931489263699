<div class="jumbotron">
    <div >

            <app-heading></app-heading>
        <br>
        <br>
        <mat-divider></mat-divider>
        <br>
        <br>
        <app-featured-events></app-featured-events>
        <br>
        
        <mat-divider></mat-divider>
        <br>
        <br>
        <app-upcoming-events></app-upcoming-events>
        
        <br>
        <mat-divider></mat-divider>
        <br>
        <br>
        <div class="row">
                <div class="col-md-12">
                        <h1 style="text-align: left; font-family: 'Nunito';font-weight: bold;">Browse webinars by Categories</h1>
                       
                        <!--
                       
                        <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
                                <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let category of categories"
                                        >
                                        <mat-card class="mat-elevation-z4 category-card" [routerLink]="['/category', category.name]">
                
                                                <div class="container-card-img">
                                                       
                                                        <img   mat-card-image style="width: calc(100% + 32px) !important;" src="../../../assets/img-alt.jpg">
                                                

                                                
                                                
                                         </div>
                
                                                <mat-card-content>
                                                    
                                                       {{category.name}}
                                                </mat-card-content>
                                                
                                        </mat-card>
                                </div>
                        </div>

-->
                        <!---->
                        <ngx-ui-loader bgsPosition="POSITION.centerCenter" bgsSize="30" [loaderId]="'loader-03'"></ngx-ui-loader>
                        <owl-carousel-o [options]="customOptions" #owlCar>
                                <ng-container  *ngFor="let category of categories">
                                    <ng-template  carouselSlide >
                                        <div >
                                                <mat-card class="mat-elevation-z4 category-card" (click)="selectCategory(category)">
                
                                                        <div class="container-card-img">
                                                               
                                                                <img *ngIf="category.img"  mat-card-image style="width: calc(100% + 32px) !important;" src="{{category.img}}" alt="no-image">
                                                                <img *ngIf="!category.img"  mat-card-image style="width: calc(100% + 32px) !important;" src="../../../assets/img-alt.jpg" alt="no-image">
                                                        
        
                                                        
                                                        
                                                 </div>
                        
                                                        <mat-card-content>
                                                            
                                                               {{category.name}}
                                                        </mat-card-content>
                                                        
                                                </mat-card>
                                            </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                            <p *ngIf="categories?.length>0"  fxShow="true" fxShow.lt-sm="false" style="text-align: center;margin-top: 10px;font-size: 30px;">
                                <button  class="corousel-nav-button1 owl1-prev" (click)="owlCar.prev()"><mat-icon >navigate_before</mat-icon></button>
                                <button class="corousel-nav-button1 owl1-next" (click)="owlCar.next()"><mat-icon > navigate_next</mat-icon></button>
                                </p>
                        <!---->
                
                <br>
                <br>
                <!--
                <mat-divider></mat-divider>
                <br>
                
            
                    <h1 style="text-align: left; font-family: 'Nunito';font-weight: bold;">Browse webinars by Industries</h1>
                    <owl-carousel-o [options]="customOptions" #industryCorousel>
                        <ng-container  *ngFor="let industry of industries">
                            <ng-template  carouselSlide >
                                <div >
                                        <mat-card class="mat-elevation-z4 category-card" (click)="selectIndustry(industry)">
        
                                                <div class="container-card-img">
                                                        <img *ngIf="industry.img"  mat-card-image style="width: calc(100% + 32px) !important;" src="{{industry.img}}" alt="no-image">
                                                        <img *ngIf="!industry.img"   mat-card-image style="width: calc(100% + 32px) !important;" src="../../../assets/img-alt.jpg" alt="no-image">
                                                

                                                
                                                
                                         </div>
                
                                                <mat-card-content>
                                                    
                                                       {{industry.name}}
                                                </mat-card-content>
                                                
                                        </mat-card>
                                    </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                    <p *ngIf="industries?.length>0"  fxShow="true" fxShow.lt-sm="false" style="text-align: center;margin-top: 10px;font-size: 30px;">
                        <button  class=" corousel-nav-button owl-prev" (click)="industryCorousel.prev()"><mat-icon >navigate_before</mat-icon></button>
                        <button class=" corousel-nav-button owl-next" (click)="industryCorousel.next()"><mat-icon > navigate_next</mat-icon></button>
                        </p>

                -->
                </div>
           
        </div>
       
       
    </div>
</div>

