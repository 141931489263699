export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCRQPQ3XasR8zyQTt-07CFXbj3KE44tkHI',
    authDomain: 'webiknowr.com',
    databaseURL: 'https://webiknowr-4eaed.firebaseio.com',
    projectId: 'webiknowr-4eaed',
    storageBucket: 'webiknowr-4eaed.appspot.com',
    messagingSenderId: '917648645378',
    appId: '1:917648645378:web:2d8183ba96db65b08fb61b',
    measurementId: 'G-0B3P8PJVGK'
  }
};

export const awsApi = {
  getAllCategories:
    'https://akj44k44n2.execute-api.ap-south-1.amazonaws.com/Prod/webinar/list/category',
  getAllIndustries:
    'https://akj44k44n2.execute-api.ap-south-1.amazonaws.com/Prod/webinar/list/industry',
  getAllWebinars:
    'https://akj44k44n2.execute-api.ap-south-1.amazonaws.com/Prod/webinar/list',
  postWebinar:
    'https://akj44k44n2.execute-api.ap-south-1.amazonaws.com/Prod/webinar',
  getWebinar:
    'https://akj44k44n2.execute-api.ap-south-1.amazonaws.com/Prod/webinar',
  saveUser: 'http://localhost:5000/webinar/saveUser',
  getWebinarById:
    'https://webiknowr-mainapp.an.r.appspot.com/webinar/getWebinarById',
  getWebinarByHostId:
    'https://webiknowr-mainapp.an.r.appspot.com/webinar/getWebinarByHost',
  sendMail: 'https://webiknowr-mainapp.an.r.appspot.com/webinar/sendMail'
};
