import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile-insights',
  templateUrl: './user-profile-insights.component.html',
  styleUrls: ['./user-profile-insights.component.css']
})
export class UserProfileInsightsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
