import { Webinar } from './../interfaces/webinars.model';
import { WebinarsUsersMapping } from './../interfaces/webinarsUsersMapping.module';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { User } from '../interfaces/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  listOfRegistrations: any[] = [];

  $users: Observable<User[]>;
  $webinarUserMapping: Observable<WebinarsUsersMapping[]>;
  constructor(private firestore: AngularFirestore, private http: HttpClient) {
    this.$users = this.firestore.collection<User>('users').valueChanges();
  }

  getRegistrationList() {
    return this.listOfRegistrations;
  }

  getAllUsers() {
    return this.$users;
  }
}
interface LooseObject {
  webinar: Webinar;
  user: User[];
}

const looseObject = {
  webinar: {
    id: null,
    webinarInfo: {
      title: 'title1',
      description: 'desc1',
      formLink: 'formlink1',
      posterUrl: 'posterurl1',
      meetingLink: 'meetinglisnk1'
    },
    category: {
      name: 'string'
    },
    industry: {
      name: 'string'
    },
    organizer: [
      {
        id: 'organizer1',
        contactInfo: {
          name: 'organizer1',
          profileUrl: 'profileUrl',
          email: 'email',
          mobileNo: '939393'
        }
      }
    ],
    feeInfo: {
      feeInINR: 120,
      paid: 'true'
    },
    recordingInfo: {
      recordingLink: 'sdsd',
      recorderd: true
    },
    schedule: {
      startDate: '10/29/2020',
      endDate: '10/29/2020',
      startTime: '09:00AM',
      endTime: '11:00AM',
      durationInMinutes: '120'
    },
    hostUserId: 'hostusedId',
    tags: [
      {
        name: 'tag1'
      },
      {
        name: 'tag2'
      }
    ],
    speakers: [
      {
        id: 'Speaker1',
        contactInfo: {
          name: 'Speaker1',
          profileUrl: 'profileUrl',
          email: 'email',
          mobileNo: '939393'
        }
      }
    ],
    status: 'Awaiting Approval',
    featured: true
  },
  user: [
    {
      uid: 'string',
      email: '',
      photoURL: '',
      displayName: ''
    }
  ]
};
