import { Webinar } from './../../interfaces/webinars.model';
import { Pipe, PipeTransform } from '@angular/core';

const { isArray } = Array;

@Pipe({
  name: 'filter'
})
export class SearchPipe implements PipeTransform {
  transform(posts: Webinar[], find: string): Webinar[] {
    if (!posts) return [];
    if (!find) return posts;
    find = find.toLowerCase();
    // return search( posts, find);
    return find
      ? posts.filter((item) =>
          item.webinarInfo.title.toLowerCase().includes(find)
        )
      : posts;
  }
}

function search(entries: any[], search: string) {
  search = search.toLowerCase();

  return entries.filter(function (obj) {
    const keys: string[] = Object.keys(obj);
    return keys.some(function (key) {
      const value = obj[key];
      if (isArray(value)) {
        return value.some((v) => {
          return v.toLowerCase().includes(search);
        });
      } else if (!isArray(value)) {
        return value.toLowerCase().includes(search);
      }
    });
  });
}
