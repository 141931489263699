<table class="table table-hover .table-responsive table-custom">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          
            <th scope="col">Date of registration</th>
       <!--   <th scope="col">Actions</th>-->  
            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let y of userId;let i=index;">
            <th scope="row">{{i+1}}</th>
            <td>{{y.displayName}}</td>
            <td> 
               {{y.email}}
            </td>
          
         <td>{{covertToDate(y.creationDate) | date : 'dd-MMM-yyyy hh:mm'}}</td>
         
         <!--
            <td>
            <button   matTooltip="View Details" mat-icon-button><mat-icon>email</mat-icon></button>
                 
    
                 
        
        </td>-->
            </tr>

        
        </tbody>
      </table>
      <button *ngIf="userId.length>0" style="margin-left: 20px;font-family: 'nunito'" mat-raised-button (click)="export()"><mat-icon>save_alt</mat-icon>Export</button>
      <div *ngIf="userId.length==0" style="text-align: center;font-family: 'Nunito'">
        No Registrations present
      </div>