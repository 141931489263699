import { Organizer } from './../../interfaces/Organizer.model';
import { AllEventService } from './../../events/all-events/all-event.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';

@Component({
  selector: 'app-admin-organizer',
  templateUrl: './admin-organizer.component.html',
  styleUrls: ['./admin-organizer.component.css']
})
export class AdminOrganizerComponent implements OnInit {
  list1: string[] = [];
  webinarList: WebinarsUsersMapping[];
  organizerList: Observable<Organizer[]>;
  constructor(private allEventsService: AllEventService) {}

  ngOnInit(): void {
    this.organizerList = this.allEventsService.getOrganizers();
    this.allEventsService.getAllWebinarsUsersMapping().subscribe((data) => {
      this.webinarList = data;
    });
  }

  findWebinars(speakerName) {
    this.list1 = [];
    this.webinarList.forEach((data) => {
      const sList = data.webinar.speakers;
      sList.forEach((i) => {
        if (i.contactInfo.name === speakerName) {
          this.list1.push('https://webiknowr.com/webinar/' + data.webinarId);
        }
      });
    });

    return this.list1;
  }
}
