<div>
    total Organizers : {{(organizerList|async)?.length}}
  </div>
  <br>
<table class="table table-hover table-custom">
    <thead>
      <tr>
        <th scope="col">#</th> 
        <th scope="col">Name</th>
        <th  scope="col">Email</th>
        <th  scope="col">Designation</th>
        <th  scope="col">Profile Url</th>
       
        <th  scope="col">Mobile</th>
        <th scope="col">webinars</th>
        <th  scope="col">Photo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let y of organizerList | async;let i=index;">
        <th scope="row">{{i+1}}</th>
        <td>{{y.name}}</td>
        <td>{{y.email}}</td>
        <td>{{y.designation}}</td>
        <td>{{y.profileUrl}}</td>
        
        <td>{{y.mobileNo}}</td>
        <td><div *ngFor="let x of findWebinars(y.name)">
            {{x}}
        </div></td>
        <td><img src="{{y.logo}}" alt="no-photo" style="    width: 50px;
            height: 50px;"></td> 
            
        </tr>
    
    </tbody>
  </table>