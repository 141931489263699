import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile-events',
  templateUrl: './user-profile-events.component.html',
  styleUrls: ['./user-profile-events.component.css']
})
export class UserProfileEventsComponent implements OnInit {
  attendedEvents: any = [
    {
      name: 'Snapchat Lens Studio Workshop',
      posterUrl: '',
      organizer: 'Webiknowr'
    },
    {
      name: 'Online Gaming Virtual Summit',
      posterUrl: '',
      organizer: 'Webiknowr'
    },
    {
      name: 'End to End Product Management',
      posterUrl: '',
      organizer: 'Webiknowr'
    }
  ];

  spokenEvents: any = [
    {
      name: 'Snapchat Lens Studio Workshop',
      posterUrl: '',
      organizer: 'Webiknowr'
    },
    {
      name: 'Online Gaming Virtual Summit',
      posterUrl: '',
      organizer: 'Webiknowr'
    },
    {
      name: 'End to End Product Management',
      posterUrl: '',
      organizer: 'Webiknowr'
    }
  ];

  organizedEvents: any = [
    {
      name: 'Snapchat Lens Studio Workshop',
      posterUrl: ''
    },
    {
      name: 'Online Gaming Virtual Summit',
      posterUrl: ''
    },
    {
      name: 'End to End Product Management',
      posterUrl: ''
    }
  ];
  constructor() {}

  ngOnInit(): void {}
}
