import { awsApi } from './../../environments/environment.prod';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Webinar } from '../interfaces/webinarsold.model';
import { User } from '../interfaces/user.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) {}

  async saveWebinar(webinar: Webinar) {
    await this.http
      .post(awsApi.postWebinar, webinar, this.httpOptions)
      .toPromise()
      .then((data) => {
        console.log('webinar saved successfully');
        console.log('response returned : ', data);
      })
      .catch((data) => {
        console.log('webinar saving failure', data);
      });
  }

  async saveUser(user: User) {
    await this.http
      .post(awsApi.saveUser, user, this.httpOptions)
      .toPromise()
      .then((data) => {
        console.log('User saved successfully');
        console.log('response returned : ', data);
      })
      .catch((data) => {
        console.log('User saving failure', data);
      });
  }

  async findWebinarById(id: string) {
    await this.http
      .get(awsApi.getWebinarById + '?id=' + id, this.httpOptions)
      .toPromise()
      .then((data) => {
        console.log('response returned : ', data);
      })
      .catch((data) => {
        console.log('failure : ', data);
      });
  }

  async findWebinarByHostId(hostId: string) {
    await this.http
      .get(awsApi.getWebinarByHostId + '?id=' + hostId, this.httpOptions)
      .toPromise()
      .then((data) => {
        console.log('response returned : ', data);
      })
      .catch((data) => {
        console.log('failure : ', data);
      });
  }

  async getAllCategories() {
    await this.http
      .get(awsApi.getAllCategories, this.httpOptions)
      .toPromise()
      .then((data) => {
        console.log('response returned : ', data);
      })
      .catch((data) => {
        console.log('failure : ', data);
      });
  }

  async getAllIndustries() {
    await this.http
      .get(awsApi.getAllIndustries, this.httpOptions)
      .toPromise()
      .then((data) => {
        console.log('response returned : ', data);
      })
      .catch((data) => {
        console.log('failure : ', data);
      });
  }
}
