import { LoginModule } from './login/login.module';
import { HostModule } from './host/host.module';
import { DashboardModule } from './user/dashboard/dashboard.module';
import { WebinarsModule } from './webinar/webinar.module';
import { EventsModule } from './events/events.module';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  Meta,
  Title
} from '@angular/platform-browser';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  PB_DIRECTION,
  POSITION,
  SPINNER
} from 'ngx-ui-loader';
import {
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/analytics';

import { AdminComponent } from './admin/admin.component';
import { AdminModule } from './admin/admin.module';
import { AdminService } from './admin/admin.service';
import { AllEventService } from './events/all-events/all-event.service';
import { AngularmaterialModule } from './common/angularmaterial/angularmaterial.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BackendService } from './service/backend.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ContactComponent } from './contact/contact.component';
import { ContactSuccessComponent } from './dialogs/contact-success/contact-success.component';
import { CreateProfileSuccessComponent } from './dialogs/create-profile-success/create-profile-success.component';
import { FeedbackComponent } from './dialogs/feedback/feedback.component';
import { FirebaseModule } from './common/firebase/firebase.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './footer/footer.component';
import { HeadingComponent } from './home/heading/heading.component';
import { HomeComponent } from './home/home/home.component';
import { HostSuccessComponent } from './dialogs/host-success/host-success.component';
import { HttpClientModule } from '@angular/common/http';
import { IndustryComponent } from './webinar/industry/industry.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { LoginPopupComponent } from './dialogs/login-popup/login-popup.component';
import { NgModule } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerformanceMonitoringService } from '@angular/fire/performance';
import { PipeModule } from './common/pipe/pipe.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ShareComponent } from './dialogs/share/share.component';
import { ToastrModule } from 'ngx-toastr';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { UserprofileModule } from './userprofile/userprofile.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'purple',
  fgsColor: 'purple',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.ballSpin, // background spinner type
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  text: 'loading',
  textColor: '#f5f3f3'
};

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    FooterComponent,
    HomeComponent,
    IndustryComponent,
    ShareComponent,
    LoginPopupComponent,
    HostSuccessComponent,
    HeadingComponent,
    FeedbackComponent,
    ContactSuccessComponent,
    AdminComponent,
    CreateProfileSuccessComponent,
    UserprofileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireAnalyticsModule,
    // AngularFireDatabaseModule,
    // AngularFireStorageModule,

    // NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig,
    //   () => 'webiknowr',
    //  {
    //    enableFirestoreSync: true, // enable/disable autosync users with firestore
    //    toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
    //    toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true

    //  }),

    HttpClientModule,
    CarouselModule,
    NgxPaginationModule,
    ClipboardModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),

    FontAwesomeModule,
    LazyLoadImageModule,
    UserprofileModule,
    AdminModule,
    PipeModule,
    AngularmaterialModule,
    FirebaseModule,
    EventsModule,
    WebinarsModule,
    DashboardModule,
    HostModule,
    LoginModule
  ],
  providers: [
    AllEventService,
    AdminService,
    BackendService,
    ScreenTrackingService,
    UserTrackingService,
    Clipboard,
    PerformanceMonitoringService,
    NgxImageCompressService,
    Meta,
    Title,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ShareComponent],
  exports: []
})
export class AppModule {}
