import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile-answers',
  templateUrl: './user-profile-answers.component.html',
  styleUrls: ['./user-profile-answers.component.css']
})
export class UserProfileAnswersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
