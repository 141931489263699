import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Webinar } from 'src/app/interfaces/webinars.model';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {
  webinar: Webinar;
  textMessage = '';
  copyFlag = false;
  link = 'https://webiknowr.com/webinar/';
  constructor(
    private dialogRef: MatDialogRef<ShareComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.webinar = data;
    this.link = this.link + this.webinar.id;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  copyInputMessage(inputElement) {
    const elementText = inputElement.textContent;
    navigator.clipboard.writeText(elementText);
    /*
      inputElement.select();  
 
      document.execCommand('copy');  
  
      inputElement.setSelectionRange(0, 0);  
 
      this.textMessage="Copied to Clipboard";     
     
  */
  }

  copyLink() {
    this.copyFlag = true;
  }

  generateCustomLink(type) {
    if (type == 'whatsapp') {
      return 'https://whatsapp://send?text=' + this.link;
    }
    if (type == 'fb') {
      return '//www.facebook.com/sharer/sharer.php?u=' + this.link;
    }
    if (type == 'telegram') {
      return 'tg://msg_url?url=' + this.link;
    }
  }
}
