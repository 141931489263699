<div>
        <mat-card>
<!--About section-->
<div>
   
   <h3>About</h3> 
   <p>Software developer having more than 4 years of experience in full stack technologies. I have worked on various professional and personal projects to create highly scalable and efficient web applications. I have acquired good analytical skills over time and have been recognized as an efficient problem solver by technical and business leaders alike.

        I have very good agile experience and have been involved in different life cycle phases of the software development and gained good knowledge of requirement analysis, solution design, development, and delivery.
        
        Technical Skill Set:-
        
        Languages/Frameworks:
        • JAVA, Spring Boot, Angular, Microservices, REST API, UI/UX design
        
        Database:
        • Oracle, Mysql, MongoDB
        
        Tools:
        • JIRA, Confluence, Bitbucket, GIT, Azure Devops, Postman, Eclipse, AWS, Google GCP, Firebase
        
        Learning pursuit: React.JS, Node.js, ES6 (EcmaScript 2015), Python, R and Machine Learning.</p>
    
</div>
<mat-divider></mat-divider>
<!--experience section-->

<div style="margin: 20px 0 20px 0">

    <h3>Experience</h3>
    <div>Senior Software Engineer at HealthAsyst</div>
    <div>Dec 2020 - Present</div>
</div>

<mat-divider></mat-divider>
<!--education section-->


<div style="margin: 30px 0 0 0">

        <h3>Education</h3>
        <div>Jabalpur Engineering College</div>
        <div>B.E. Electronics and Telecommunication</div>
        <div>2012-2016</div>
    </div>
</mat-card>
</div>