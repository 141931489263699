import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo
} from '@angular/fire/auth-guard';
import { HostGuestComponent } from './host-guest/host-guest.component';
import { HostComponent } from './host/host.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipeModule } from './../common/pipe/pipe.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularmaterialModule } from './../common/angularmaterial/angularmaterial.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const redirectUnauthorizedToHostGuest = () =>
  redirectUnauthorizedTo(['host/guest']);
const redirectLoggedInToHost = () => redirectLoggedInTo(['host']);
const redirectUnauthorizedUserProfile = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: 'host',
    component: HostComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToHostGuest,
      redirectLoggedInToHost
    }
  },
  {
    path: 'user-profile/host',
    component: HostComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedUserProfile }
  },
  {
    path: 'host/guest',
    component: HostGuestComponent
  }
];

@NgModule({
  declarations: [HostComponent, HostGuestComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularmaterialModule,
    RouterModule.forRoot(routes),
    PipeModule,
    LazyLoadImageModule,
    CarouselModule,
    FlexLayoutModule
  ],
  providers: [],
  exports: [HostComponent, HostGuestComponent]
})
export class HostModule {}
