import { Webinar } from './webinars.model';

import { User } from './user.model';
export interface WebinarsUsersMapping {
  id: string;

  webinarId: string;
  webinarName: string;
  status: string;
  totalHits: number;
  startDate: string;
  hostId: string;
  organizerName: string;
  oraganizerEmail: string;
  // user:User[],
  webinar: Webinar;
  userId: User[];
  creationDate: Date;
}

export const webinarsUsersMapping: any = {
  /* id:"",
    webinarId:"",
    userId:[],
    webinar:{
        "id": null,
        "webinarInfo": {
          "title": "title1",
          "description": "desc1",
          "formLink": "formlink1",
          "posterUrl": "posterurl1",
          "meetingLink": "meetinglisnk1"
        },
        "category":  [{
            "name":"asd"
        }],
        "industry": [{
            "name":"asd"
        }],
        "organizer": 
          {
            "id": "organizer1",
            "contactInfo": {
              "name": "organizer1",
              "profileUrl": "profileUrl",
              "email": "email",
              "mobileNo": "939393",
              "logo":"",
              "about":"",
              "designation":""
            }
          }
        ,
        "feeInfo": {
          "feeInINR": "120",
          "paid": "true"
        },
        "recordingInfo": {
          "recordingLink": "sdsd",
          "recorderd": true
        },
        "schedule": {
          "startDate": "10/29/2020",
          "endDate": "10/29/2020",
          "startTime": "09:00AM",
          "endTime": "11:00AM",
          "durationInMinutes": 120
        },
        "hostUserId": "hostusedId",
        "tags": [
          {
            "name": "tag1"
          },
          {
            "name": "tag2"
          }
        ],
        "speakers": [
          {
            "id": "Speaker1",
            "contactInfo": {
                "name": "organizer1",
                "profileUrl": "profileUrl",
                "email": "email",
                "mobileNo": "939393",
                "logo":"",
                "about":"",
                "designation":""
            }
          }
        ],
        "status":"Awaiting Approval",
        "featured":true
      },

      user:[{
        "uid":"",
        "email":"",
        "photoURL":"",
        "displayName":"",
      }] */
};
