import { Webinar } from 'src/app/interfaces/webinars.model';
import { Category } from './../interfaces/Category.model';
import { Injectable, EventEmitter } from '@angular/core';
import { WebinarsUsersMapping } from '../interfaces/webinarsUsersMapping.module';

@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService {
  categoryName: string;
  industryName: string;
  category: EventEmitter<Category>;
  searchText: string;

  webinasrList: Webinar[];
  webinarUserMappingList: WebinarsUsersMapping[];
  webinarUserMapping: WebinarsUsersMapping;
  totalRegistered = 0;
  totalWebinars = 0;
  constructor() {
    this.category = new EventEmitter<Category>();
  }

  // 2
  raiseEvent(category: Category): void {
    //console.log(category);
    this.categoryName = category.name;
    this.category.emit(category);
  }
}
