import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../events/all-events/all-event.service';
import { AuthService } from './../../../service/auth.service';
import { Webinar } from './../../../interfaces/webinars.model';
import { CommunicationServiceService } from './../../../service/communication-service.service';
import { Component, OnInit } from '@angular/core';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';

import { take } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class DashboardHomeComponent implements OnInit {
  totalWebinars = 0;
  totalRegistered = 0;
  webinars: Array<Webinar>;
  webinarsUsersMapping: Array<WebinarsUsersMapping>;
  originalWebinarList: Webinar[];

  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService
  ) {
    this.totalRegistered = this.commService.totalRegistered;
    this.totalWebinars = this.commService.totalWebinars;

    // this.commService.totalRegistered=undefined;
    // this.commService.totalWebinars=undefined;
  }

  async ngOnInit(): Promise<void> {
    const user = await this.authService.isLoggedIn();

    this.allEventService
      .getWebinarsMappingOfHost(user.uid)
      .pipe(take(1))
      .subscribe((data) => {
        // console.log(data);
        this.webinarsUsersMapping = data;
        this.originalWebinarList = this.webinars;
        this.totalWebinars = this.webinarsUsersMapping.length;

        if (data) {
          this.webinarsUsersMapping.forEach((i) => {
            this.totalRegistered = this.totalRegistered + i.userId.length;
          });
        }
      });

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }
}
