import { AllEventService } from './../../../../../events/all-events/all-event.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Industry } from './../../../../../interfaces/Industry.model';
import { Category } from './../../../../../interfaces/Category.model';
import { CategoryService } from './../../../../../service/category.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Webinar } from 'src/app/interfaces/webinars.model';
import { IndustryService } from 'src/app/service/industry.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-summary-edit',
  templateUrl: './summary-edit.component.html',
  styleUrls: ['./summary-edit.component.css']
})
export class SummaryEditComponent implements OnInit {
  id = '';
  webinar: Webinar;
  categories: Category[];
  industries: Industry[];
  public summaryForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<SummaryEditComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private route: Router,
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private industryService: IndustryService,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private allEventsService: AllEventService
  ) {
    this.id = localStorage.getItem('webinar-id');
    this.webinar = data;

    this.categoryService
      .getCategories()
      .pipe(take(1))
      .subscribe((data) => {
        //console.log(data);
        this.categories = data;
      });

    this.industryService
      .getIndustries()
      .pipe(take(1))
      .subscribe((data) => {
        // console.log(data);
        this.industries = data;
      });
  }

  ngOnInit(): void {
    this.summaryForm = this.fb.group({
      webinarInfo: this.fb.group({
        title: [this.webinar.webinarInfo.title, [Validators.required]],
        description: [this.webinar.webinarInfo.description],
        formLink: [this.webinar.webinarInfo.formLink, [Validators.required]],
        posterUrl: [this.webinar.webinarInfo.posterUrl]
      }),

      feeInfo: this.fb.group({
        feeInINR: [
          this.webinar.feeInfo.feeInINR,
          [Validators.required, Validators.pattern('^[0-9]*$')]
        ],
        paid: [this.webinar.feeInfo.paid, [Validators.required]]
      }),
      category: this.fb.array([
        this.fb.group({
          name: [this.webinar.category[0].name]
        })
      ]),
      industry: this.fb.array([
        this.fb.group({
          name: [this.webinar.industry[0].name]
        })
      ])
    });
  }

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    // console.log(this.summaryForm.value);
    this.webinar.webinarInfo.title = this.summaryForm.get('webinarInfo')[
      'controls'
    ].title.value;
    this.webinar.webinarInfo.description = this.summaryForm.get('webinarInfo')[
      'controls'
    ].description.value;
    this.webinar.webinarInfo.formLink = this.summaryForm.get('webinarInfo')[
      'controls'
    ].formLink.value;

    this.webinar.feeInfo.paid = this.summaryForm.get('feeInfo')[
      'controls'
    ].paid.value;
    this.webinar.feeInfo.feeInINR = this.summaryForm.get('feeInfo')[
      'controls'
    ].feeInINR.value;

    this.webinar.category[0].name = this.summaryForm.get('category')[
      'controls'
    ][0]['controls'].name.value;
    this.webinar.industry[0].name = this.summaryForm.get('industry')[
      'controls'
    ][0]['controls'].name.value;

    //console.log(this.webinar);

    try {
      // this.firestore.collection('webinars').doc(this.id).update(this.webinar);

      this.allEventsService.updateWebinar(this.webinar, this.id);
    } catch (error) {
      this.toast.error('Some error occured. Please try again');
      console.log(error);
    }

    this.toast.success('Webinar Updated Successfully');
    this.dialogRef.close();
  }
}
