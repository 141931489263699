
                <!-- the position of the parent container must be set to relative -->
                <!-- It is really important to set loaderId for non-master loader -->
                

<div class="jumbotron">
                <div style="margin-top: -30px;">
                        <div class="row">
                                <!--<div class="col-lg-4"></div>
                                <div class="col-lg-4">
                                        <h1 style="text-align: center; font-family: 'Nunito';">All Webinars</h1>
                                </div>
                                <div class="col-lg-4">
        
                                </div>-->
                                <div class="col-md-12" >
                                        
                                                <div  fxLayout="row" fxFlex="100%" fxFlex.xs="100%"
                                                fxFlex.sm="100%">
                                                <div class="sticky" >
                                                <button style="font-family: 'Nunito'" class="header-buttons" [ngStyle]="{'font-weight': allFlag ? 700 : 400}" mat-button (click)="showAllWebinars()">All</button>
                                                <button style="font-family: 'Nunito'" class="header-buttons" [ngStyle]="{'font-weight': todayFlag ? 700 : 400}" mat-button (click)="showTodayWebinars()">Happening Today</button>
                                                <button style="font-family: 'Nunito'"class="header-buttons" [ngStyle]="{'font-weight': tomorrowFlag ? 700 : 400}" mat-button (click)="showTomorrowWebinars()">Happening Tomorrow</button>
                                                <button style="font-family: 'Nunito'"class="header-buttons" [ngStyle]="{'font-weight': weekFlag ? 700 : 400}" mat-button (click)="showThisWeekWebinars()">Happening this week</button>
                                        </div>
                                                </div>
                                                
                                                <!--
                                                <button mat-button style="font-family: 'Nunito'" (click)="toggleFilters()"><mat-icon>filter_alt</mat-icon>Filters</button>
                                                <button mat-button style="font-family: 'Nunito'" [matMenuTriggerFor]="sort"><mat-icon>sort</mat-icon>Sort</button>
                                                <mat-menu #sort="matMenu">
                                                
                                        
                                                <button mat-menu-item (click)="applySort('price-low-high')">
                                                        <mat-icon>trending_up</mat-icon>
                                                        <span>Price low to high</span>
                                                </button>
                                                <button mat-menu-item (click)="applySort('price-high-low')">
                                                                <mat-icon>trending_down</mat-icon>
                                                        <span>Price High to low</span>
                                                </button>
                                                <button mat-menu-item (click)="applySort('date')">
                                                                <mat-icon>date_range</mat-icon>
                                                        <span>Date</span>
                                                </button>
                                                </mat-menu>
                                                -->
                                        
                                </div>
                                
                        </div>
                        <mat-divider></mat-divider>
                        
                        
        
                        <!--
                        <div class="row">
                                <div class="col-lg-3"></div>
                                <div class="col-lg-6">
                                        <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Search</mat-label>
                                                <input matInput [(ngModel)]="searchText" placeholder="Search"
                                                        autocomplete="off">
        
                                                <mat-icon matSuffix>search</mat-icon>
                                        </mat-form-field>
                                       
        
                                </div>
                                <div class="col-lg-3"></div>
        
        
                        </div>
        -->
                        
            <!--         
        <div *ngIf="showFilters">
                        <br>
                        <mat-divider></mat-divider>
                        
                        <br>
                
        </div>
        -->   
        <br>
        <div >
                <!--
                        <div class="row">
                                        <div class="col-lg-3"></div>
                                        <div class="col-lg-6">
                                                <mat-form-field appearance="outline" style="width: 100%">
                                                        <mat-label>Search</mat-label>
                                                        <input matInput [(ngModel)]="searchText" placeholder="Search"
                                                                autocomplete="off">
                
                                                        <mat-icon matSuffix>search</mat-icon>
                                                </mat-form-field>
                                
                
                                        </div>
                                        <div class="col-lg-3"></div>
                
                
                                </div>-->
                                
                                <div class="search-mobile">
        
                                <mat-form-field appearance="outline" style="width: 100%" >
                                        <mat-label>Search</mat-label>
                                        <input matInput [(ngModel)]="searchText" placeholder="Search"
                                                autocomplete="off">

                                        <mat-icon matSuffix>search</mat-icon>
                                 </mat-form-field> 
                                </div>      
        
                                <nav class="navbar navbar-expand-sm navbar-light bg-light ">
                                <!-- <a class="navbar-brand" href="#">Navbar</a> -->
                                <button  class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span class="navbar-toggler-icon"></span>
                                                <span style="font-size: 15px;font-family: 'Nunito'">Filters</span>
                                                
                                </button>
                                
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav mr-auto">
                                                        <div class="search-desktop">       
                                        <li class="nav-item active">
                                                        <mat-form-field appearance="outline" class="search_class nav-link">
                                                                        <mat-label>Search</mat-label>
                                                                        <input matInput [(ngModel)]="searchText" placeholder="search by webinar name, topic, speaker, etc."
                                                                                autocomplete="off">
                                
                                                                        <mat-icon matSuffix>search</mat-icon>
                                                        </mat-form-field>
                                                </li>
                                                </div>
                                        <li class="nav-item">
                                                <mat-form-field appearance="outline" class="category_class nav-link">
                                                        <mat-label>Select Category</mat-label>
                                                        <mat-select [formControl]="categoryFormControl"
                                                        #multipleCategories id="categoryselect"  multiple  >
                                                                <mat-select-trigger>
                                                                        {{categoryFormControl.value ? categoryFormControl.value[0] : ''}}
                                                                        <span *ngIf="categoryFormControl.value?.length > 1"
                                                                                class="example-additional-selection">
                                                                                (+{{categoryFormControl.value.length - 1}}
                                                                                {{categoryFormControl.value?.length === 2 ? 'other' : 'others'}})
                                                                        </span>
                                                                </mat-select-trigger>
                                                                <mat-option  *ngFor="let category of categories" [value]="category.name">
                                                                        {{category.name}}
                                                                </mat-option>
                                                        </mat-select>
                                                </mat-form-field>
                                        </li>
                                        <li class="nav-item ">
                                                <mat-form-field appearance="outline" class="industry_class nav-link">
                                                        <mat-label>Select Industry</mat-label>
                                                        <mat-select [formControl]="industryFormControl"
                                                        #multipleIndustries multiple>
                                                                <mat-select-trigger>
                                                                        {{industryFormControl.value ? industryFormControl.value[0] : ''}}
                                                                        <span *ngIf="industryFormControl.value?.length > 1"
                                                                                class="example-additional-selection">
                                                                                (+{{industryFormControl.value.length - 1}}
                                                                                {{industryFormControl.value?.length === 2 ? 'other' : 'others'}})
                                                                        </span>
                                                                </mat-select-trigger>
                                                                <mat-option  *ngFor="let industry of industries" [value]="industry.name">
                                                                        {{industry.name}}
                                                                </mat-option>
                                                        </mat-select>
                                                </mat-form-field>
                                        </li>
                                        <li class="nav-item">
                                                <mat-form-field appearance="outline" class="duration_class nav-link">
                                                        <mat-label>Select Duration</mat-label>
                                                        <mat-select [formControl]="durationFormControl" #multipleDuration
                                                                multiple>
                                                                <mat-select-trigger>
                                                                        {{durationFormControl.value ? durationFormControl.value[0] : ''}}
                                                                        <span *ngIf="durationFormControl.value?.length > 1"
                                                                                class="example-additional-selection">
                                                                                (+{{durationFormControl.value.length - 1}}
                                                                                {{durationFormControl.value?.length === 2 ? 'other' : 'others'}})
                                                                        </span>
                                                                </mat-select-trigger>
                                                                <mat-option  *ngFor="let duration of durationList" [value]="duration.value">
                                                                        {{duration.value}}
                                                                </mat-option>
                                                        </mat-select>
                                                </mat-form-field>
                                        </li>
                                       
                                        <li class="nav-item">
                                                        <mat-button-toggle-group [(ngModel)]="priceFilter"  class="price_class nav-link"  appearance="legacy" #group="matButtonToggleGroup" 
                                                        >
                                                                <mat-button-toggle style="border-right: solid;
                                                                border-bottom: solid;
                                                                border-width: 1px;
                                                                border-left: solid;
                                                                border-top: solid;
                                                                border-left-width: 1px;
                                                                border-top-width: 1px;" value="false" aria-label="Text align center">
                                                               <span style="font-family: 'Nunito'">Free</span> 
                                                                </mat-button-toggle>
                                                                <mat-button-toggle style="border-bottom: solid;
                                                                border-right: solid;
                                                                border-top: solid;
                                                                border-width: 1px;
                                                                "  value="true" aria-label="Text align center">
                                                                        <span style="font-family: 'Nunito'">Paid</span> 
                                                                </mat-button-toggle>
                
                                                        </mat-button-toggle-group>
                                                </li>
                                       
                                       
                                        
                                        </ul>
                                        <!-- <form class="form-inline my-2 my-lg-0">
                                        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                                        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                        </form> -->
                                </div>
                                </nav>
                                <!--
                                <div style="text-align: right">
                                <mat-form-field appearance="outline" class="sort-class">
                                                <mat-label>Sort By</mat-label>
                                                <mat-select >
                                                
                                                        <mat-option (click)="applySorting('Newest')"  value="Newest">
                                                                Newest
                                                        </mat-option>
                                                        
                                                         <mat-option (click)="applySorting('Oldest')"  value="Oldest">
                                                                Oldest
                                                        </mat-option>
                                                      
                                                </mat-select>
                                        </mat-form-field>
                                </div>-->
        
                       
        </div>
       
         <!--added by preksha-->
        <div class="filters__active" *ngIf="searchText || categoryFormControl.value?.length>0 || industryFormControl.value?.length>0 || durationFormControl.value?.length>0 || priceFilter">
        
                        <mat-chip-list #chipList aria-label="Selected Filters">
                                <div class="filters__active__label">
                                        <span style="font-family: 'nunito';font-weight: 600">Active filters:</span>
                                        </div>
                                <mat-chip *ngFor="let category of categoryFormControl.value" [selectable]="true"
                                   [removable]="true" (removed)="removeCatergory(category , multipleCategories)">
                            {{category}}
                            <mat-icon matChipRemove >cancel</mat-icon>
                          </mat-chip>
                          <mat-chip *ngFor="let industry of industryFormControl.value" [selectable]="true"
                                         [removable]="true" (removed)="removeIndustry(industry , multipleIndustries)">
                                  {{industry}}
                                  <mat-icon matChipRemove >cancel</mat-icon>
                                </mat-chip>
                        <mat-chip *ngFor="let duration of durationFormControl.value" [selectable]="true"
                                         [removable]="true" (removed)="removeDuration(duration , multipleDuration)">
                                  {{duration}}
                                  <mat-icon matChipRemove >cancel</mat-icon>
                                </mat-chip>

                       <mat-chip *ngIf="priceFilter=='true'" [selectable]="true" [removable]="true" (removed)="removePrice(priceFilter)">
                        Paid
                <mat-icon matChipRemove >cancel</mat-icon>
              </mat-chip>
              <mat-chip *ngIf="priceFilter=='false'" [selectable]="true" [removable]="true" (removed)="removePrice(priceFilter)">
                Free
                <mat-icon matChipRemove >cancel</mat-icon>
              </mat-chip> 
              <mat-chip  *ngIf="searchText" [selectable]="true" [removable]="true" (removed)="removeSearch()">
                {{searchText}}
                <mat-icon matChipRemove >cancel</mat-icon>
              </mat-chip> 
                          </mat-chip-list>
                
        </div>
        
        <div class="d-flex justify-content-center"> 
                <div class="spinner-border" role="status"> 
                    <span class="sr-only" id="loading"></span> 
                </div> 
            </div> 
        <div *ngIf="webinars?.length==0" style="text-align: center;font-family:'Nunito';font-size: 15px;">
              <span >No data found.</span>  
        </div>
                      
       
        
        <div *ngIf="webinars?.length>0">
                <div class="row">
                        <div class="col-md-12">
                                <!--<div class="mat-paginator-container"><mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" showFirstLastButtons>
                                        </mat-paginator></div>-->
                                      
                                <div  fxLayout="row wrap" fxLayoutGap="16px grid">
                                        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%"
                                                *ngFor="let x of webinars | allfilter : searchText:categoryFormControl.value:industryFormControl.value :durationFormControl.value:priceFilter | paginate: config" (click)="selectPost(x);">
                                                <app-webinar-card
                                                [webinar]="x">

                                                </app-webinar-card>
                                        </div>
                                </div>
                        </div>
                </div>
                <br>
                <div style="text-align: right"><pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>  </div>   
                </div>
        </div>
        </div>
        
        