import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Webinar, sampleWebinar } from './../../interfaces/webinars.model';
import { LoginPopupComponent } from './../../dialogs/login-popup/login-popup.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './../../service/auth.service';

import { AllEventService } from './../../events/all-events/all-event.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  OnChanges,
  ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ShareComponent } from '../../dialogs/share/share.component';
import { User } from '../../interfaces/user.model';

import { WebinarsUsersMapping } from '../../interfaces/webinarsUsersMapping.module';
import * as dayjs from 'dayjs';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import window from 'global';
import { take } from 'rxjs/operators';
declare let Email: any;

@Component({
  selector: 'app-webinar-details',
  templateUrl: './webinar-details.component.html',
  styleUrls: ['./webinar-details.component.scss']
})
export class WebinarDetailsComponent implements OnInit {
  id: string;
  loading = true;
  loaded = false;
  countDownDate: any;
  disableFlag = false;
  recordingFlag = false;
  expiredFlag = false;
  showVideoFlag = false;
  @ViewChild('timer') countDownTimer: ElementRef;

  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  public selected: number;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  private existFlag = true;
  selectedTab = 0;
  _user: User = {
    email: '',
    uid: '',
    displayName: ''
  };

  webinarUserMapping: WebinarsUsersMapping;
  userId: User[] = [];
  registeredFlag = false;

  webinars: Webinar[] = [];
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private allEventService: AllEventService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private commService: CommunicationServiceService,
    private ngxService: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    private title: Title,
    private meta: Meta
  ) {
    //window.scroll(0,0);
  }

  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  webinar: Webinar = sampleWebinar;

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');
    this.setMetaTags();
    try {
      await this.getWebinar(); // DataCall
    } catch (err) {
      // console.log('Error', err);
    }
    this.allEventService
      .getWebinars()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          data.forEach((i) => {
            if (
              i.category[0].name == this.webinar.category[0].name &&
              i.webinarInfo.title != this.webinar.webinarInfo.title &&
              this.webinars.length < 5
            ) {
              this.webinars.push(i);
            }
          });

          //  console.log(this.webinars);
        }
      });

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }

  async getWebinar(): Promise<void> {
    // console.log(this.id)

    // console.log(this.id);
    this.ngxService.start();
    this.allEventService
      .getWebinarById(this.id.toString())
      .subscribe((data) => {
        // console.log(data);
        // console.log(data.category[0].name);
        /*
     if(!data){
      this.existFlag=false;
      this.ngxService.stop();
      this.router.navigate(['home']);
     }*/
        this.webinar = data;
        this.loaded = true;
        this.ngxService.stop();

        const c = Date.parse(
          `${this.webinar.schedule.endDate} ${this.webinar.schedule.endTime}`
        );

        const countdowntime = new Date(c);
        const newDate = new Date();

        if (dayjs(newDate).isAfter(countdowntime)) {
          this.expiredFlag = true;
        }

        if (this.webinar.recordingInfo.recorded) {
          this.recordingFlag = true;
        }
        //  console.log(this.webinar.category[0].name);
        // console.log(JSON.stringify(this.webinar));
        this.countDownDate = new Date(
          this.webinar.schedule.startDate
        ).getTime();
        // console.log(this.countDownDate);
        this.timer();
      });

    /*
    const user = await this.isLoggedIn();

    this.allEventService.getWebinarUserMappingByWebinarId(this.id).subscribe(data => {

      if(data && data.length>0)
        this.webinarUserMapping = data[0];
        this.userId=this.webinarUserMapping.userId;
      console.log(this.webinarUserMapping);
      this.userId.forEach(data=>{

        if(data.email==user.email){
          this.registeredFlag=true;
        }
      })
   
    });
*/
  }

  goBack(): void {
    this.location.back();
  }

  async register() {
    const user = await this.isLoggedIn();
    if (user) {
      // do something

      // console.log(localStorage.getItem('user'));
      //let user=JSON.parse(localStorage.getItem('user'));
      // console.log(this.webinar);
      // console.log(user.uid);
      this.webinar.id = this.id;
      this._user.uid = user.uid;
      this._user.email = user.email;
      this._user.displayName = user.displayName;
      // console.log(this._user);
      this.allEventService.saveRegistration(this.webinar, this._user);
      // this.allEventService.sendEmail(this.webinar,'registration');
      /* if (typeof window !== "undefined") {
      window.open(this.webinar.webinarInfo.formLink,'_blank');
    }
    */
      window.open(this.webinar.webinarInfo.formLink, '_blank');
    } else {
      // do something else
      this.openDialog(this.webinar);
    }
  }

  async watchRecording() {
    const user = await this.isLoggedIn();
    if (user) {
      // do something

      // console.log(localStorage.getItem('user'));
      //let user=JSON.parse(localStorage.getItem('user'));
      // console.log(this.webinar);
      // console.log(user.uid);
      this.webinar.id = this.id;
      this._user.uid = user.uid;
      this._user.email = user.email;
      this._user.displayName = user.displayName;
      // console.log(this._user);
      this.allEventService.saveRegistration(this.webinar, this._user);
      this.allEventService.saveViews(this.webinar, this._user);

      this.showVideoFlag = true;
    } else {
      // do something else
      this.openDialog(this.webinar);
    }
  }

  addSpeaker() {
    // this.allEventService.addOrganizer(this.webinar);

    this.allEventService.sendEmail(this.webinar, 'registration');
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  openDialog(selectedWebinar: Webinar) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = 600;
    dialogConfig.maxWidth = 400;
    // console.log(selectedWebinar);
    dialogConfig.data = selectedWebinar;

    this.dialog.open(LoginPopupComponent, dialogConfig);
  }
  shareDialog(selectedWebinar: Webinar) {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    //dialogConfig.maxHeight=600;
    //dialogConfig.maxWidth=400;

    dialogConfig.width = '500px';
    selectedWebinar.id = this.id;
    //console.log(selectedWebinar);
    dialogConfig.data = selectedWebinar;

    this.dialog.open(ShareComponent, dialogConfig);
  }
  getTimeRemaining(startDate, startTime) {
    const c = Date.parse(`${startDate} ${startTime}`);
    const countdowntime = new Date(c);
    const newDate = new Date();
    // console.log(newDate);
    const total = +countdowntime - +newDate;
    //console.log(total);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }
  timer() {
    const c = Date.parse(
      `${this.webinar.schedule.endDate} ${this.webinar.schedule.endTime}`
    );
    // console.log(c);
    const countdowntime = new Date(c);
    const newDate = new Date();

    const timeinterval = setInterval(() => {
      const t = this.getTimeRemaining(
        this.webinar.schedule.startDate,
        this.webinar.schedule.startTime
      );
      if (t.total <= 0) {
        this.disableFlag = true;
        this.countDownTimer.nativeElement.innerHTML = 'EXPIRED';
      } else {
        this.countDownTimer.nativeElement.innerHTML =
          t.days + 'd ' + t.hours + 'h ' + t.minutes + 'm ' + t.seconds + 's ';
      }

      // clock.innerHTML = 'days: ' + t.days + '<br>' +
      //                   'hours: '+ t.hours + '<br>' +
      //                   'minutes: ' + t.minutes + '<br>' +
      //                   'seconds: ' + t.seconds;
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY
    ];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1]
      ];
      const duration = time - this.swipeTime;
      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';

        if (swipe === 'next') {
          const isFirst = this.selectedTab === 0;
          if (this.selectedTab <= 3) {
            this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
          }
        } else if (swipe === 'previous') {
          const isLast = this.selectedTab === 4;
          if (this.selectedTab >= 1) {
            this.selectedTab = this.selectedTab - 1;
          }
        }
        // Do whatever you want with swipe
      }
    }
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    margin: 16,
    autoplay: true,
    autoplaySpeed: 200,
    autoplayHoverPause: true,

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/webinar', uri]));
  }

  openRelatedWebinar(uri) {
    this.redirectTo(uri);
  }

  setMetaTags() {
    this.title.setTitle(this.webinar.webinarInfo.title + ' | webiknowr');
    this.meta.updateTag({
      name: 'description',
      content: this.webinar.webinarInfo.description.substring(50)
    });
    this.meta.updateTag({
      property: 'og:title',
      content: this.webinar.webinarInfo.title + ' | webiknowr'
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.webiknowr.com/webinar/' + this.id
    });
    // this.meta.updateTag({ property:'og:image', content: this.webinar.webinarInfo.posterUrl });
  }
}
