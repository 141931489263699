<div class="container">

        <div class="row">

            <div class="col-md-12">
               
                <div class="header">
<p style="text-align: right;color:black"><button matTooltip="close" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button></p>
                    <span style="font-size: 25px">Congratulations!!!</span>
                    <br> Your webinar is submitted.
                    <br>
                    <br>
                    <div style="display: flex;flex-direction: row wrap;justify-content: space-between">
            
                            
                            <input  [(ngModel)]="link" disabled
                            autocomplete="off" style="width: 100%; font-family: 'Nunito'">
                            <button style="margin-left: 5px" (click)="copyLink()" [cdkCopyToClipboard]="link" class="btn"><mat-icon>content_copy</mat-icon>Copy</button>
                            </div> 
                            <div *ngIf="copyFlag">
                                    <br>
                                <span style="color: green;font-family: 'Nunito'">Link copied to clipboard</span>
                            </div>
                    <br><span style="font-size: 15px;color:black">You can contact us at webiknowr@gmail.com for any concerns</span>
                
                   

                </div>

                <div class="body">
                      You can check out all your submitted webinars from below link<br>
                       <button mat-button color="primary" (click)="openMyWebinars()">Go to My Dashboard</button>

                </div>
            
            </div>
        </div>
</div>

