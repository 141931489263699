import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdminService } from './../admin.service';
import { User } from './../../interfaces/user.model';
import { AllEventService } from './../../events/all-events/all-event.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
  _listOfRegistrations: WebinarsUsersMapping[];

  totalReg = 0;
  $users: Observable<User[]>;
  users: User[];

  constructor(
    private allEventsService: AllEventService,
    private adminService: AdminService,
    private loader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.loader.startBackground();
    this.allEventsService.getAllWebinarsUsersMapping().subscribe((data) => {
      this._listOfRegistrations = data;
      data.forEach((i) => {
        this.totalReg = this.totalReg + i.totalHits;
      });
      this.loader.stopBackground();
    });

    this.$users = this.adminService.getAllUsers();
  }
}
