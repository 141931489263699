import { RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from './admin.service';
import { AngularmaterialModule } from './../common/angularmaterial/angularmaterial.module';
import { AdminOrganizerComponent } from './admin-organizer/admin-organizer.component';
import { AdminSpeakerComponent } from './admin-speaker/admin-speaker.component';
import { AdminWebinarsComponent } from './admin-webinars/admin-webinars.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminCategoryComponent } from './admin-category/admin-category.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const redirectUnauthorizedUserProfile = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    children: [
      {
        path: 'home',
        component: AdminHomeComponent
      },
      {
        path: 'category',
        component: AdminCategoryComponent
      },
      {
        path: 'webinars',
        component: AdminWebinarsComponent
      },
      {
        path: 'users',
        component: AdminUsersComponent
      },
      {
        path: 'speakers',
        component: AdminSpeakerComponent
      },
      {
        path: 'organizers',
        component: AdminOrganizerComponent
      }
    ],
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedUserProfile }
  }
];

@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminHomeComponent,
    AdminCategoryComponent,
    AdminUsersComponent,
    AdminWebinarsComponent,
    AdminSpeakerComponent,
    AdminOrganizerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularmaterialModule,
    RouterModule.forRoot(routes)
  ],
  providers: [AdminService]
})
export class AdminModule {}
