<!--
<div class="wrapper">
     
        <nav id="sidebar">
                <div class="sidebar-header">
                    <div *ngIf="!profileImage"><img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQNL_ZnOTpXSvhf1UaK7beHey2BX42U6solRA&usqp=CAU" style="    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                    margin-left: 64px;
                    "> </div>
                    <div *ngIf="profileImage"><img  src="{{profileImage}}"  style="    width: 70px;
                        height: 70px;
                        border-radius: 100%;
                        margin-left: 64px;
                    "> </div>


                    <h3 style="margin-left: 53px;
                    margin-top: 5px;
                    margin-bottom: -10px;">Hi, {{userName}}</h3>

                
                
                </div>

            <ul class="list-unstyled components">
               
                <li >
                        
                        <a (click)="selectedMenu('d')"><span class="menu-links"> <i class="fas fa-home"></i> Dashboard</span></a>
                      
                    </li>
  
                <li >
                        <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle "><span  class="menu-links"> <i class="fas fa-calendar"></i> Webinars</span></a>
                        <ul class="collapse list-unstyled" id="homeSubmenu">
                            <li>
                                <a (click)="selectedMenu('hw')">Hosted Webinars</a>
                            </li>
                          
                        </ul>
                    </li>
               
               
            </ul>

           
        </nav>

    
        <div id="content">

            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">

                    <button type="button" (click)="myFunc($event)" id="sidebarCollapse" class="btn btn-info">
                        <i class="fas fa-align-left"></i>
                        
                    </button>
                 
                </div>
            </nav>

           <div *ngIf="dashboardSelected">
               <div class="dashboard-card">
            <mat-card class="mat-elevation-z4">
                <span style="font-size: 20px;
                margin-bottom: 25px;">Total Webinars Submitted</span>
                <br>
                <span style="font-size: 40px;
                ">{{this.totalWebinars}}</span>
            </mat-card>

            <mat-card class="mat-elevation-z4">
                    <span style="font-size: 20px;
                margin-bottom: 25px;">Total Users Registered</span>
                <br>
                <span style="font-size: 40px;
                ">{{this.totalRegistered}}</span>
                </mat-card>

            </div>
           </div>
           <div *ngIf="hostSelected">
         
            <app-timeline [webinars]="webinarsUsersMapping"></app-timeline>
           </div> 

           <div *ngIf="attendSelected">
                <h2>attend selected</h2>
           </div>
          
        </div>
    </div>
-->

<mat-sidenav-container   class="example-container">
  
        <mat-sidenav style="width: 200px" #snav mode="side"  opened  [fixedInViewport]="true" [fixedTopGap]="75" >
        
            
                <mat-nav-list>
     <!--              <a mat-list-item *ngIf="!location.includes('/summary')"  (click)="goToDashboardHome()" #dashboard ><mat-icon>dashboard</mat-icon>Dashboard</a>--> 
     <button style="margin-left: 22px;
     margin-bottom: 28px;
     margin-top: 9px;" mat-raised-button *ngIf="!location.includes('/summary')" routerLink="/home"  ><mat-icon>keyboard_backspace</mat-icon>Back to Home</button>
     
                        <a mat-list-item routerLinkActive="active" *ngIf="!location.includes('/summary')" routerLink="host" >Hosted Webinars</a>
                        <a mat-list-item routerLinkActive="active" *ngIf="!location.includes('/summary')" routerLink="attendee" >Attended Webinars</a>
                        <a mat-list-item routerLinkActive="active" *ngIf="!location.includes('/summary')" routerLink="profile" >Personal Details</a>
                        
                        <a mat-list-item routerLinkActive="active" *ngIf="!location.includes('/summary')" (click)="signOut()" ><mat-icon>login</mat-icon>Sign Out</a>
                        
                        <button style="margin-left:10px" mat-raised-button *ngIf="location.includes('/summary')" routerLink="/user/dashboard/host"  ><mat-icon>keyboard_backspace</mat-icon>Back to Timeline</button>

                
                        <a mat-list-item routerLinkActive="active" routerLink="host/summary" style="margin-top: 25px;"  *ngIf="location.includes('/summary')"><mat-icon>info</mat-icon>Basic Info</a>
                    
                        <a mat-list-item routerLinkActive="active" routerLink="host/summary/organizer"  *ngIf="location.includes('/summary')"><mat-icon>account_box</mat-icon>Organizer</a>
                        <a mat-list-item routerLinkActive="active" routerLink="host/summary/speakers" *ngIf="location.includes('/summary')"><mat-icon>record_voice_over</mat-icon>Speakers</a>
                        <a mat-list-item routerLinkActive="active" routerLink="host/summary/schedule" *ngIf="location.includes('/summary')"><mat-icon>schedule</mat-icon>Schedule</a>
                        <a mat-list-item routerLinkActive="active"  routerLink="host/summary/registrants"  *ngIf="location.includes('/summary')"><mat-icon>how_to_reg</mat-icon>Registrants</a>
                 <!--      <a mat-list-item   *ngIf="location.includes('/summary')"><mat-icon>notifications</mat-icon>Notifications</a>--> 
                
                </mat-nav-list>
        
        </mat-sidenav>
        <mat-sidenav-content > 
          <!--
         <app-about-me></app-about-me>
         <app-popular-speaker></app-popular-speaker>
         <app-upcoming-events></app-upcoming-events>
          <app-footer></app-footer>
         -->
         <button class="menu-icon" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>

         
        
         <router-outlet  ></router-outlet> 
      
        </mat-sidenav-content>
      </mat-sidenav-container>
