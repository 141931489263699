import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo
} from '@angular/fire/auth-guard';
import { WebinarDetailsComponent } from './../../webinar/webinar-details/webinar-details.component';
import { SummaryEditComponent } from './host/summary/summary-edit/summary-edit.component';
import { ProfileComponent } from './profile/profile.component';
import { TimelineComponent } from './host/timeline/timeline.component';
import { SummaryComponent } from './host/summary/summary.component';
import { DashboardHostSpeakersComponent } from './host/speakers/speakers.component';
import { DashboardHostOrganizerComponent } from './host/organizer/organizer.component';
import { DashboardHostSettingsComponent } from './host/dashboard-host-settings/dashboard-host-settings.component';
import { DashboardHostScheduleComponent } from './host/dashboard-host-schedule/dashboard-host-schedule.component';
import { DashboardHostRegistrantsComponent } from './host/dashboard-host-registrants/dashboard-host-registrants.component';
import { DashboardHomeComponent } from './home/home.component';
import { AttendeeComponent } from './attendee/attendee.component';
import { DashboardComponent } from './dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipeModule } from './../../common/pipe/pipe.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularmaterialModule } from './../../common/angularmaterial/angularmaterial.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const redirectUnauthorizedUserProfile = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: 'user/dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'home',
        component: DashboardHomeComponent
      },
      {
        path: 'host',
        component: TimelineComponent
      },
      {
        path: 'host/summary',
        component: SummaryComponent
      },
      {
        path: 'host/summary/organizer',
        component: DashboardHostOrganizerComponent
      },
      {
        path: 'host/summary/speakers',
        component: DashboardHostSpeakersComponent
      },
      {
        path: 'host/summary/schedule',
        component: DashboardHostScheduleComponent
      },
      {
        path: 'host/summary/registrants',
        component: DashboardHostRegistrantsComponent
      },
      {
        path: 'attendee',
        component: AttendeeComponent
      },
      {
        path: 'attendee/webinar/:id',
        component: WebinarDetailsComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      }
    ],
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedUserProfile }
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    AttendeeComponent,
    DashboardHomeComponent,
    DashboardHostRegistrantsComponent,
    DashboardHostScheduleComponent,
    DashboardHostSettingsComponent,
    DashboardHostOrganizerComponent,
    DashboardHostSpeakersComponent,
    SummaryComponent,
    TimelineComponent,
    ProfileComponent,
    SummaryEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularmaterialModule,
    RouterModule.forRoot(routes),
    PipeModule,
    LazyLoadImageModule,
    CarouselModule,
    FlexLayoutModule,
    ClipboardModule
  ],
  providers: [Clipboard],
  exports: []
})
export class DashboardModule {}
