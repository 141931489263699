import { AngularFirestore } from '@angular/fire/firestore';
import { Webinar } from './../../../interfaces/webinars.model';
import { CommunicationServiceService } from './../../../service/communication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../events/all-events/all-event.service';
import { AuthService } from './../../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-attendee',
  templateUrl: './attendee.component.html',
  styleUrls: ['./attendee.component.css']
})
export class AttendeeComponent implements OnInit {
  upcomingWebinars: WebinarsUsersMapping[];
  previousWebinars: WebinarsUsersMapping[];
  originalWebinars: WebinarsUsersMapping[] = [];
  filteredWebinars: WebinarsUsersMapping[] = [];
  upcomingFlag = false;
  previousFlag = false;
  totalWebinars = 0;
  totalRegistered = 0;
  webinars: Array<Webinar>;
  webinarsUsersMapping: Array<WebinarsUsersMapping>;
  originalWebinarList: Webinar[] = [];
  hostFlag = false;
  _user: any;
  displayName = 'user';
  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private firestore: AngularFirestore,
    private ngxService: NgxUiLoaderService
  ) {}

  async ngOnInit(): Promise<void> {
    // console.log(this.webinars);

    const user = await this.authService.isLoggedIn();

    this._user = user;
    this.displayName = user.displayName;

    //console.log(user.uid);
    this.ngxService.start();
    this.firestore
      .collection<WebinarsUsersMapping>('webinars-users-mapping')
      .valueChanges()
      .subscribe((data) => {
        //console.log(data);
        this.webinarsUsersMapping = data;

        if (data.length > 0) {
          this.webinarsUsersMapping.forEach((i) => {
            for (let j = 0; j < i.userId.length; j++) {
              if (i.userId[j].email == user.email) {
                this.originalWebinars.push(i);
                break;
              }
            }
          });
          this.ngxService.stop();
          // console.log(this.originalWebinars);
          this.filteredWebinars = this.originalWebinars;
          this.totalWebinars = this.webinarsUsersMapping.length;
          this.upcomingWebinars = this.getUpcomingWebinars();
          // console.log(this.upcomingWebinars);
          this.previousWebinars = this.getPreviousWebinars();
          this.hostFlag = true;
        } else {
          this.hostFlag = false;
        }
      });

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }

  getPreviousWebinars(): WebinarsUsersMapping[] {
    const currentDate = new Date();
    const formattedCurrentDate = dayjs(currentDate).format('YYYY-MM-DD');
    this.originalWebinars = this.filteredWebinars;

    return this.originalWebinars.filter((txn) => {
      const dt1 = Date.parse(
        `${txn.webinar.schedule.endDate} ${txn.webinar.schedule.endTime}`
      );
      const newDate = new Date(dt1);
      return dayjs(newDate).isBefore(currentDate);
    });
  }

  getUpcomingWebinars() {
    const currentDate = new Date();
    const formattedCurrentDate = dayjs(currentDate).format('YYYY-MM-DD');
    this.originalWebinars = this.filteredWebinars;

    return this.originalWebinars.filter((txn) => {
      const dt1 = Date.parse(
        `${txn.webinar.schedule.endDate} ${txn.webinar.schedule.endTime}`
      );
      const newDate = new Date(dt1);
      return dayjs(newDate).isAfter(currentDate);
    });
  }

  routeToSummary(webinar) {
    this.commService.webinarUserMapping = webinar;
    this.router.navigate(['user/dashboard/host/summary']);
  }

  showDetails(webinar) {}

  navigateToWebinar(id) {
    if (
      confirm(
        'You will be redirected to the webinar. Do you want to exit the dashboard?'
      )
    ) {
      this.router.navigate(['/webinar', id]);
    }
  }
}
