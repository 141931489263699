<div>
    <mat-card>
<div class="row">
        <div class="col-md-4">
            <h3>Recent Events Attended</h3>
            <div *ngFor="let event of attendedEvents">
                    <div style="margin-bottom: 5px">
                        <div >
                            <span>{{event.name}}</span>
                        </div>
                        <div style="font-size: 11px">
                            <span>Organized by {{event.organizer}}</span>
                        </div>
                    </div>
                
            </div>

        </div>
        <div class="col-md-4">
                <h3>Recent Events Spoken</h3>
                <div *ngFor="let event of spokenEvents">
                        <div style="margin-bottom: 5px">
                            <div >
                                <span>{{event.name}}</span>
                            </div>
                            <div style="font-size: 11px">
                                <span>Organized by {{event.organizer}}</span>
                            </div>
                        </div>
                    
                </div>
    
            </div>
            <div class="col-md-4">
                    <h3>Recent Events Organized</h3>
                    <div *ngFor="let event of organizedEvents">
                            <div style="margin-bottom: 5px">
                                <div >
                                    <span>{{event.name}}</span>
                                </div>
                                <div style="font-size: 11px">
                                    <span>Organized by {{event.organizer}}</span>
                                </div>
                            </div>
                        
                    </div>
        
                </div>
            </div>
    </mat-card>
</div>