import { Webinar } from '../../interfaces/webinars.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allfilter'
})
export class FilterPipe implements PipeTransform {
  durationList = [
    {
      value: 'less than 30 minutes',
      min: '0',
      max: '30'
    },
    {
      value: '30-60 minutes',
      min: '30',
      max: '60'
    },
    {
      value: '1-2 hours',
      min: '60',
      max: '120'
    },
    {
      value: 'more than 2 hours',
      min: '120',
      max: '1000'
    }
  ];

  transform(
    items: Webinar[],
    find: string,
    categoryFilter: string[],
    industryFilter: string[],
    durationFilter: string[],
    priceFilter?: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        for (let i = 0; i < item.speakers.length; i++) {
          if (
            find &&
            !item.speakers[i].contactInfo.name
              .toLowerCase()
              .includes(find.toLowerCase()) &&
            !item.webinarInfo.title
              .toLowerCase()
              .includes(find.toLowerCase()) &&
            !item.organizer.contactInfo.name
              .toLowerCase()
              .includes(find.toLowerCase()) &&
            !item.category[0].name.toLowerCase().includes(find.toLowerCase()) &&
            !item.industry[0].name.toLowerCase().includes(find.toLowerCase())
          ) {
            return false;
          }
        }
        /*
      for(let i=0;i<item.tags.length;i++){
        if (find  && !item.tags[i].name.toLowerCase().includes(find.toLowerCase())){
          return false;
      }
      }
    */
        if (
          categoryFilter &&
          categoryFilter.length > 0 &&
          !categoryFilter.includes(item.category[0].name)
        ) {
          return false;
        }
        if (
          industryFilter &&
          industryFilter.length > 0 &&
          !industryFilter.includes(item.industry[0].name)
        ) {
          return false;
        }
        if (durationFilter && durationFilter.length > 0) {
          const list = this.getDuration(durationFilter);

          if (list) {
            const min = list[0];
            const max = list[1];

            if (
              item.schedule.durationInMinutes < min ||
              item.schedule.durationInMinutes > max
            ) {
              return false;
            }
          }
        }
        if (priceFilter && item.feeInfo.paid != priceFilter) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }

    return null;
  }

  getDuration(durationValue) {
    let min = 0;
    let max = 1000;
    const list1 = [];
    const list2 = [];

    if (durationValue != undefined && durationValue.length > 0) {
      durationValue.forEach((data) => {
        let list3 = [];
        list3 = this.durationList.filter((x) => x.value == data);

        if (list3 != undefined && list3.length > 0) {
          list1.push(list3[0].min);
          list2.push(list3[0].max);
        }
      });
      min = Math.min(...list1);
      max = Math.max(...list2);
    }
    const listFinal = [];
    listFinal.push(min, max);
    return listFinal;
  }
}
