<div class="jumbotron">


    <div class="row">
        <div class="col-md-12">

            <div *ngIf="authService.user$ | async as user" >
                 <h1 style="text-align: center; font-family: 'Nunito';">Total registration : {{totalReg}}</h1>
               
                        
                
                <div *ngIf="user.email=='ashish.agrawal65@gmail.com' || user.email=='singh.prashant3093@gmail.com' || user.email=='webiknowr@gmail.com'">
            
                  
                    <button style="font-family: 'Nunito'" class="header-buttons"  mat-raised-button (click)="showRegistrationDetails()">Registration Details</button>
                    <button style="font-family: 'Nunito'" class="header-buttons"  mat-raised-button (click)="showUserDetails()">User details</button>
                  
           
                    <br>
                    <br>
                    
                  <div *ngIf="rFlag">
                    <div>
                      total webinars : {{(_listOfRegistrations)?.length}}
                    </div>
                    <br>
                    <table class="table table-hover table-custom">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th  scope="col">Organizer</th>
                            <th  scope="col">Speaker</th>
                            <th scope="col">Schedule</th>
                            <th scope="col">users</th>
                            <th scope="col">Registrations</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let y of _listOfRegistrations ;let i=index;">
                            <th scope="row">{{i+1}}</th>
                            <td>{{y.webinar.webinarInfo.title}}</td>
                            <td>{{y.webinar.organizer.contactInfo.name}}</td>
                            <td>
                              <div *ngFor="let s of y.webinar.speakers">
                                  {{s.contactInfo.name}}
                              </div>
                       
                            </td>
                            <td> <span
                                *ngIf="y.webinar.schedule.startDate==y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}</span>
                        <span *ngIf="y.webinar.schedule.startDate!=y.webinar.schedule.endDate">{{y.webinar.schedule.startDate | date : "EEE dd MMM"}}
                                -
                                {{y.webinar.schedule.endDate | date :"EEE dd MMM"}}</span>
                            <br>
                            {{y.webinar.schedule.startTime}} - {{y.webinar.schedule.endTime}}
                            </td>
                           
                            <td>
                              <div *ngFor="let u of y.userId">
                                {{u.email}}

                              </div>
                            </td>
                                <td>{{y.totalHits}}</td>
                                
                            </tr>
                        
                        </tbody>
                      </table>

            
                    </div>

                    <div *ngIf="uFlag">
                        <div>
                            total users : {{($users | async)?.length}}
                          </div>
                          <br>
                        <table class="table table-hover table-custom">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th  scope="col">Email</th>
                                <th  scope="col">Mobile</th>
                                
                                
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let y of $users | async;let i=index;">
                                <th scope="row">{{i+1}}</th>
                                <td>{{y.displayName}}</td>
                                <td>{{y.email}}</td>
                                <td>{{y.phoneNumber}}</td>
                             
                          
                                    
                                </tr>
                            
                            </tbody>
                          </table>
    
                
                        </div>
                </div>
                <div *ngIf="user.email!='ashish.agrawal65@gmail.com' && user.email!='singh.prashant3093@gmail.com' && user.email!='webiknowr@gmail.com'">
                    <h3 style="text-align: center">You are not an admin</h3>
                </div>
                </div>
        </div>
    </div>
</div>

