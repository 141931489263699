import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { WebinarsUsersMapping } from './../../../../interfaces/webinarsUsersMapping.module';
import { Speaker } from './../../../../interfaces/Speaker.model';
import { CommunicationServiceService } from './../../../../service/communication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AllEventService } from './../../../../events/all-events/all-event.service';
import { AuthService } from './../../../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Webinar } from 'src/app/interfaces/webinars.model';

import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.css']
})
export class DashboardHostSpeakersComponent implements OnInit {
  id: string;
  webinar: Webinar;
  name: string;
  designation: string;
  email: string;
  socialProfile: string;
  mobile: string;
  logo: string;
  about: string;
  speakers: Speaker[];
  profileUrl: string;

  webinarMapping: WebinarsUsersMapping;

  isDisabled = true;

  public myForm: FormGroup;
  $webinar: Observable<any>;

  file: any;
  localUrl: any;
  localCompressedURl: any;
  posterCompressedURL: any;
  organizerCompressedURL: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService,
    private imageCompress: NgxImageCompressService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.id = localStorage.getItem('webinar-id');
    //this.commService.webinarUserMapping=undefined;

    this.allEventService
      .getWebinarById(this.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.webinar = data;
        this.name = this.webinar.organizer.contactInfo.name;

        this.speakers = this.webinar.speakers;
      });
  }

  update() {
    this.isDisabled = false;
  }

  save() {
    try {
      this.allEventService.updateWebinar(this.webinar, this.id);
      this.allEventService.updateSpeaker(this.webinar);
      this.toast.success('Speaker details updated successfully');
    } catch (error) {}
    this.isDisabled = true;
  }

  cancel() {
    this.isDisabled = true;
  }

  selectFile(event: any, type, index) {
    this.file = event.target.files[0];
    const fileName = this.file['name'];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFile(this.localUrl, fileName, type, index);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image, fileName, type, index) {
    const orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    //console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(image, orientation, 100, 60)
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        //console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        // create file from byte
        //console.log(this.localCompressedURl);
        //console.log(this.myForm.value);
        this.webinar.speakers[index].contactInfo.logo = this.localCompressedURl;

        return this.localCompressedURl;
      });
  }
}
