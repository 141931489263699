import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from './../../../environments/environment.prod';
import { AngularFireModule } from '@angular/fire';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,

    NgxAuthFirebaseUIModule.forRoot(
      environment.firebaseConfig,
      () => 'webiknowr',
      {
        enableFirestoreSync: true, // enable/disable autosync users with firestore
        toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: false // whether to open/show a snackbar message on auth error - default : true
      }
    )
  ],
  exports: [
    AngularFireModule,
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgxAuthFirebaseUIModule
  ]
})
export class FirebaseModule {}
