import { AllEventService } from './../../events/all-events/all-event.service';
import { User } from './../../interfaces/user.model';
import { AdminService } from './../admin.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  $users: Observable<User[]>;
  webinarList: WebinarsUsersMapping[];
  list1: string[] = [];
  constructor(
    private adminService: AdminService,
    private allEventsService: AllEventService
  ) {}

  ngOnInit(): void {
    this.$users = this.adminService.getAllUsers();
    this.allEventsService.getAllWebinarsUsersMapping().subscribe((data) => {
      this.webinarList = data;
    });
  }

  findWebinars(userName) {
    this.list1 = [];
    this.webinarList.forEach((data) => {
      const sList = data.userId;
      sList.forEach((i) => {
        if (i.displayName === userName) {
          this.list1.push('https://webiknowr.com/webinar/' + data.webinarId);
        }
      });
    });

    return this.list1;
  }
}
