<div class="container">

    <div class="row">
        <div class="col-md-8">
            <div class="summary-title">



                <span>Summary</span><button (click)="openDialog()" style="margin-left: 30px" mat-button color="primary"><mat-icon>edit</mat-icon>Edit</button>
            </div>
        </div>
    </div>
    <br><br>
    <div class="row">

        <div class="col-md-7">

            <span style="font-size: 25px"> {{webinar.webinarInfo.title}}</span>
            <br>
            <br>
            <span
                *ngIf="webinar.schedule.startDate==webinar.schedule.endDate">{{webinar.schedule.startDate | date : "EEEE dd MMMM"}}</span>
            <span
                *ngIf="webinar.schedule.startDate!=webinar.schedule.endDate">{{webinar.schedule.startDate | date : "EEEE dd MMMM"}}
                -
                {{webinar.schedule.endDate | date :"EEEE dd MMMM"}}</span>
            <span> | </span>
            {{webinar.schedule.startTime}} - {{webinar.schedule.endTime}}

            <br>
            <br>
            <span>{{webinar.category[0].name}}</span> |  <span>{{webinar.industry[0].name}}</span>
            <br>
            <br>
            <span *ngIf="webinar.feeInfo.paid=='false'">Free</span>
            <span *ngIf="webinar.feeInfo.paid=='true'">{{webinar.feeInfo.feeInINR | currency:'INR'}}</span>

            <br>
            <br>
            <span style="font-weight: 600">Registration Link : </span> <span>{{webinar.webinarInfo.formLink}}</span>
            <div class="copy_url">


                <input type="text" style="width: 70%;   " disabled [(ngModel)]="'https://webiknowr.com/webinar/'+id"><button
                    class="btn"  (click)="copyLink()" [cdkCopyToClipboard]="'https://webiknowr.com/webinar/'+id">Copy
                    Link</button>

                   
            </div>
            <div *ngIf="copyFlag">
                    
                <span style="color: green;font-family: 'Nunito'">Link copied to clipboard</span>
            </div>


            <div *ngIf="expiredFlag">

                <p style="color: red">This event is expired. But, you can add embedded recording link. 
                    We will show the recording of your webinar on our website.</p>
                
                <p style="color: red">Note: only embedded links should be entered.</p>
                    
                    Example: https://www.youtube.com/embed/0K0yTDH37Mk
            
                <div class="copy_url">


                    <input type="text" [disabled]="recInputFlag" placeholder="Enter youtube embedded video link"style="width: 70%;"  [(ngModel)]="recordingLink">
                    <button class="btn" [disabled]="recordingLink.length==0 || recInputFlag" (click)="addRecordingLink()">Add Recording Link</button>
    
                      
                </div>
                <div *ngIf="recInputFlag" style="color: darkgreen">
                    Recoding Link is added and will be shown in the webinar page
               </div> 
               <div  style="color: red;font-weight: bold">
                {{errorMessage}}
           </div> 
               
               
            </div>
            <!--
            <div class="status-price-category-card-row">

                <mat-card style="color: green" class="mat-elevation-z8">
                        <span class="status-card">{{webinar.status}}</span>
                </mat-card>
                <mat-card  style="color: darkgreen"  class="mat-elevation-z8">
                    
                        <span *ngIf="webinar.feeInfo.paid=='false'">Free</span>
                        <span *ngIf="webinar.feeInfo.paid=='true'">{{webinar.feeInfo.feeInINR | currency:'INR'}}</span>
                </mat-card>
               
            </div>
            -->
        </div>
        
        <div class="col-md-4 col-md-offset-1">
                <p style="text-align: center;margin-bottom: -18px;"><button (click)="poster.click()"  color="primary" mat-button><mat-icon>photo</mat-icon>
                    <span *ngIf="webinar.webinarInfo.posterUrl">change poster</span>
                    <span *ngIf="!webinar.webinarInfo.posterUrl">upload poster</span>
                </button></p>

          
                                            
<input style="opacity: 0" #poster type="file" accept=".jpg,.png,.jpeg" id="poster-input" (change)="selectFile($event,'poster')" >
                        
                    
             


                
            <mat-card class="upload-card">

                       
                        <img *ngIf="webinar.webinarInfo.posterUrl"   mat-card-image style="width: calc(100% + 32px) !important;" src="{{webinar.webinarInfo.posterUrl}}" alt="no-image">
                        <img *ngIf="!webinar.webinarInfo.posterUrl"   mat-card-image style="width: calc(100% + 32px) !important;" src="../../../../../assets/img-alt.jpg" alt="no-image">
              
            </mat-card>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            
            <br>
            <span style="font-size: 14px"><pre>{{webinar.webinarInfo.description}}</pre></span>
        </div>
    </div>

</div>