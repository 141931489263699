import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AllEventService } from './../../events/all-events/all-event.service';
import { Component, OnInit } from '@angular/core';
import { WebinarsUsersMapping } from 'src/app/interfaces/webinarsUsersMapping.module';
import { Router } from '@angular/router';
import { CommunicationServiceService } from 'src/app/service/communication-service.service';

@Component({
  selector: 'app-admin-webinars',
  templateUrl: './admin-webinars.component.html',
  styleUrls: ['./admin-webinars.component.css']
})
export class AdminWebinarsComponent implements OnInit {
  webinarUsersMappingList: WebinarsUsersMapping[];
  constructor(
    private allEventsService: AllEventService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private commService: CommunicationServiceService
  ) {}

  ngOnInit(): void {
    this.ngxService.startBackground();
    this.allEventsService.getAllWebinarsUsersMapping().subscribe((data) => {
      this.webinarUsersMappingList = data;
      this.ngxService.stopBackground();
      //console.log(this.webinarUsersMappingList);
    });
  }

  routeToSummary(webinar) {
    this.commService.webinarUserMapping = webinar;
    localStorage.setItem('webinar-id', webinar.webinarId);
    this.router.navigate(['user/dashboard/host/summary']);
  }

  delete(webinar) {
    if (confirm('Do you really want to delete this webinar?')) {
      // console.log('delete');
      this.allEventsService.deleteWebinar(webinar);
    }
  }
}
