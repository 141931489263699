import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Category } from 'src/app/interfaces/Category.model';
import { CategoryService } from './../../service/category.service';

@Component({
  selector: 'app-admin-category',
  templateUrl: './admin-category.component.html',
  styleUrls: ['./admin-category.component.css']
})
export class AdminCategoryComponent implements OnInit {
  $categories: Observable<Category[]>;
  name: string;
  image: string;
  categories: Category[];
  constructor(
    private categoryService: CategoryService,
    private toasts: ToastrService
  ) {}

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe((data) => {
      this.categories = data;
    });
  }

  updateCategory(category) {
    /* let category={
      name:this.name,
      img:this.image
    }*/

    try {
      this.categoryService.updateCategory(category.id, category);
      this.toasts.success('category updated successfully');
    } catch (error) {
      this.toasts.error('error in updating', error);
    }
  }
  deleteCategory(id) {
    try {
      this.categoryService.deleteCategory(id);
      this.toasts.success('category deleted successfully');
    } catch (error) {
      this.toasts.error('error in deleting', error);
    }
  }

  addCategory() {
    const category = {
      name: this.name,
      img: this.image
    };
    try {
      this.categoryService.addCategory(category);
      this.toasts.success('category added successfully');
    } catch (error) {
      this.toasts.error('error in adding', error);
    }
  }
}
