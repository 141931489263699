<div class="dashboard-card">

        <mat-card class="mat-elevation-z4">
                <span style="font-size: 20px;
                margin-bottom: 25px;">Total Webinars Submitted</span>
                <br>
                <br>
                <span style="font-size: 40px;
                ">{{this.totalWebinars}}</span>
            </mat-card>

            <mat-card class="mat-elevation-z4">
                    <span style="font-size: 20px;
                margin-bottom: 25px;">Total Users Registered</span>
                <br>
                <br>
                <span style="font-size: 40px;
                ">{{this.totalRegistered}}</span>
                </mat-card>

 </div>