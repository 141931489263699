import { AngularmaterialModule } from './../common/angularmaterial/angularmaterial.module';
import { UserProfileFeaturedComponent } from './user-profile-featured/user-profile-featured.component';
import { UserProfileAboutComponent } from './user-profile-about/user-profile-about.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileEventsComponent } from './user-profile-events/user-profile-events.component';
import { UserProfileAnswersComponent } from './user-profile-answers/user-profile-answers.component';
import { UserProfileInsightsComponent } from './user-profile-insights/user-profile-insights.component';
import { UserProfileTestimonialsComponent } from './user-profile-testimonials/user-profile-testimonials.component';
import { UserProfileCommunityComponent } from './user-profile-community/user-profile-community.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    UserProfileAboutComponent,
    UserProfileEventsComponent,
    UserProfileAnswersComponent,
    UserProfileInsightsComponent,
    UserProfileTestimonialsComponent,
    UserProfileCommunityComponent,
    UserProfileFeaturedComponent
  ],
  imports: [CommonModule, MatCardModule, MatDividerModule],
  exports: [
    UserProfileAboutComponent,
    UserProfileEventsComponent,
    UserProfileAnswersComponent,
    UserProfileInsightsComponent,
    UserProfileTestimonialsComponent,
    UserProfileCommunityComponent,
    UserProfileFeaturedComponent
  ]
})
export class UserprofileModule {}
