<div style="margin-bottom: 84px;">
<div class="jumbotron">






    <div *ngIf="authService.user$ | async as user" class="container host">
        <h1 style="text-align: center; font-family: 'Nunito';">Hi {{user.displayName}}, Fill this form below to post a webinar</h1>
        
    <!--<button (click)="openDialog('asfasf')">open</button>-->
    </div>


</div>


<!-- <div class="jumbotron jumbotron-fluid">
        <div class="container host"> -->
<div class="container" >
    <div class="col-md-12   text-center">

        <form [formGroup]="myForm" (ngSubmit)="submitForm()">
         <!--   <mat-button-toggle-group  appearance="legacy" #group="matButtonToggleGroup" [value]="selectedVal"
                (change)="onValChange(group.value)">
                <mat-button-toggle class="mat-elevation-z4" value="1" aria-label="Text align left">
                    Live Webinar
                </mat-button-toggle>
                <mat-button-toggle class="mat-elevation-z4" value="2" aria-label="Text align center">
                    Recorded
                </mat-button-toggle>

            </mat-button-toggle-group>-->
            <!-- <div class="example-selected-value">Selected value: {{group.value}}</div> -->
            <mat-horizontal-stepper  labelPosition="bottom" [linear]="true">



                <mat-step [stepControl]="myForm.get('webinarInfo')['controls'].title && myForm.get('webinarInfo')['controls'].formLink"  >
                   
                    <mat-card class="mat-elevation-z4">
                        <ng-template matStepLabel>Basic Info</ng-template>
                        <h1 style="text-align: center; font-family: 'Nunito';">Event Overview</h1>
                        <mat-card-content>
                                <div formGroupName="webinarInfo">
                                    <div  >
                                        <div >
                                                <mat-form-field appearance="outline" style="width: 100%">
                                                        <mat-label>Name of the Event</mat-label>
                                                        <input required matInput placeholder="Name" formControlName="title">
                                                        <mat-icon matSuffix>text_rotation_none</mat-icon>
                                                        <mat-hint>Give it a short, distinct name</mat-hint>
                                                        <!-- error -->
                                                        <mat-error *ngIf="myForm.get('webinarInfo')['controls'].title.touched && myForm.get('webinarInfo')['controls'].title.invalid">
                                                            <span *ngIf="myForm.get('webinarInfo')['controls'].title.errors.required">Event Name is
                                                                mandatory.</span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" style="width: 100%">
                                                        <mat-label>About the Event</mat-label>
                                                        <textarea formControlName="description" matInput placeholder="Enter Event Description"
                                                            rows="8" cols="50"></textarea>
                                                        <mat-icon matSuffix>event_note</mat-icon>
                                                        <mat-hint>Agenda, key takeaways, etc.</mat-hint>
                                                        <!-- error 
                                                    <mat-error
                                                        *ngIf="myForm.controls.description.touched && myForm.controls.description.invalid">
                                                        <span *ngIf="myForm.controls.description.errors.required">Webinar Name is
                                                            mandatory.</span>
                                                        <span *ngIf="myForm.controls.description.errors.minLength">Please enter some more
                                                            information.</span>
                                                    </mat-error>-->
                                                    </mat-form-field>
                                        </div>
                                    </div>
                         



                            <div >
                                <!--*ngIf="selectedVal=='1'"-->

                                <mat-form-field appearance="outline">
                                    <mat-label>Event Registration Link</mat-label>
                                    <input required matInput formControlName="formLink"
                                        placeholder="Enter webinar registration link">
                                    <mat-icon matSuffix>link</mat-icon>
                                    <mat-hint>link to register for the event</mat-hint>
                                    <!-- error -->
                                    <mat-error
                                        *ngIf="myForm.get('webinarInfo')['controls'].formLink.touched && myForm.get('webinarInfo')['controls'].formLink.invalid">
                                        <span *ngIf="myForm.get('webinarInfo')['controls'].formLink.errors.required">Event registration link
                                            is
                                            mandatory.</span>
                                    </mat-error>
                                </mat-form-field>
                     
                            </div>

                           
                            


           <!--   <div >
                            <mat-form-field appearance="outline" style="width: 50%;">
                                <mat-label>Poster Link</mat-label>
                                <input matInput formControlName="posterUrl" placeholder="Insert poster link or upload">
                                <input style="display: none;" type="file" (change)="fileChangeEvent($event)" #fileInput>


                                <mat-icon matSuffix>add_a_photo</mat-icon>
                                <mat-hint>Insert poster link or upload</mat-hint>
                               
                            </mat-form-field>
                            
                            OR
                           
                            
                            <button type="button" mat-raised-button color="primary" matSuffix
                                (click)="fileInput.click()">
                                <mat-icon>
                                    cloud_upload
                                </mat-icon>&nbsp;
                                upload poster
                            </button>
                        </div> 

                            
                            <a class="image">{{selectedFileName}}
                                <img [src]="url">
                            </a>
                        -->
                        <div style="margin-top: 15px">
                                            
                            <p style="text-align: center;font-family: 'nunito'">Upload poster (only JPEG image supported)</p><input type="file" accept=".jpg,.jpeg" id="poster-input" (change)="selectFile($event,'poster',0)" >
                            
                            <br><br>
                            <p *ngIf="posterUploadError" style="color: red">Image not supported. Please select image with .JPEG or .JPG extension.</p>
                            <img [src]="posterCompressedURL" class="poster-css" alt="no-image">
                          </div>

                                
                            </div>
                              <!--  <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved">Remove</a>-->
                            <!--
                              <div *ngIf="selectedVal=='2'">
                                    <div formGroupName="recordingInfo">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Recording Link</mat-label>
                                        <input required matInput formControlName="recordingLink"
                                            placeholder="Enter webinar recording link">
                                        <mat-icon matSuffix>link</mat-icon>
                                        <mat-hint>embedded webinar recording link</mat-hint>
                                      
                                        <mat-error
                                            *ngIf="myForm.get('recordingInfo')['controls'].recordingLink.touched && myForm.get('recordingInfo')['controls'].recordingLink.invalid">
                                            <span *ngIf="myForm.get('recordingInfo')['controls'].recordingLink.errors.required">Event recording link
                                                is
                                                mandatory.</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Duration</mat-label>
                                        <input required matInput formControlName="duration"
                                            placeholder="Enter webinar recording duration">
                                        <mat-icon matSuffix>link</mat-icon>
                                        <mat-hint>enter duration in minutes</mat-hint>
                                      
                                        <mat-error
                                            *ngIf="myForm.get('recordingInfo')['controls'].duration.touched && myForm.get('recordingInfo')['controls'].duration.invalid">
                                            <span *ngIf="myForm.get('recordingInfo')['controls'].duration.errors.required">Event recording duration
                                                is
                                                mandatory.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                         
                                
                                </div>-->
                        </mat-card-content>


                    </mat-card>
                
                    <br>
                   
                    <p style="text-align: center;margin-top: 10px">
                 <button mat-raised-button color="primary" matStepperNext type="button">Next<mat-icon style="    font-size: 22px;">arrow_forward</mat-icon></button>
                    </p> 
                </mat-step>
                <mat-step [stepControl]="myForm.get('organizer').get('contactInfo')['controls'].name && myForm.get('organizer').get('contactInfo')['controls'].email">
                        <div formGroupName="organizer">
                    <div formGroupName="contactInfo">

                        <mat-card class="mat-elevation-z4">
                            <ng-template matStepLabel>Organizer Info</ng-template>

                            <div>
                                <h1 style="text-align: center; font-family: 'Nunito';">Organizer Info</h1>
                                <mat-card-content>
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Name of the Organizer</mat-label>
                                        <input required matInput placeholder="Organizer Name" formControlName="name" (keyup)="onKeyOrganizer($event.target.value)" [matAutocomplete]="auto1">
                                        <mat-icon matSuffix>person</mat-icon>

                                        <mat-autocomplete #auto1 = "matAutocomplete">
                                                <mat-option (click)="setOrganizer(s)" *ngFor = "let s of organizerFilteredArray " [value] = "s.name">
                                                        <img *ngIf="s.logo" style="vertical-align:middle;margin-right: 10px;" aria-hidden src="{{s.logo}}" height="25" alt="no-image"/>
                                                        <span>{{s.name}}</span>
                                                </mat-option>
                                         </mat-autocomplete>
                                        <!-- error -->
                                        <mat-error
                                            *ngIf="myForm.get('organizer').get('contactInfo')['controls'].name.touched && myForm.get('organizer').get('contactInfo')['controls'].name.invalid">
                                            <span
                                                *ngIf="myForm.get('organizer').get('contactInfo')['controls'].name.errors.required">Organizer
                                                Name is
                                                mandatory.</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" style="width: 100%">
                                            <mat-label>About the organizer</mat-label>
                                            <textarea formControlName="about" matInput placeholder="About the Organizer"
                                                rows="8" cols="50"></textarea>
                                            <mat-icon matSuffix>event_note</mat-icon>
                                            <mat-hint>Agenda, key takeaways, etc.</mat-hint>
                                            <!-- error 
                                        <mat-error
                                            *ngIf="myForm.controls.description.touched && myForm.controls.description.invalid">
                                            <span *ngIf="myForm.controls.description.errors.required">Webinar Name is
                                                mandatory.</span>
                                            <span *ngIf="myForm.controls.description.errors.minLength">Please enter some more
                                                information.</span>
                                        </mat-error>-->
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Social Page/Website of the Organizer</mat-label>
                                                <input matInput placeholder="Profile URL/website" formControlName="profileUrl">
                                                <mat-icon matSuffix>language</mat-icon>
        
        
                                            </mat-form-field>
                                            <!--
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Logo/display picture of the organizer</mat-label>
                                                <input matInput placeholder="logo/display picture URL" formControlName="logo">
                                                <mat-icon matSuffix>add_a_photo</mat-icon>
                                                <mat-hint>paste the Image url in jpeg, png, svg format</mat-hint>
    
                                            </mat-form-field>
                                        -->

                                        <div>
                                            
                                            <p style="text-align: center;font-family: 'nunito'">Upload logo (only JPEG image supported)</p><input type="file" accept=".jpg,.jpeg" id="image-input" (change)="selectFile($event,'organizer',0)" #fileInput>
                                            
                                            <br><br>
                                            <p *ngIf="organizerUploadError" style="color: red">Image not supported. Please select image with .JPEG or .JPG extension.</p>
                                            <img [src]="organizerCompressedURL" style="width: 100px" alt="no-image">
                                          </div>
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Email Address</mat-label>
                                        <input required type="email" matInput placeholder="Organizer Email"
                                            formControlName="email">
                                        <mat-icon matSuffix>email</mat-icon>
                                        <mat-error
                                        *ngIf="myForm.get('organizer').get('contactInfo')['controls'].email.touched && myForm.get('organizer').get('contactInfo')['controls'].email.invalid">
                                        <span
                                            *ngIf="myForm.get('organizer').get('contactInfo')['controls'].email.errors.required">Organizer
                                            Email is
                                            mandatory.</span>
                                            <span *ngIf="myForm.get('organizer').get('contactInfo')['controls'].email.errors.email">Please, enter valid email address.</span>
                                    </mat-error>


                                    </mat-form-field>
                                    
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Mobile Number</mat-label>
                                        <input matInput placeholder="Mobile Number" type="tel" formControlName="mobileNo">
                                        <mat-icon matSuffix>call</mat-icon>
                                        <mat-error *ngIf="myForm.get('organizer').get('contactInfo')['controls'].mobileNo.touched && myForm.get('organizer').get('contactInfo')['controls'].mobileNo.invalid">
                                                <span *ngIf="myForm.get('organizer').get('contactInfo')['controls'].mobileNo.errors.pattern">Only digits Allowed</span>
                                                
                                                <span *ngIf="myForm.get('organizer').get('contactInfo')['controls'].mobileNo.errors.minlength || myForm.get('organizer').get('contactInfo')['controls'].mobileNo.errors.maxlength">Mobile Number should be of 10 digits</span>
                                        </mat-error>

                                    </mat-form-field>
                                </mat-card-content>

                            </div>




                        </mat-card>
                        
                    </div>
                    </div>

                    <br>
                    <p style="text-align: center;margin-top: 10px">
                            <button mat-raised-button color="primary" matStepperPrevious type="button"><mat-icon style="    font-size: 22px;">arrow_back</mat-icon>Back</button>
                            <button style="margin-left: 40px" mat-raised-button color="primary" matStepperNext type="button">Next<mat-icon style="    font-size: 22px;">arrow_forward</mat-icon></button>
                     </p> 
                </mat-step>
                <!--*ngIf="selectedVal=='1'"-->
                <mat-step  [stepControl]="myForm.get('schedule')">
                        <div formGroupName="schedule">
                            <mat-card class="mat-elevation-z4">
                                <ng-template matStepLabel>Schedule Info</ng-template>
    
                                <h1 style="text-align: center; font-family: 'Nunito';">Event Date and Time</h1>
                             
                             <!--   <div style="  height: 60px;">
                                        <ng-moment-timezone-picker (onselect)="setTimezone($event)" 
                                        [customPlaceholderText]="'select timezone'" [setZone]="'Asia/Kolkata'">
                                        
                                    </ng-moment-timezone-picker>
                                </div>

                                <div>
                                        
                                        <mat-form-field appearance="outline">
                                                <mat-label>Select Timezone</mat-label>
        
                                                <mat-select formControlName="timeZone" >
                                                    <mat-option [value]="list.zone" *ngFor="let list of timeZones">
                                                        {{list.zone}} - {{list.desc}}
                                                    </mat-option>
                                                </mat-select>
                                          
                                            </mat-form-field>
                                </div>
                                -->

                                <mat-card-content>
                                  
                                    
                                    <diV fxLayout="row wrap" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">
                                        <mat-form-field appearance="outline">
    
                                            <mat-label>Start date</mat-label>
                                            <input formControlName="startDate" matInput [matDatepickerFilter]="dateFilter"
                                                [matDatepicker]="picker1" placeholder="Choose a date"
                                                (dateChange)="date($event,'startDate')">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                            <mat-hint>mm/dd/yyyy</mat-hint>
                                            <mat-error
                                                *ngIf="myForm.get('schedule')['controls'].startDate.touched && myForm.get('schedule')['controls'].startDate.invalid">
                                                <span *ngIf="myForm.get('schedule')['controls'].startDate.errors.required">Start date
                                                    is
                                                    mandatory.</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
    
                                            <mat-label>End date</mat-label>
                                            <input formControlName="endDate" matInput [matDatepickerFilter]="endDateFilter"
                                                [matDatepicker]="picker2" placeholder="Choose a date"
                                                (dateChange)="date($event,'endDate')">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <mat-hint>mm/dd/yyyy</mat-hint>
                                            <mat-error
                                                *ngIf="myForm.get('schedule')['controls'].endDate.touched && myForm.get('schedule')['controls'].endDate.invalid">
                                                <span *ngIf="myForm.get('schedule')['controls'].endDate.errors.required">End
                                                    date is
                                                    mandatory.</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </diV>
                                    <diV fxLayout="row wrap" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Start Time</mat-label>
    
                                            <mat-select formControlName="startTime">
                                                <mat-option [value]="time" *ngFor="let time of startTimeArray"
                                                    (click)="changeEndTimeArray(time)">{{time}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="myForm.get('schedule')['controls'].startTime.touched && myForm.get('schedule')['controls'].startTime.invalid">
                                                <span
                                                    *ngIf="myForm.get('schedule')['controls'].startTime.errors.required">Start
                                                    time is
                                                    mandatory.</span>
                                            </mat-error>
    
    
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>End Time</mat-label>
    
                                            <mat-select formControlName="endTime">
                                                <mat-option [value]="time" *ngFor="let time of endTimeArray"
                                                    >{{time}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="myForm.get('schedule')['controls'].endTime.touched && myForm.get('schedule')['controls'].endTime.invalid">
                                                <span *ngIf="myForm.get('schedule')['controls'].endTime.errors.required">End
                                                    time is
                                                    mandatory.</span>
                                            </mat-error>
    
    
                                        </mat-form-field>
                                    </diV>
                                   
    
                                </mat-card-content>
    
    
    
                            </mat-card>
                            <br>
                            <p style="text-align: center;margin-top: 10px">
                                    <button mat-raised-button color="primary" matStepperPrevious type="button"><mat-icon style="    font-size: 22px;">arrow_back</mat-icon>Back</button>
                                    <button style="margin-left: 40px" mat-raised-button color="primary" matStepperNext type="button">Next<mat-icon style="    font-size: 22px;">arrow_forward</mat-icon></button>
                             </p> 
                        </div>
                    </mat-step>
                <mat-step [stepControl]="myForm.get('speakers')['controls'][0]['controls']['contactInfo']['controls']['name'] && myForm.get('speakers')['controls'][0]['controls']['contactInfo']['controls']['designation'] 
                && myForm.get('speakers')['controls'][0]['controls']['contactInfo']['controls']['profileUrl']">
                    <div formArrayName="speakers"
                        *ngFor="let speaker of myForm.get('speakers')['controls']; let i = index; let l = last; let f = first;">
                    <div [formGroupName]="i" >
                        <mat-card class="mat-elevation-z4">
                            <ng-template matStepLabel>Speakers Info</ng-template>
                            <button type="button" *ngIf="!f" mat-icon-button class="float-right"
                                (click)="removeSpeaker(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <div formGroupName="contactInfo">
                                <h1 style="text-align: center; font-family: 'Nunito';">Speaker {{i+1}}</h1>
                                <mat-card-content>
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Speaker's Name</mat-label>
                                        <input required matInput placeholder="Speakers" formControlName="name" (keyup)="onKeySpeaker($event.target.value)" [matAutocomplete] = "auto2">
                                        <mat-icon matSuffix>person</mat-icon>
                                        <mat-autocomplete #auto2 = "matAutocomplete">
                                                <mat-option (click)="setSpeaker(o,i)" *ngFor = "let o of speakersFilteredArray " [value] = "o.name">
                                                  
                                                   <img *ngIf="o.logo" style="vertical-align:middle;margin-right: 10px;" aria-hidden src="{{o.logo}}" height="25" alt="no-image" />
                                                   <span> <b>{{o.name}}</b> - {{o.designation}}</span>
                                                </mat-option>
                                         </mat-autocomplete>
                                        
                                        <!-- <mat-error *ngIf="myForm.controls.speakers.touched && myForm.controls.speakers.invalid">
                                            <span *ngIf="myForm.controls.speakers.errors.required">At least one speaker Name is
                                                mandatory.</span>
                                        </mat-error> -->
                                    </mat-form-field>
                                   <mat-form-field appearance="outline" style="width: 100%">
                                            <mat-label>Speaker's Designation</mat-label>
                                            <input  matInput placeholder="Speakers" formControlName="designation">
                                            <mat-icon matSuffix>assignment_ind</mat-icon>
    
                                            <!--
                                             <mat-error *ngIf="myForm.controls.speakers.touched && myForm.controls.speakers.invalid">
                                                <span *ngIf="myForm.controls.speakers.errors.required">At least one speaker Name is
                                                    mandatory.</span>
                                            </mat-error> -->
                                        </mat-form-field>
                                   
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Speaker's Social Profile/website</mat-label>
                                        <input required matInput placeholder="LinkdIn Profile" formControlName="profileUrl">
                                        <mat-icon matSuffix>language</mat-icon>


                                    </mat-form-field>

                                    <mat-form-field appearance="outline" style="width: 100%">
                                            <mat-label>About speaker</mat-label>
                                            <textarea  matInput placeholder="about the speaker" formControlName="about"  rows="5" cols="50">
                                            </textarea>
                                            <mat-icon matSuffix>event_note</mat-icon>
    
    
                                     </mat-form-field>
                

                                    <div>
                                            
                                            <p style="text-align: center;font-family: 'nunito'">Upload photo (only JPEG image supported)</p><input type="file" accept=".jpg,.jpeg" id="image-input" (change)="selectFile($event,'speaker',i)" #fileInput>
                                            
                                            <br><br>
                                            <p *ngIf="speakerUploadError" style="color: red">Image not supported. Please select image with .JPEG or .JPG extension.</p>
                                            <img [src]="getSpeakerPhoto(i)" style="width: 100px" alt="no-image">
                                          </div>
                                    
                                </mat-card-content>

                            </div>
                            <button type="button" *ngIf="l" mat-button color="primary" (click)="addSpeaker()">
                                <mat-icon>add</mat-icon>Add
                                Speaker
                            </button>



                        </mat-card>
                    </div>
                        <br>
                    </div>
                    <p style="text-align: center;margin-top: 10px">
                            <button mat-raised-button color="primary" matStepperPrevious type="button"><mat-icon style="    font-size: 22px;">arrow_back</mat-icon>Back</button>
                            <button style="margin-left: 40px" mat-raised-button color="primary" matStepperNext type="button">Next<mat-icon style="    font-size: 22px;">arrow_forward</mat-icon></button>
                     </p> 


                </mat-step>

                <mat-step >
                    <!-- <div class="row"> -->
                    <ng-template matStepLabel>Other Info</ng-template>
                    <mat-card>

                        <h1 style="text-align: center; font-family: 'Nunito';">Other Info</h1>

                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-6">
                                   
                                <div formArrayName="category" *ngFor="let x of myForm.get('category')['controls'];let i = index">

                                        <div [formGroupName]="i">
                               
                                    <mat-form-field appearance="outline">
                                        <mat-label>Category</mat-label>

                                        <mat-select formControlName="name" >
                                            <mat-option [value]="category.name" *ngFor="let category of categories">
                                                {{category.name}}
                                            </mat-option>
                                        </mat-select>
                                    <!--
                                        <mat-error *ngIf="myForm.controls.category.touched && myForm.controls.category.invalid">
                                                <span *ngIf="myForm.controls.category.errors.required">Please select category</span>
                                        </mat-error>-->
                                    </mat-form-field>
                                </div>
                                </div>
                                </div>
                                <div class="col-md-6">
                                        <div formArrayName="industry" *ngFor="let x of myForm.get('industry')['controls'];let i = index">

                                                <div [formGroupName]="i">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Industry</mat-label>
                                        
                                            <mat-select formControlName="name">
                                            <mat-option [value]="industry.name" *ngFor="let industry of industries">
                                                {{industry.name}}
                                            </mat-option>
                                        </mat-select>
                                        
                                        <!--
                                        <mat-error *ngIf="myForm.controls.industry.touched && myForm.controls.industry.invalid">
                                                <span *ngIf="myForm.controls.industry.errors.required">Please select category</span>
                                        </mat-error>-->
                                    </mat-form-field>
                                   </div>
                                   </div>
                                </div>
                            </div>
                            <mat-form-field appearance="outline">
                                <mat-label>Tags</mat-label>
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let tag of tagsArray" [selectable]="selectable"
                                        [removable]="removable" (removed)="remove(tag)">
                                        {{tag.name}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>

                                    <input placeholder="Add tags" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                                <mat-hint>press enter to add tags</mat-hint>
                            </mat-form-field>

                            <div formGroupName="feeInfo" style="    margin-bottom: 11px;">
                             <span style="font-family: 'nunito';margin-right: 5px">Webinar Price : </span>   
                            <mat-radio-group class="radio" formControlName="paid">
                                <mat-radio-button value=false>Free</mat-radio-button>
                                <mat-radio-button #fees value=true>Paid</mat-radio-button>

                                <mat-error *ngIf="myForm.get('feeInfo')['controls'].paid.touched && myForm.get('feeInfo')['controls'].paid.invalid">
                                        <span *ngIf="myForm.get('feeInfo')['controls'].paid.errors.required">Please select Price</span>
                                    </mat-error>
                            </mat-radio-group>
                           
                            <mat-form-field *ngIf="myForm.get('feeInfo')['controls'].paid.value=='true'" appearance="outline">
                                <mat-label>Webinar Price</mat-label>
                                <input required matInput formControlName="feeInINR" placeholder="Enter webinar Price">
                                <mat-icon matSuffix>money</mat-icon>
                                <mat-hint>Enter in Rupees</mat-hint>
                                <!-- error -->
                                <mat-error *ngIf="myForm.get('feeInfo')['controls'].feeInINR.touched && myForm.get('feeInfo')['controls'].feeInINR.invalid">
                                        <span *ngIf="myForm.get('feeInfo')['controls'].feeInINR.errors.required">Please select Price</span>
                                        <span *ngIf="myForm.get('feeInfo')['controls'].feeInINR.errors.pattern">Please enter in digits</span>
                                    </mat-error>

                            </mat-form-field>
                        </div>
                        <div>
                       <span style="font-family: 'nunito';margin-right: 5px">E-Certificate provided :</span> 
                         <mat-radio-group  formControlName="certificate">
                                <mat-radio-button value=true>Yes</mat-radio-button>
                                <mat-radio-button #fees value=false>No</mat-radio-button>
                         </mat-radio-group>
                            
                        </div>
                        

                        </mat-card-content>



                    </mat-card>

                    <button  [disabled]="!(this.myForm.get('feeInfo')['controls'].paid.valid && (this.myForm.get('feeInfo')['controls'].paid.value=='false' || (this.myForm.get('feeInfo')['controls'].paid.value=='true' && this.myForm.get('feeInfo')['controls'].feeInINR.valid)))" style="margin-top: 30px;width: 100%" mat-raised-button color="primary"  type="submit"
                    
                   
                    >
                    <!-- [disabled]="!(this.myForm.controls.category.valid && this.myForm.controls.industry.valid && this.myForm.get('feeInfo')['controls'].paid.valid && (this.myForm.get('feeInfo')['controls'].paid.value=='false' || (this.myForm.get('feeInfo')['controls'].paid.value=='true' && this.myForm.get('feeInfo')['controls'].feeInINR.valid)))"-->
                        <mat-icon>add</mat-icon>
                        <mat-label>Add Event</mat-label>
                    </button>
<!---->
                    

                </mat-step>



            </mat-horizontal-stepper>
        </form>

    </div>


</div>
</div>
<!-- </div>
    </div> -->