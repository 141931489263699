<div class="jumbotron">
        <div class="container">
                <div class="row">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4">
                                <h1 style="text-align: center; font-family: 'Nunito';">Webinars on {{industryName}} Industry</h1>
                        </div>
                        <div class="col-lg-4">

                        </div>
                </div>
                <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-6">
                                <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Search</mat-label>
                                        <input matInput [(ngModel)]="searchText" placeholder="Search"
                                                autocomplete="off">

                                        <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                

                        </div>
                        <div class="col-lg-3"></div>


                </div>
        
        </div>

        <br>

    <mat-divider>

    </mat-divider>


<br>

<div *ngIf="webinars.length>0">
        <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
                <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%"
                        *ngFor="let x of webinars | filter : searchText" >
                        <mat-card class="mat-elevation-z4" >
                                <div class="container-card-img">
                                                <img *ngIf="x.webinarInfo.posterUrl" mat-card-image src="{{x.webinarInfo.posterUrl}}" alt="no-image" >
                                                <img *ngIf="!x.webinarInfo.posterUrl" mat-card-image src="../../../assets/img-alt.jpg" alt="no-image">
                                        
                                        <mat-chip class="action-button-category btn btn-primary top-left mat-elevation-z4" >{{x.category[0].name}}</mat-chip>
                                        <mat-chip class="action-button-price btn btn-primary top-right mat-elevation-z4"> 
                                                        <span *ngIf="x.feeInfo.paid=='false'">Free</span>
                                                        <span *ngIf="x.feeInfo.paid=='true'">{{x.feeInfo.feeInINR | currency:'INR'}}</span>
                                        </mat-chip>
                                       
                                        
                                 </div>
                        

                                <mat-card-content>
                                        <p  matTooltip="{{x.webinarInfo.title}}" matTooltipClass='red-tooltip'
                                                style="text-align: center; font-weight: 500; font-size: 1.2em ;">
                                               
                                                {{ (x.webinarInfo.title.length > 40) ? (x.webinarInfo.title | slice:0:40) + '...' : x.webinarInfo.title }}
                                        </p>
                                        <mat-divider></mat-divider>
                                        <br>
                                        <div>
                                                <div class="outer-div">
                                                        <mat-icon style="color: #792cc7">calendar_today</mat-icon>&nbsp;&nbsp;
                                                        <span
                                                                *ngIf="x.schedule.startDate==x.schedule.endDate">{{x.schedule.startDate | date : "EEEE dd MMMM"}}</span>
                                                        <span *ngIf="x.schedule.startDate!=x.schedule.endDate">{{x.schedule.startDate | date : "EEEE dd MMMM"}}
                                                                -
                                                                {{x.schedule.endDate | date :"EEEE dd MMMM"}}</span>
                                                </div>
                                                <br>
                                                <div class="outer-div">
                                                        <mat-icon style="color: #c94190">alarm</mat-icon>&nbsp;&nbsp;
                                                        <span>{{x.schedule.startTime}} - {{x.schedule.endTime}}</span>
                                                </div>
                                                <br>
                                                
                                        </div>
                                </mat-card-content>
                                <mat-card-actions>
                                       <!--<p style="text-align: center"><a mat-raised-button
                                                        color="primary" href="{{x.f}}"
                                                         [routerLink]="['/webinar', x.id]">Details</a></p>--> 

                                                  
          
                                                <button mat-icon-button style="color: #792cc7"  class="bottom-right-share"><mat-icon>share</mat-icon></button>
                                        
                                                <button *ngIf="!favoriteSelected" (click)="toggleFavorite()" mat-icon-button style="color: #c94190" class="bottom-right-like"><mat-icon>favorite_border</mat-icon></button>
                                                <button *ngIf="favoriteSelected" (click)="toggleFavorite()"  mat-icon-button style="color: red" class="bottom-right-like"><mat-icon>favorite</mat-icon></button>               
                                                        

                                </mat-card-actions>
                        </mat-card>
                </div>
        </div>

    </div>
    <div *ngIf="webinars.length==0" style="text-align: center; font-family: 'Nunito'">There are no webinars for this industry.</div>
</div>