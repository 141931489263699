import { Observable, Subscription } from 'rxjs';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { Organizer } from './../../interfaces/Organizer.model';
import { Speaker } from './../../interfaces/Speaker.model';
import { HostSuccessComponent } from './../../dialogs/host-success/host-success.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Industry } from './../../interfaces/Industry.model';
import { Category } from './../../interfaces/Category.model';
import { IndustryService } from './../../service/industry.service';
import { CategoryService } from './../../service/category.service';
import { AuthService } from './../../service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HostService } from './../host.service';
import { Webinar } from './../../interfaces/webinars.model';
import { AllEventService } from './../../events/all-events/all-event.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { formatDate } from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { HttpClient } from '@angular/common/http';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireUploadTask } from '@angular/fire/storage/task';
import { AngularFireStorageReference } from '@angular/fire/storage/ref';

// import { HttpClient } from '@angular/common/http';

export interface Tag {
  name: string;
  creationDate: Date;
}

/*
export interface Speaker {
  name: string;
  profileURL: string;
  email: string;
  mobile: number;
}

export interface Organizer {
  name: string;
  profileURL: string;
  email: string;
  mobile: string;
}

export interface Schedule {
  durationInMinutes: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}
*/
@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.css']
})
export class HostComponent implements OnInit, OnDestroy {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  downloadURL: Observable<string>;
  showMsg = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  @ViewChild('chipList', { static: true }) chipList;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  startTimeArray: any = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];
  endTimeArray: any = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];

  categoryArray: Category[] = [];
  industryArray: Industry[] = [];
  categories: Array<Category>;
  industries: Array<Industry>;
  tagsArray: Tag[] = [
    { name: '#webinar', creationDate: new Date() },
    { name: '#webiknowr', creationDate: new Date() }
  ];

  $speakers: Observable<Speaker[]>;
  $organizers: Observable<Organizer[]>;
  speakersArray: any[] = [];
  speakersFilteredArray: any[] = [];
  organizerArray: any[] = [];
  organizerFilteredArray: any[] = [];
  organizer: Organizer;
  submitFlag = false;
  durationInSeconds = 5;
  public speakers: FormArray;
  public myForm: FormGroup;
  selectedFile: File = null;
  selectedFileName = '';
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  public selectedVal: string; //for toggle button initial selection
  url = '';

  file: any;
  localUrl: any;
  localCompressedURl: any;
  posterCompressedURL: any;
  organizerCompressedURL: any;
  speakerCompressedURL: any;

  posterFile: any;
  organizerFile: any;
  speakerFile: any;

  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  subscriptions: Subscription[] = [];
  subscription1$: Subscription;
  subscription2$: Subscription;
  subscription3$: Subscription;
  constructor(
    private allEventService: AllEventService,
    private fb: FormBuilder,
    private hostService: HostService,
    private _snackBar: MatSnackBar,
    public authService: AuthService,
    private http: HttpClient,
    private categoryService: CategoryService,
    private industryService: IndustryService,
    private dialog: MatDialog,
    private commService: CommunicationServiceService,
    private ngxService: NgxUiLoaderService,
    private imageCompress: NgxImageCompressService,
    private toasts: ToastrService,
    private afStorage: AngularFireStorage
  ) {
    this.subscription1$ = this.categoryService
      .getCategories()
      .subscribe((data) => {
        //console.log(data);
        this.categories = data;
      });

    this.subscription2$ = this.industryService
      .getIndustries()
      .subscribe((data) => {
        // console.log(data);
        this.industries = data;
      });

    this.subscriptions.push(this.subscription1$);
    this.subscriptions.push(this.subscription2$);
    this.allEventService.getSpeakers().subscribe((data) => {
      this.speakersArray = data;
      this.speakersFilteredArray = this.speakersArray;
    });
    // console.log(this.$speakers);
    this.allEventService.getOrganizers().subscribe((data) => {
      this.organizerArray = data;
      this.organizerFilteredArray = this.organizerArray;
    });
  }

  onKeyOrganizer(value: string) {
    this.organizerFilteredArray = this.organizerArray;
    value = value.toLowerCase();
    this.organizerFilteredArray = this.organizerFilteredArray.filter((i) => {
      return i.name.toLowerCase().startsWith(value);
    });
  }

  onKeySpeaker(value: string) {
    this.speakersFilteredArray = this.speakersArray;
    value = value.toLowerCase();
    this.speakersFilteredArray = this.speakersFilteredArray.filter((i) => {
      return i.name.toLowerCase().startsWith(value);
    });
  }

  webinar: Webinar[];

  ngOnInit(): void {
    (this.myForm = this.fb.group({
      webinarInfo: this.fb.group({
        title: ['', [Validators.required]],
        description: [''],
        formLink: ['', [Validators.required]],
        posterUrl: ['']
      }),
      schedule: this.fb.group({
        startDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]],
        startTime: ['', [Validators.required]],
        endTime: ['', [Validators.required]],
        timeZone: ['']
      }),
      tags: [this.tagsArray, [Validators.required]],
      category: this.fb.array([
        this.fb.group({
          name: ['']
        })
      ]),
      industry: this.fb.array([
        this.fb.group({
          name: ['']
        })
      ]),
      speakers: this.fb.array([this.createSpeaker()]), //declaringspeares as formarray
      organizer: this.fb.group({
        contactInfo: this.fb.group({
          name: ['', [Validators.required]],
          profileUrl: [''],
          email: ['', [Validators.required, Validators.email]],
          mobileNo: [
            '',
            [
              Validators.minLength(10),
              Validators.maxLength(10),
              Validators.pattern('^[0-9]*$')
            ]
          ],
          about: [''],
          logo: ['']
        })
      }),

      feeInfo: this.fb.group({
        feeInINR: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        paid: [Boolean, [Validators.required]]
      }),
      recordingInfo: this.fb.group({
        recordingLink: [''],
        recorded: [''],
        duration: [0]
      }),
      certificate: ['']
    })),
      (this.selectedVal = '1');

    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;
  }

  public onValChange(val: string) {
    this.selectedVal = val;
  }

  blur($event) {
    // tslint:disable-next-line:no-console
  }

  get speakerControls() {
    return this.myForm.get('speakers')['controls'];
  }

  createSpeaker(): FormGroup {
    return this.fb.group({
      contactInfo: this.fb.group({
        name: ['', [Validators.required]],
        profileUrl: ['', [Validators.required]],
        email: [''],
        mobileNo: [''],
        designation: ['', [Validators.required]],
        about: [''],
        logo: ['']
      })
    });
  }

  createOrganizer(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      profileURL: [''],
      email: ['', [Validators.required]],
      mobile: [''],
      about: ['']
    });
  }

  createSchedule(): FormGroup {
    return this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
      extraInfo: ['', [Validators.required]]
    });
  }

  addSpeaker(): void {
    this.speakers = this.myForm.get('speakers') as FormArray;

    this.speakers.push(this.createSpeaker());
  }

  removeSpeaker(i: number) {
    this.speakers.removeAt(i);
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = this.selectedFile.name;
    if (event.target.files) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result as string;
      };
    }
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      this.selectedFileName = fileInput.target.files[0].name;
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          //console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            // console.log(imgBase64Path);
            this.url = imgBase64Path;
            this.cardImageBase64 = imgBase64Path;

            this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  changeEndTimeArray(time) {
    this.resetEndTimeArray();
    const list1 = this.startTimeArray;

    const i = this.startTimeArray.indexOf(time);

    this.endTimeArray.splice(0, i + 1);
  }

  resetEndTimeArray() {
    const timeArray = [
      '12:00 AM',
      '12:30 AM',
      '01:00 AM',
      '01:30 AM',
      '02:00 AM',
      '02:30 AM',
      '03:00 AM',
      '03:30 AM',
      '04:00 AM',
      '04:30 AM',
      '05:00 AM',
      '05:30 AM',
      '06:00 AM',
      '06:30 AM',
      '07:00 AM',
      '07:30 AM',
      '08:00 AM',
      '08:30 AM',
      '09:00 AM',
      '09:30 AM',
      '10:00 AM',
      '10:30 AM',
      '11:00 AM',
      '11:30 AM',
      '12:00 PM',
      '12:30 PM',
      '01:00 PM',
      '01:30 PM',
      '02:00 PM',
      '02:30 PM',
      '03:00 PM',
      '03:30 PM',
      '04:00 PM',
      '04:30 PM',
      '05:00 PM',
      '05:30 PM',
      '06:00 PM',
      '06:30 PM',
      '07:00 PM',
      '07:30 PM',
      '08:00 PM',
      '08:30 PM',
      '09:00 PM',
      '09:30 PM',
      '10:00 PM',
      '10:30 PM',
      '11:00 PM',
      '11:30 PM'
    ];
    //console.log('before reset', this.endTimeArray);
    // console.log('before reset', timeArray);
    this.endTimeArray = timeArray;
    //console.log('after reset', this.endTimeArray);
  }

  async submitForm() {
    //console.log(this.myForm.value);
    /*
    if(this.url){
      this.myForm.get('webinarInfo')['controls'].posterUrl.setValue(this.url);
    }
   */

    this.ngxService.start();
    const webid = await this.allEventService.createWebinar(this.myForm.value);

    this.ngxService.stop();

    if (webid == undefined) {
      this.toasts.error(
        'There is some technical error. Please check all form fields and try again'
      );
    } else {
      this.submitFlag = true;
      this.cardImageBase64 = null;
      this.selectedFileName = null;
      this.localCompressedURl = null;
      this.posterCompressedURL = null;
      this.organizerCompressedURL = null;

      this.myForm.reset();
      this.openDialog(webid);
    }

    /*const fd = new FormData;
    fd.append('file', this.selectedFile);
    this.http.post("https://file.io/", fd)
      .subscribe(res => {
        console.info(res);
        this.myForm.controls.img.setValue(res['link']);
        console.log(this.myForm.value);
        this.submitFlag = true;
        //this.openSnackBar();
        
      });


*/
  }

  date(e, dateType) {
    const momentDate = new Date(e.target.value); // Replace event.value with your date value
    const formattedDate = dayjs(momentDate).format('YYYY-MM-DD');

    //console.log(formattedDate);
    this.myForm.get('schedule')['controls'][dateType].setValue(formattedDate, {
      onlyself: true
    });

    if (dateType == 'startDate') {
      this.myForm
        .get('schedule')
        ['controls']['endDate'].setValue(formattedDate, {
          onlyself: true
        });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add tag
    const creationDate = new Date();
    if ((value || '').trim() && this.tagsArray.length < 5) {
      this.tagsArray.push({ name: value.trim(), creationDate: creationDate });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  /* Remove dynamic languages */
  remove(subject: Tag): void {
    const index = this.tagsArray.indexOf(subject);
    if (index >= 0) {
      this.tagsArray.splice(index, 1);
    }
  }

  dateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    // Prevent Saturday and Sunday from being selected.
    //console.log(this.myForm.get('schedule')['controls']['endDate'].value);

    return d > dt;
  };
  endDateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const dt = new Date();
    dt.setDate(dt.getDate() - 1);
    // Prevent Saturday and Sunday from being selected.
    const dtt = this.myForm.get('schedule')['controls']['startDate'].value;
    const momentDate = new Date(dtt);
    momentDate.setDate(momentDate.getDate() - 1);
    if (dtt != null && dtt != undefined && dtt != '') {
      return d > momentDate;
    }
    return d > dt;
  };

  async openDialog(webid: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '700px';
    dialogConfig.data = webid;

    dialogConfig.disableClose = true;

    this.dialog.open(HostSuccessComponent, dialogConfig);
  }

  enableSubmitButton() {
    if (
      this.myForm.get('category')['controls'].valid &&
      this.myForm.get('industry')['controls'].valid &&
      this.myForm.get('feeInfo')['controls'].paid.valid &&
      (this.myForm.get('feeInfo')['controls'].paid.value == 'false' ||
        (this.myForm.get('feeInfo')['controls'].paid.value == 'true' &&
          this.myForm.get('feeInfo')['controls'].feeInINR.valid))
    ) {
      return true;
    }

    return false;
  }

  setOrganizer(organizer: any) {
    this.myForm
      .get('organizer')
      ['controls']['contactInfo']['controls']['about'].setValue(
        organizer.about
      );
    //this.myForm.get('organizer')['controls']['contactInfo']['controls']['email'].setValue(organizer.email);
    this.myForm
      .get('organizer')
      ['controls']['contactInfo']['controls']['logo'].setValue(organizer.logo);
    this.organizerCompressedURL = organizer.logo;
    //this.myForm.get('organizer')['controls']['contactInfo']['controls']['mobileNo'].setValue(organizer.mobileNo);
    this.myForm
      .get('organizer')
      ['controls']['contactInfo']['controls']['profileUrl'].setValue(
        organizer.profileUrl
      );
  }

  setSpeaker(speaker: any, index) {
    this.myForm
      .get('speakers')
      ['controls'][index]['controls']['contactInfo']['controls'][
        'about'
      ].setValue(speaker.about);
    this.myForm
      .get('speakers')
      ['controls'][index]['controls']['contactInfo']['controls'][
        'designation'
      ].setValue(speaker.designation);
    this.myForm
      .get('speakers')
      ['controls'][index]['controls']['contactInfo']['controls'][
        'logo'
      ].setValue(speaker.logo);
    this.myForm
      .get('speakers')
      ['controls'][index]['controls']['contactInfo']['controls'][
        'profileUrl'
      ].setValue(speaker.profileUrl);
    //this.myForm.get('speaker')['controls']['contactInfo']['controls']['about'].setValue(organizer.about);
    /*
    this.myForm.get('organizer')['controls']['contactInfo']['controls']['email'].setValue(organizer.email);
    this.myForm.get('organizer')['controls']['contactInfo']['controls']['logo'].setValue(organizer.logo);
    this.myForm.get('organizer')['controls']['contactInfo']['controls']['mobileNo'].setValue(organizer.mobileNo);
    this.myForm.get('organizer')['controls']['contactInfo']['controls']['profileUrl'].setValue(organizer.profileUrl);
 */
  }

  posterUploadError = false;
  speakerUploadError = false;
  organizerUploadError = false;
  selectFile(event: any, type, index) {
    if (event.target.files[0].name.endsWith('.png')) {
      this.toasts.warning('Please upload image with .JPEG or .JPG extension');
      if (type == 'poster') {
        this.posterUploadError = true;
        this.posterCompressedURL = null;
      } else if (type == 'speaker') {
        this.speakerUploadError = true;
        this.speakerCompressedURL = null;
      } else if (type == 'organizer') {
        this.organizerUploadError = true;
        this.organizerCompressedURL = null;
      }

      return;
    }

    let fileName: any;
    this.file = event.target.files[0];
    fileName = this.file['name'];

    if (type == 'organizer') {
      this.organizerFile = this.file;
    }
    if (type == 'speaker') {
      this.speakerFile = this.file;
    }
    if (type == 'poster') {
      this.posterFile = this.file;
    }
    //console.log(this.myForm.value);

    const imageName = fileName;

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        //console.log(event.target.result);
        this.localUrl = event.target.result;
        this.compressFile(this.localUrl, fileName, type, index);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  imageUpload(file, name) {
    this.ref = this.afStorage.ref(name);
    this.task = this.ref.put(file);
  }

  compressFile(image, fileName, type, index) {
    const orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    //console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    this.imageCompress
      .compressFile(image, orientation, 100, 60)
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        //console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        // create file from byte

        if (type == 'organizer') {
          this.myForm
            .get('organizer')
            ['controls']['contactInfo']['controls']['logo'].setValue(
              this.localCompressedURl
            );
          this.organizerCompressedURL = this.localCompressedURl;
          this.organizerUploadError = false;
        }
        if (type == 'speaker') {
          this.myForm
            .get('speakers')
            ['controls'][index]['controls']['contactInfo']['controls'][
              'logo'
            ].setValue(this.localCompressedURl);
          this.speakerCompressedURL = this.localCompressedURl;
          this.speakerUploadError = false;
        }
        if (type == 'poster') {
          this.myForm
            .get('webinarInfo')
            ['controls'].posterUrl.setValue(this.localCompressedURl);
          //console.log(this.localCompressedURl);
          this.posterCompressedURL = this.localCompressedURl;
          this.posterUploadError = false;
        }
        //console.log(this.myForm.value);

        return this.localCompressedURl;
      });
  }

  dataURItoBlob(dataURI) {
    // const byteString = window.atob(dataURI);
    const byteString = null;
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  getSpeakerPhoto(index) {
    return this.myForm.get('speakers')['controls'][index]['controls'][
      'contactInfo'
    ]['controls']['logo'].value;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  videoUpload(event) {}

  setTimezone(event) {
    this.timeZoneSelected = event.abbr;
    this.myForm
      .get('schedule')
      ['controls'].timeZone.setValue(this.timeZoneSelected);
  }

  timeZoneSelected = '';
  timeZones: any[] = [
    {
      zone: 'IST',
      desc: 'Indian Standard Time'
    },
    {
      zone: 'EST',
      desc: 'Europian Standard Time'
    },
    {
      zone: 'EET',
      desc: 'Eastern Europian Time (UTC +02:00)'
    },
    {
      zone: 'CET',
      desc: 'Central Europian Time (UTC +01:00)'
    }
  ];
  getTimeZone() {}
}
