
        <div class="container-fluid">
            <div class="row" style="margin-top: 30px;margin-bottom: 30px">
                
                <div class="col-lg-5 offset-1 " fxLayout="column">
                        <h1 style="text-align: center; font-family: 'Nunito';">contact us</h1>
                        <form [formGroup]="myForm" (ngSubmit)="submitForm()">
                            <div fxLayout="column">
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input required matInput formControlName="name" placeholder="Enter Your Full Name">
                            <mat-icon matSuffix>face</mat-icon>
                            <mat-error
                                *ngIf="myForm.controls.name.touched && myForm.controls.name.invalid">
                                
                               <span
                                    *ngIf="myForm.controls.name.errors.required">
                                    Name is
                                    mandatory.</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input required formControlName="email" matInput placeholder="Enter Your Email Address">
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-error
                                *ngIf="myForm.controls.email.touched && myForm.controls.email.invalid">
                                
                               <span
                                    *ngIf="myForm.controls.email.errors.required">
                                    Email is
                                    mandatory.</span>
                                    <span *ngIf="myForm.controls.email.errors.email">Please, enter valid email address.</span>
                            </mat-error>
                         </mat-form-field>
                        
                        <mat-form-field appearance="outline">
                            <mat-label>Message</mat-label>
                            <textarea required formControlName="message" matInput placeholder="Enter your message"></textarea>
                            <mat-icon matSuffix>text_rotation_none</mat-icon>
                            <mat-error
                            *ngIf="myForm.controls.message.touched && myForm.controls.message.invalid">
                            
                           <span
                                *ngIf="myForm.controls.message.errors.required">
                                Message is
                                mandatory.</span>
                        </mat-error>
                        </mat-form-field>
    
                        
                        
                        
                       
                        
                        <button [disabled]="!(myForm.controls.message.valid && myForm.controls.name.valid && myForm.controls.email.valid)" type="submit" mat-raised-button color="primary" style="margin-top: 15px" >Submit</button>
                    </div>
                    </form>  
                                
                        
                        
    
    
    
    
                    
                </div>
                <div class="col-lg-6">
                    <img src="../../assets/contact.svg" style="    height: 389px;
                    width: auto;
                    margin-left: 82px;" alt="no-image">
                </div>
            </div>
        </div>
