import { WebinarsUsersMapping } from './../../interfaces/webinarsUsersMapping.module';
import { CommunicationServiceService } from './../../service/communication-service.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Webinar } from './../../interfaces/webinars.model';
import { AllEventService } from './../../events/all-events/all-event.service';
import { AuthService } from './../../service/auth.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  webinars: Array<Webinar>;
  webinarsUsersMapping: Array<WebinarsUsersMapping>;
  originalWebinarList: Webinar[];
  dashboardSelected = true;
  personalDetailsSelected = false;
  hostSelected = false;
  attendSelected = false;
  totalWebinars = 0;
  totalRegistered = 0;
  userName: string;
  profileImage: string;
  location = '';
  constructor(
    private authService: AuthService,
    private allEventService: AllEventService,
    private route: ActivatedRoute,
    private router: Router,
    private commService: CommunicationServiceService
  ) {
    // console.log('dashboard');
    router.events.subscribe((event: NavigationEnd) => {
      this.location = event.url;
    });
  }

  async ngOnInit(): Promise<void> {
    const user = await this.authService.isLoggedIn();
    this.userName = user.displayName;
    this.profileImage = user.photoURL;
    this.commService.searchText = null;
    this.commService.categoryName = null;
    this.commService.industryName = null;

    // this.goToDashboardHome();
  }

  selectedMenu(value) {
    if (value == 'd') {
      this.dashboardSelected = true;
      this.personalDetailsSelected = false;
      this.hostSelected = false;
      this.attendSelected = false;
    } else if (value == 'hw') {
      this.dashboardSelected = false;
      this.personalDetailsSelected = false;
      this.hostSelected = true;
      this.attendSelected = false;
    } else if (value == 'aw') {
      this.dashboardSelected = false;
      this.personalDetailsSelected = false;
      this.hostSelected = false;
      this.attendSelected = true;
    }
  }

  myFunc(e) {
    const target: HTMLElement = e.target;

    const t = document.getElementById('sidebar');
    t.classList.toggle('active');
  }

  goToDashboardHome() {
    this.router.navigate(['user/dashboard/home']);
  }

  signOut() {
    this.authService.signOut();
  }
}
