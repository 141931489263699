import { Speaker } from './Speaker.model';
import { Schedule } from './Schedule.model';
import { Tag } from './../host/host/host.component';
import { RecordingInfo } from './RecordingInfo.model';
import { FeeInfo } from './FeeInfo.model';
import { Organizer } from './Organizer.model';
import { Category } from './Category.model';
import { Webinarinfo } from './Webinarinfo.model';
import { Industry } from './Industry.model';
export class Webinar {
  'id': string;
  'webinarInfo': Webinarinfo;
  'category': Category[];
  'industry': Industry[];
  'organizer': Organizer;
  'feeInfo': FeeInfo;
  'recordingInfo': RecordingInfo;
  'schedule': Schedule;
  'hostUserId': string;
  'tags': Tag[];
  'speakers': Speaker[];
  'status': string;
  'featured': boolean;
  'creationDate': Date;
  'certificate': string;
}

export const sampleWebinar = {
  id: 'string',

  industry: [
    {
      name: 'Management Consulting'
    }
  ],
  speakers: [
    {
      id: 'string',
      contactInfo: {
        id: 'string',
        profileUrl: 'https://www.linkedin.com/in/amitabhdash/',
        logo: '',
        email: '',
        designation: '',
        about: '',
        name: 'Amitabh Dash',
        mobileNo: '',
        creationDate: new Date()
      }
    }
  ],
  webinarInfo: {
    description:
      "With placement season knocking the doors for MBA peeps, this time, we have planned an 'All you need to Know' session in the Consulting Domain.\n\nGet acquainted with their terms, prerequisites, and career prospects. You will receive first-hand tips and tricks on solving Guess Estimates & cracking the Case Interviews.\n\nWe are glad to host Amitabh Dash for this webinar. He is an alumnus of the Management Development Institute and is currently working at McKinsey & Company as a Senior Consultant.\n\nRegister Now",
    title: 'Kick-Starting your career in Consulting Domain',
    formLink: 'https://forms.gle/1mzuhATe9mYpsHQJ8',
    posterUrl: 'string',
    meetingLink: 'string'
  },
  status: 'Approved',
  category: [
    {
      name: 'MBA/GMAT/GRE',
      img: '',
      id: ''
    }
  ],
  creationDate: new Date(),
  tags: [
    {
      creationDate: new Date(),
      name: '#webinar'
    },
    {
      creationDate: new Date(),
      name: '#inconversation'
    },
    {
      creationDate: new Date(),
      name: '#consulting'
    }
  ],
  hostUserId: 'nRi39N4gx7Z93CbtGMaEpl574rE3',
  feeInfo: {
    paid: 'false',
    feeInINR: ''
  },
  featured: true,
  schedule: {
    endTime: '11:00 PM',
    startTime: '10:00 PM',
    endDate: '2020-09-19',
    startDate: '2020-09-19',
    durationInMinutes: 60
  },
  organizer: {
    id: 'string',
    contactInfo: {
      email: 'inconversationwebinars@gmail.com',
      mobileNo: '8377068480',
      name: 'In-Conversation',
      profileUrl: 'https://www.linkedin.com/company/in-conversation-webinars',
      about: '',
      logo: '',
      designation: '',
      creationDate: new Date()
    }
  },
  recordingInfo: {
    recordingLink: '',
    recorded: true,
    views: 0,
    duration: 0
  },
  certificate: 'true'
};
