<!--
<div class="row">
    <div class="col-md-12">
        <h1 style="text-align: center; font-family: 'Nunito';font-weight: bold;">
            <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
            <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
            &nbsp;
            Featured Webinars&nbsp;
            <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
            <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
        </h1>
        <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let x of webinars1">
                    <mat-card class="mat-elevation-z4" >
                            <div class="container-card-img">
                                            <img *ngIf="x.img" mat-card-image src="{{x.img}}" >
                                            <img *ngIf="!x.img" mat-card-image src="../../../../assets/img-alt.jpg">
                                    
                                    <mat-chip class="action-button-category btn btn-primary top-left mat-elevation-z4" >{{x.category[0]}}</mat-chip>
                                    <mat-chip class="action-button-price btn btn-primary top-right mat-elevation-z4"> 
                                            <span *ngIf="x.price=='Free'">{{x.price}}</span>
                                            <span *ngIf="x.price=='Paid'">{{x.fees | currency:'INR'}}</span>
                                    </mat-chip>
                                   
                                    
                             </div>
                    

                            <mat-card-content>
                                    <p  matTooltip="{{x.title}}" matTooltipClass='red-tooltip'
                                            style="text-align: center; font-weight: 500; font-size: 1.2em ;">
                                           
                                            {{ (x.title.length > 40) ? (x.title | slice:0:40) + '...' : x.title }}
                                    </p>
                                    <mat-divider></mat-divider>
                                    <br>
                                    <div>
                                            <div class="outer-div">
                                                    <mat-icon style="color: #792cc7">calendar_today</mat-icon>&nbsp;&nbsp;
                                                    <span
                                                            *ngIf="x.schedule.startDate==x.schedule.endDate">{{x.schedule.startDate | date : "EEE dd MMM"}}</span>
                                                    <span *ngIf="x.schedule.startDate!=x.schedule.endDate">{{x.schedule.startDate | date : "EEE dd MMM"}}
                                                            -
                                                            {{x.schedule.endDate | date :"EEE dd MMM"}}</span>
                                            </div>
                                            <br>
                                            <div class="outer-div">
                                                    <mat-icon style="color: #c94190">alarm</mat-icon>&nbsp;&nbsp;
                                                    <span>{{x.schedule.startTime}} - {{x.schedule.endTime}}</span>
                                            </div>
                                            <br>
                                            
                                    </div>
                            </mat-card-content>
                            <mat-card-actions>
                                    <p style="text-align: center"><a mat-raised-button
                                                    color="primary" href="{{x.formLink}}"
                                                     [routerLink]="['/webinar', x.id]">Details</a></p>

                                              
      
                                            <button mat-icon-button style="color: #792cc7" (click)="openDialog(x)" class="bottom-right-share"><mat-icon>share</mat-icon></button>
                                    
                                            <button *ngIf="!favoriteSelected" (click)="toggleFavorite()" mat-icon-button style="color: #c94190" class="bottom-right-like"><mat-icon>favorite_border</mat-icon></button>
                                            <button *ngIf="favoriteSelected" (click)="toggleFavorite()"  mat-icon-button style="color: red" class="bottom-right-like"><mat-icon>favorite</mat-icon></button>               
                                                    

                            </mat-card-actions>
                    </mat-card>
            </div>
        </div>

    </div>
    <div class="col-md-12">
            <owl-carousel-o [options]="customOptions">
 
                    <ng-container *ngFor="let y of webinars1">
                      <ng-template carouselSlide >
                        

                            <mat-card>
                                <mat-card-content>
                                    {{y.title}}
                                </mat-card-content>
                            </mat-card>
                        
                      </ng-template>
                    </ng-container>
                 
            </owl-carousel-o>
    </div>
</div>-->

<div style="position: relative">
                <!-- the position of the parent container must be set to relative -->
                <!-- It is really important to set loaderId for non-master loader -->
                <ngx-ui-loader bgsPosition="POSITION.centerCenter" [loaderId]="'loader-01'"></ngx-ui-loader>


<div class="row featured-event-main">
        <div class="col-md-12">
                <div style="display: flex;flex-direction: row;justify-content: space-between">
            <h1 style="text-align: left; font-family: 'Nunito';font-weight: bold; ">
               <!-- <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                
                Featured Webinars
              <!--  <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
            </h1>
            <button mat-button style="font-family: 'Nunito';font-weight: bold; " routerLink="attend">
                        <!-- <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                         <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                         
                        View All
                       <!--  <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>
                         <mat-icon style="color: #c94190; font-size: 20px;">star</mat-icon>-->
                       </button>
                </div>
                <!--
            <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-container  *ngFor="let x of webinars ">
                <ng-template  carouselSlide >
                    
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <p *ngIf="webinars?.length>0" fxShow="true" fxShow.lt-sm="false" style="text-align: center;margin-top: 10px;font-size: 30px;">
                <button class=" corousel-nav-button owl-prev mat-elevation-z4" (click)="owlCar.prev()"><mat-icon >navigate_before</mat-icon></button>
                <button  class="corousel-nav-button owl-next mat-elevation-z4" (click)="owlCar.next()"><mat-icon > navigate_next</mat-icon></button>
              </p>
        -->
        <div  fxLayout="row wrap" fxLayoutGap="16px grid">
                        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let x of webinars">
                                        <app-webinar-card
                                        [webinar]="x">

                                        </app-webinar-card>
                        </div>
                        </div>
        </div>
        
    </div>
    </div>